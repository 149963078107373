.bitUiModalButton {
    margin-bottom: 10px !important;
    
}

.modalOKbtn {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.modalOKbtn:focus-visible {
    border: 1px solid grey;
   }

.modalCancelBtn:focus-visible {
    border: 3px solid grey;
}

.modal-loader{
    position: relative;
    top: -14%;
    left: 5px;
}