@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry

// Custom ant.d styles - must be imported after importing the default ant.d stylesheet
@import "./antd-overrides/default.less";
@import "./antd-overrides/modal.less";
@import "./antd-overrides/icon.less";
@import "./antd-overrides/input.less";
@import "./antd-overrides/menu.less";
@import "./antd-overrides/form.less";
@import "./antd-overrides/select.less";

// Theme color palette
@import "./colors.less";

body {
  font-family: "IBM Plex Sans" !important;
  background-color: @LIGHTEST_GRAY;
  caret-color: @DARKEST_GRAY;
}
