.noUserCard {
    background: #FFFFFF;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 
      0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 
      0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 
      0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 
      0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 
      0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 
      0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 
      0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    border-radius: 10px;
    height: 255px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    }
  }