.loaderBody {
  position: relative;
  max-width: 180px;
  background: #ededed;
  border-radius: 60px;
  width: 100%;
  height: 54px;
  top: 20%;
  margin: auto;
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner:before {
    display: inline;
    content: "";
    position: absolute;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-left: 0;
    top: 30%;
    left: 22%;
    border-radius: 50%;
    border: 2px solid #1C1C1C;
    border-top-color: #ededed;
    animation: spinner 2s linear infinite;
  }
  .loadingText {
    display: inline;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    position: relative;
    top: 30%;
    left: 42%;
    margin-top: 0;
    margin-left: 0;

    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 20px;
    color: #737373;

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .loadingText:after {
    content: '..';
    animation: dots 1.5s steps(5, end) infinite;}
  
  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: white;
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}}
}

.loaderButton {
  position: relative;
  top: 20%;
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner:before {
    display: inline;
    content: "";
    position: absolute;
    box-sizing: border-box;
    width: 9px;
    height: 9px;
    margin-top: 0;
    margin-left: 0;
    top: 30%;
    left: 22%;
    border-radius: 50%;
    border: 2px solid #4D4D4D;
    border-top-color: #ededed;
    animation: spinner 2s linear infinite;
  }
}