.ant-steps-horizontal {
  display: none !important;
}
.createPassContainer {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
  border-radius: 10px;
  padding: 13.57% 14.842% 16.75%;
}
.crtPassStep {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 20px;
  color: #1C1C1C;
}
.stepDashLine {
  margin: 8px 0px 20px 0px;
  display: flex;
}
.dashFill {
  width: 12.93px;
  height: 2px;
  background: #1C1C1C;
  border-radius: 1px;
}
.dashEmpty {
  width: 12.93px;
  height: 2px;
  background: #EDEDED;
  border-radius: 1px;
  margin-left: 3.1px;
}
.marLeft {
  margin-left: 3.1px;
}
.mt-35 {
  margin-top: 35px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-22 {
  margin-top: 22px !important;
}
.crtPassHed {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4375rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1C1C1C;
  margin-bottom: 27px;
}
.crtPassSabText {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 18px;
  color: #4D4D4D;
}
.crtPassLabel {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem !important;
  line-height: 20px !important;
  color: #1C1C1C !important;
  margin-bottom: 20px !important;
  display: block !important;
}
.crtPassLabel .ant-form-item-label {
  padding-bottom: 0px !important;
}
.crtPassLabel label {
  height: 20px !important;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem !important;
  line-height: 20px !important;
  color: #1C1C1C !important;
  padding-bottom: 5px;
}
.crtPassLabel .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content .customPassword {
  height: 54px !important;
}
.nextBtn {
  margin-top: 12.065%;
}
.secondNextBtn {
  margin-top: 49%;
}
.thirdFinishBtn {
  margin-top: 9.35%;
}
.picUpPaddStyl {
  padding-bottom: 12.51% !important;
  margin-bottom: 20%;
}
.uploadFileBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploadPicture {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEEEEE !important;
  border: 1px solid #EDEDED !important;
  box-sizing: border-box !important;
  border-radius: 200px !important;
  height: 240px !important;
  width: 240px !important;
}
.uploadPicture .ant-upload-btn {
  height: 240px !important;
  width: 240px;
  padding: 0px !important;
  box-sizing: border-box !important;
  border-radius: 200px !important;
  position: relative;
  z-index: 11;
}
.imgSize {
  width: 78px !important;
  height: 76px !important;
}
.uploadText {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8125rem !important;
  line-height: 20px;
  color: #1C1C1C !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px !important;
  margin-bottom: 6px !important;
}
.uploadHint {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.625rem !important;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #737373 !important;
}
.deleteImg {
  position: absolute;
  right: 27px;
  bottom: 27px;
  z-index: 999;
}
.createPassStep {
  width: 95%;
  margin-bottom: 20%;
}