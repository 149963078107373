@Disabled: url(../../../../../assets/images/disabled.svg),auto;
.pagination-style{
  text-align: center;
  padding-top: 20px !important;
  border-top: 1px solid #ddd;
}
.ant-pagination-options {
  display: none !important;
}
.ant-pagination-item {
  background: transparent !important;
  border-radius: 5px !important;
  margin: 0px 4px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 25px !important;
  height: 30px !important;
  margin-right: 6px;
  font-family: "IBM Plex Sans";
  line-height: 18px;
  font-style: normal;
  font-size: 0.75rem;
  border: 1px solid transparent !important;
  cursor: pointer;
  a {
    min-width: 25px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #000000;
  }
  &:focus-visible {
    border: 2px solid black !important;
  }
  &:hover {
    border: 1px solid #4d4d4d !important;
    a {
      color: #4d4d4d;
    }
  }
}
.ant-pagination-item-active {
  background: #ffffff !important;
  box-shadow: 3px 2px 20px -5px rgb(0 0 0 / 80%);
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  a {
    color: #1c1c1c !important;
    font-weight: bold;
  }
  &:hover {
    border: 1px solid #ccc !important;
    a {
      color: #1c1c1c !important;
    }
  }
}
.ant-pagination-disabled svg{
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-pagination-disabled .ant-pagination-item-link {
  cursor: @Disabled !important;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 25px !important;
  height: 30px !important;
  color: #1c1c1c;
  line-height: 28px !important;
  margin: 0;
  svg{
    width: 10px;
    height: 10px;
    color: #4d4d4d;
  }

  &:focus-visible {
    border: 2px solid black !important;
  }
  .ant-pagination-item-link {
    min-width: 25px !important;
    height: 30px !important;
    border: 0px !important;
    background: transparent !important;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover{
      svg{
      color: #777;
      }
    }
  }
}
.ant-pagination-item-ellipsis {
  color: #4d4d4d !important;
}
