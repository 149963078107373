
@Disabled: url(../../../../../assets/images/disabled.svg),auto;

.alertCentreDiv {
  margin-left: 3.5%;
}

.alertTableDiv {
  table {
    thead {
      tr {
        th:nth-child(2) {
          padding-left: 0px;
        }
      }
    }
    thead > tr > th:hover .ant-table-filter-trigger {
      visibility:visible;
    }
    // .ant-table-filter-trigger {
    //   visibility: hidden;
    // }
  

    tbody {
      tr {
        td {
          min-width: 100px;
          max-width: 140px;
          cursor: pointer;

          .alertCenre-lastColumn {
            display: flex;
            align-items: center;

            .alertCentre-expandIcon {
              padding-left: 20px;
              margin-left: auto;
            }
          }
        }

        td:first-child {
          border-radius: 10px 0 0px 10px;
          min-width: 16px !important;
          max-width: 16px !important;
          width: 1%;
          padding: 0px !important;
        }

        td:nth-child(2) {
          padding-left: 0px;
        }

        td:last-child {
          border-radius: 0px 10px 10px 0px;
        }
      }

      .alertCentre-expanded {
        td .alertCentre-expandIcon img {
          transform: rotate(90deg);
        }
      }

      .ant-table-expanded-row {
        td {
          background: #fafafa;
          cursor: auto !important;

          &:hover {
            background: #fafafa !important;
          }
        }
      }
    }
  }
}

.bold-700 {
  font-weight: 700;
}

.alertCentre-description {
  margin: 25px 5% 40px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
    0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
  border-radius: 10px;
  padding: 30px 50px;
}

.alertCentre-icon {
  width: 20px;
  height: 20px;
  margin-right: 11px;
}

.alertCentre-description p {
  margin: 0px;
}

.alertCentre-col-1 {
  width: 33%;

  .alertCentre-content-1 {
    margin-left: 58px;
  }

  .alertCentre-content-2 {
    padding-left: 25%;
  }
}

.alertCentre-clearInput {
  margin-bottom: 15px !important;
  display: flex !important;
  flex-direction: column !important;
  .ant-form-item-label {
    display: flex;
    align-items: flex-start;
    .ant-form-item-required {
      height: auto;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 20px;
      color: #1c1c1c;
    }
  }
  .ant-form-item-control {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        input {
          height: 44px !important;
          font-size: 0.75rem;
          line-height: 20px;
        }
      }
    }
  }
}

.alertCentre-clearBtn {
  height: 35px;
}

.alert-resend-btn{
  height: 35px;
  margin-top: 30px;
}

.alertCentre-severity {
  text-transform: capitalize;
}

.ant-input[disabled] {
  cursor: @Disabled !important;
}
.headText{
   display: flex;
   justify-content: space-between;
   cursor: pointer;
}
.alertHeadingText {
  color: #4d4d4d;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  cursor: pointer;
}
.tableHeadingFixed{
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.ml-15{
  margin-left: 15px !important;
}

.clear-alert-toggle {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 20px;
  color: #737373;
  flex-wrap: wrap;
  margin-bottom: 1px;
}