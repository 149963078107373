.Toastify__toast-container {
  width: 230px !important;
}

.toast {
  height: 125px !important;
  background-color: #1c1c1c;
  border-radius: 10px;
  box-shadow: 0px 76px 60px rgb(0 0 0 / 9%),
    0px 31.751px 25.0666px rgb(0 0 0 / 6%),
    0px 16.9756px 13.4018px rgb(0 0 0 / 5%),
    0px 9.51638px 7.51293px rgb(0 0 0 / 5%),
    0px 5.05408px 3.99006px rgb(0 0 0 / 4%),
    0px 2.10311px 1.66035px rgb(0 0 0 / 3%);
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  align-items: center;
  display: flex;
}
.toast-body {
  margin: 10px 10px !important;
  color: #cbcbcb;
  word-break: break-word;
}

.error-toast {
  color: rgb(0, 0, 0) !important;
  background-color: #f66363 !important;

}

.notification-container {
  font-size: 0.875rem;
  box-sizing: border-box;
  position: fixed;
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  top: auto !important;
  left: auto !important;
  bottom: 12px;
  right: 12px;
  animation: toast-in-right 0.5s;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.notification {
  width: 230px;
  height: 125px;
  background: #1c1c1c;
  box-shadow: 0px 76px 60px rgb(0 0 0 / 9%),
    0px 31.751px 25.0666px rgb(0 0 0 / 6%),
    0px 16.9756px 13.4018px rgb(0 0 0 / 5%),
    0px 9.51638px 7.51293px rgb(0 0 0 / 5%),
    0px 5.05408px 3.99006px rgb(0 0 0 / 4%),
    0px 2.10311px 1.66035px rgb(0 0 0 / 3%);
  border-radius: 10px;
  padding: 30px 30px 30px 30px;
  position: absolute;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
