.TreeNodeDropdown input {
    display: none !important;
}
.TreeNodeDropdown{
    padding: 4px 0 !important;
    background: #ffffff;
    border: 1px solid #EDEDED !important;
    box-sizing: border-box !important;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
        0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
        0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
        0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
        0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
        0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
        0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
        0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335) !important;
        border-radius: 10px !important;
}
.ant-select-tree-list-scrollbar-thumb{
    width: 5px !important;
    border-radius: 10px;
    background: rgb(204, 204, 204) !important;
    right: 0 !important;
}
.ant-select-tree-treenode{
    padding-left: 2em !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-left: 2px solid transparent;
    text-align: left;
    color: #4d4d4d;
    height: 40px;
    display: flex !important;
    flex-direction: row;
    align-items: center !important;
    &:hover {
      border-left: 2px solid red;
      background: #f0f0f0 !important;
      color: #1c1c1c;
    }
}
.ant-select-tree-title{
    font-size: 0.875rem !important;
    line-height: 20px !important;
}
.ant-select-tree-switcher{
    align-self: auto !important;
}
.TreeNodeDropdown .ant-select-tree-node-content-wrapper:hover{
    background-color: transparent !important;
}
.TreeNodeDropdown .ant-select-tree-node-selected{
    background-color: transparent !important;
    font-weight: 500;
    color: #1c1c1c !important;
}
.modalSpinner {
    display: inline;
    content: "";
    position: absolute;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #000000;
    border-top-color: #ededed;
    animation: spinner 2s linear infinite;
}
.modalLoadingText:after {
    content: '..';
    animation: dots 1.5s steps(5, end) infinite;
}

@keyframes dots {
    0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
        color: white;
        text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
        text-shadow:
        .25em 0 0 white,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
        text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}
}

.locationModalDropdown {
    width: 100%;
}

.TreeNodeDropdown .ant-select-tree-node-content-wrapper-normal {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}