.custom-checkboxInner{
    height: 6px;
    width: 6px;
    background-color: black;
    display: grid;
}
.custom-checkboxOuter{
    cursor: pointer;
    width: 14px;
    height: 14px;
    padding: 3px;
    border: 1px solid #CBCBCB;
    border-radius: 3px;
}
.custom-checkboxDisable{
    border: 1px solid #EDEDED !important;
    cursor: url(../../../../../assets/images/disabled.svg),auto;
    .custom-checkboxInner{
        background-color: #EDEDED;
    }
    &:hover{
        .custom-checkboxInner{
            background-color: #EDEDED;
        }
    }
}