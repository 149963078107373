.headerContainer {
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
.logo {
  display: flex;
  align-items: center;
  line-height: 20px;
}
.logoOne {
  padding-right: 15.2px;
  padding-left: 3px;
}
.juntoLogoSty {
  padding-left: 12px;
  width: 128.06px;
  height: 24px;
  cursor: pointer;
}
.bibLogoSty {
  padding-left: 3px;
  width: 140px;
  height: 18.7px;
  cursor: pointer;
}
.headerRight {
  justify-content: flex-end;
  align-items: center !important;
}
.headerAlertIcon {
  padding-right: 45px;
}
.lineIcon {
  padding-right: 45px;
  color: #ededed;
}
.bellIconImg {
  padding-right: 45px;
}
.settingIconImg {
  padding-right: 45px;
}
.headerLogo {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  margin-left: 30px;
}
.headerLogoText {
  padding-left: 10px;
  font-family: "IBM Plex Sans";
  font-size: 0.875rem;
  line-height: 20px;
  color: #1c1c1c;
  margin: 0px;
  word-break: break-word;
}
.bellIconFix {
  width: 16px;
  height: 19.37px;
  cursor: pointer;
}
.settingIconFix {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.right {
  float: right;
}
.pl-7 {
  padding-left: 7px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-29 {
  padding-left: 29px;
}
.ml-28 {
  margin-left: 28px;
}
.headMar {
  margin-left: 5.786%;
}
.warnCount {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  text-align: center;
  color: #1c1c1c;
  width: 8px;
  height: 20px;
}
.pl-10 {
  padding-left: 10px;
}
.unionIcon {
  cursor: pointer;
  width: 25px;
  height: 22px;
}
.h-24 {
  height: 24px;
}
.headerAlert {
  height: 20px;
  width: 20px;
}
.headAlert {
  height: 24px;
  width: 24px;
}
.mlBell-modal {
  .ant-modal-content::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }
  .ant-modal-content::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .ant-modal-content::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  .ant-modal-content {
    max-width: 414px;
    width: 100%;
    height: 452px;
    padding: 36px 5px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
      0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
      0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
      0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
      0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
      0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
      0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
      0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    .ant-modal-body {
      padding: 0;
      .bellContent {
        .notificatonNotseen{
          background: #fafafa94;
        }
        p {
          text-align: left;
          padding: 0;
          padding-bottom: 45px !important;
          border-bottom: 1px solid #ededed;
          margin-bottom: 36px;
          cursor: pointer;
          .notificationText {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            color: #1c1c1c;
          }
          .notificationTime {
            float: right;
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 0.6875rem;
            line-height: 16px;
            margin-top: -10px;
            color: #737373;
          }
        }
      }
    }
  }
}
.ant-modal-mask {
  background-color: transparent !important;
}

.ant-notification {
  .ant-notification-notice {
    width: 271px;
    height: 182px;
  }
  .notificationTitleIcon {
    margin-right: 10px;
    vertical-align: text-bottom;
  }
  .notificationTitleText {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
  }
  .warning {
    color: #f89406;
  }
  .error {
    color: #ee0000;
  }
  .info {
    color: #55c1f6;
  }
  .uncertain {
    color: #7954b8;
  }
  .off {
    color: #808B96;
  }
  .duration {
    float: right;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.6875rem;
    margin-right: -10px;
    color: #737373;
    margin-top: 3px;
  }
  .notificationDescription {
    .location {
      color: #737373;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 0.625rem;
      line-height: 20px;
      margin: 0;
    }
    .device {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 20px;
      color: #1c1c1c;
      margin-bottom: 5px;
    }
    .describe {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 16px;
      color: #4d4d4d;
      padding-left: 13%;
    }
  }
  .ant-notification-notice-description {
    border-bottom: 1px solid #ededed;
    padding-bottom: 25px;
  }
  .ant-notification-notice-btn {
    margin-top: 10px;
  }
  .ant-notification-notice-close {
    display: none;
  }
  .notificatioButton {
    border-color: transparent;
    background: transparent;
    color: #1c1c1c;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    text-shadow: none;
    box-shadow: none;
  }
}
.alertCountClass {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  color: #1c1c1c;
  width: 8px;
}
.headProfileIcon {
  margin-left: 3.03% !important;
}
.errorCount {
  margin-left: 3.8% !important;
}
.bellWrap {
  position: relative;
  .bellcount {
    width: 18px;
    height: 18px;
    background: #1C1C1C;
    box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.028), 0px 2.7px 3.3px rgba(0, 0, 0, 0.04), 0px 5px 6.3px rgba(0, 0, 0, 0.05), 0px 8.9px 11.2px rgba(0, 0, 0, 0.06), 0px 16.7px 20.9px rgba(0, 0, 0, 0.072), 0px 40px 50px rgba(0, 0, 0, 0.01);
    border-radius: 40px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 0.563rem;
    line-height: 12px;
    color: #ffffff;
    position: absolute;
    top: -6px;
    text-align: center;
    padding-top: 3px;
  }
}
.alertCountwrap {
  cursor: pointer;
  .count {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 20px;
    text-align: center;
    color: #1c1c1c;
    padding-left: 5.58px;
  }
  .point {
    padding-left: 5px;
    padding-top: 10px;
    .warnPoint {
      width: 5px;
      height: 5px;
      border-radius: 50px;
      background: #f89406;
      box-shadow: 0px 36px 80px rgba(248, 148, 6, 0.37),
        0px 15.0399px 33.4221px rgba(248, 148, 6, 0.265976),
        0px 8.04107px 17.869px rgba(248, 148, 6, 0.22056),
        0px 4.50776px 10.0172px rgba(248, 148, 6, 0.185),
        0px 2.39404px 5.32008px rgba(248, 148, 6, 0.14944),
        0px 0.996212px 2.21381px rgba(248, 148, 6, 0.104024);
    }
    .errorPoint {
      width: 5px;
      height: 5px;
      border-radius: 50px;
      background: #ee0000;
      box-shadow: 0px 45px 80px rgba(238, 0, 0, 0.14),
        0px 22.522px 40.0391px rgba(238, 0, 0, 0.106447),
        0px 13.5662px 24.1177px rgba(238, 0, 0, 0.0912224),
        0px 8.69392px 15.4559px rgba(238, 0, 0, 0.0799253),
        0px 5.6347px 10.0172px rgba(238, 0, 0, 0.07),
        0px 3.54698px 6.30574px rgba(238, 0, 0, 0.0600747),
        0px 2.03796px 3.62304px rgba(238, 0, 0, 0.0487776),
        0px 0.896959px 1.59459px rgba(238, 0, 0, 0.0335534);
    }
  }
}
.modalBox {
  top: 12% !important;
  left: 29%;
}
.mlBellInner {
 display: flex;
 align-items: center;
 justify-content: center;
 width: 35px;
 height: 35px;
 &:hover {
  background: #FFFFFF;
  box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.0152067), 0px 5px 6.3px rgba(0, 0, 0, 0.02), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
  border-radius: 50px;
 }
}
.notificationNotFound{
  justify-content: center;
  display: flex;
}
.modalBoxInner {
  top: 12% !important;
  left: 34%;
}
.modalBoxCover {
  overflow: hidden !important;
}
.setIconOff .bellbackround {
  width: 35px;
  height: 35px;
  border-radius: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
}
.setIconOn   .bellbackround {
  width: 35px;
  height: 35px;
  border-radius: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
}
.locationToaster {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #737373;
  padding-left: 15%;
  }
.deviceName {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1C1C1C;
  padding-left: 15%;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1400px) {
  .modalBox {
    left: 33.4%;
  }
  .modalBoxInner {
    left: 35.9% !important;
  }
  .setIconOn .bellbackround {
    width: 35px !important;
    height: 35px !important;
  }
  .setIconOff .bellbackround {
    width: 35px !important;
    height: 35px !important;
  }
}
@media screen and (min-device-width: 1025px) and (max-device-width: 1439px) {
  .modalBox {
    left: 33.4%;
  }
  .modalBoxInner {
    left: 38.9%;
  }
  .setIconOn .bellbackround {
    width: 35px !important;
    height: 35px !important;
  }
  .setIconOff .bellbackround {
    width: 35px !important;
    height: 35px !important;
  }
}
@media screen and (min-device-width: 1441px) and (max-device-width: 1920px) {
  .modalBox {
    top: 13% !important;
    left: 36.3%;
  }
  .setIconOn .bellbackround {
    width: 35px !important;
    height: 35px !important;
  }
  .setIconOff .bellbackround {
    width: 35px !important;
    height: 35px !important;
  }
  .modalBoxInner {
    top: 13% !important;
    left: 41.7%;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .modalBoxCover {
    .ant-modal {
      max-width: 46%;
    }
  }
  .setIconOn .bellbackround {
    width: 35px !important;
    height: 35px !important;
  }
  .modalBox {
    left: 19.5%;
  }
  .setIconOff .bellbackround {
    width: 35px !important;
    height: 35px !important;
  }
  .modalBoxInner {
    left: 24.3% ;
  }
  .headProfileIcon {
    margin-left: 7.03% !important;
  }
}
@media screen and (min-device-width: 426px) and (max-device-width: 768px) {
  // .modalBox {
  //     top: 11% !important;
  //     left: 40%;
  //   }
    .setIconOn .bellbackround {
      width: 35px !important;
      height: 35px !important;
    }
    .setIconOff .bellbackround {
      width: 35px !important;
      height: 35px !important;
    }
    // .modalBoxInner {
    //   top: 9% !important;
    //   left: 45%;
    // }
    .headProfileIcon {
      margin-left: 8.03% !important;
    }
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
    .modalBox {
      top: 5% !important;
      left: 40%;
    }
    .setIconOn .bellbackround {
      width: 32px !important;
      height: 32px !important;
    }
    .setIconOff .bellbackround {
      width: 32px !important;
      height: 32px !important;
    }
    .modalBoxInner {
      top: 5% !important;
      left: 45%;
    }
    .headProfileIcon {
      margin-left: 9.03% !important;
    }
  }
  #bellBoundary {
    position: relative;
    overflow-y: scroll;
    height: 400px;
    table{
      width: 100%;
      tr{
        border-bottom: 1px solid #EDEDED;
        td{
          height: 100px;
          span{
            margin-bottom: 20px;
          }
          .notificationText {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            color: #1c1c1c;
            float: left;
            padding-left: 20px;
            width: 75%;
            text-align: left;
          }
          .notificationTime {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 0.6875rem;
            line-height: 16px;
            color: #737373;
            float: right;
            padding-right: 20px;
            width: 20%;
          }
        }
      }
    }
}
#bellBoundary::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}
#bellBoundary::-webkit-scrollbar-track {
  border-radius: 10px;
}
#bellBoundary::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
.describeEllipsis {
  white-space: nowrap;
  width: 15vw;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-device-width: 1441px) and (max-device-width: 1900px) {
  .describeEllipsis {
    white-space: nowrap;
    width: 10vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
  .describeEllipsis {
    white-space: nowrap;
    width: 20vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-device-width: 426px) and (max-device-width: 768px) {
  .describeEllipsis {
    white-space: nowrap;
    width: 27vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-device-width: 376px) and (max-device-width: 425px) {
  .describeEllipsis {
    white-space: nowrap;
    width: 48vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-device-width: 321px) and (max-device-width: 375px) {
  .describeEllipsis {
    white-space: nowrap;
    width: 58vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-device-width: 300px) and (max-device-width: 320px) {
  .describeEllipsis {
    white-space: nowrap;
    width: 68vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (max-device-width: 1024px) {
  .headMar{
    margin-left: 3.5%;
  }
}

.alertIconCover {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.0152067), 0px 5px 6.3px rgba(0, 0, 0, 0.02), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    background: #FFFFFF;
  }
}

.alertIconInner {
  display: flex;
  justify-content: center;
}
.warningIconCover {
  padding-left: 11px;
}

.dashBoardTooltip {
  .ant-tooltip-arrow-content {
    display: block !important;
  }
  .ant-tooltip-inner {
    background: #1c1c1c !important;
    border: none !important;
    border-radius: 10px !important;
    box-shadow: none;
    font-family: IBM Plex Sans;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 0.875rem !important;
    color: #fff!important;
    line-height: 18px !important;
    min-width: 50px !important;
    min-height: 18px !important;
  }
}
.setIcon .right {
  padding: 6px;
 }
 .setIcon:hover {
   background: #FFFFFF;
   box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.0152067), 0px 5px 6.3px rgba(0, 0, 0, 0.02), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
   border-radius: 40px;
 }

 .seperatorCover {
   margin-left: 4.3%;
 }

 .ml-30 {
   margin-left: 3.7%;
 }

.dashBoardTipoff .ant-tooltip-content{
  display: none;
}

.backDiv{
  width: fit-content;
  .backToPreviousPage{
    display: flex;
    align-items: center;
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-weight: normal;
    font-size: 	0.8125rem !important;
    color: #1c1c1c;
    cursor: pointer;
    img{
      height: 10px;
      padding-right: 6px;
    }
    &:hover{
      color: #4D4D4D;
    }
  }
}

.popout {
  animation: popout 1s ease;
  -webkit-animation: popout 1s ease;
}
@keyframes popout {
  from{transform:scale(0)}
  80%{transform:scale(1.2)}
  to{transform:scale(1)}
}
@-webkit-keyframes popout {
  from{-webkit-transform:scale(0)}
  80%{-webkit-transform:scale(1.2)}
  to{-webkit-transform:scale(1)}
}

.notiNotFound {
  justify-content: center;
  display: flex;  
  .ant-tooltip-arrow-content {
    display: block !important;
  }
  .ant-tooltip-inner {
    background: #1c1c1c !important;
    border: none !important;
    border-radius: 10px !important;
    box-shadow: none;
    font-family: IBM Plex Sans;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 0.875rem !important;
    color: #fff !important;
    line-height: 18px !important;
    min-width: 50px !important;
    min-height: 18px !important;
  }
}
.biblioLogoSty {
  padding-left: 3px;
  width: 140px;
  height: 18.7px;
  cursor: pointer;
}

// .animateWarning:focus-within {
//   border: 1px solid red !important;
// }

.tooltipCommon {
  .ant-tooltip-inner {
    position: relative !important;
    top: -20px !important;
  }
}

/* Align the export file link and remove button with other notifications */

.fileNotification {
  display: inline-flex;
  background-color: #fff;
}

.interactiveLink {
  font-weight: bold;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  margin-right: 3px;
}

.interactiveLink:hover {
  color: #0056b3;
  background-color: #e9ecef;
  border-radius: 4px;
  text-decoration: underline;
}

.remove-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 48px
}

.remove-btn:hover {
  background-color: #e9ecef;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.04); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
}

.pulse {
  animation: pulse 1s ease-in-out infinite;
}

@keyframes rotate {
  0% { transform: rotate(0); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  75% { transform: rotate(-10deg); }
  100% { transform: rotate(0); }
}

.rotate {
  animation: rotate 0.6s ease-in-out infinite;
}