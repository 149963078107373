.toggleSwitch {
    border: 1px solid #CBCBCB !important;
    box-sizing: border-box !important;
    border-radius: 50px !important;
    height: 14px !important;
    min-width: 28px !important;
    width: 28px !important;


    .ant-switch-handle {
        width: 10px !important;
        height: 10px !important;
        top: 1px;
    }
}

.toggleOff {
    background-color: #fff !important;

    .ant-switch-handle::before {
        background: #737373 !important;
    }
}

.toggleCheck {
    background: #13A922 !important;
}