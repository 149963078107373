.alertSetting-component {
    height: 100%;
}

.alertSetting-header {
    padding-top: 10px;
    padding-bottom: 25px;
}

.alertSetting-heading {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4375rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1C1C1C;
    height: 54px;
}

.alertSetting-saveBtn {
    margin-left: auto;

    .ant-row.ant-form-item {
        margin-bottom: 0px !important;
    }
}

.alertAntdCustom {
    table {
        tbody {
            tr {
                td {
                    min-width: 110px;
                }

                td:nth-child(3) {
                    font-weight: 700;
                }

                td:last-child {
                    min-width: 135px;
                }
                td:nth-last-child(2),
                td:nth-last-child(3),
                td:nth-last-child(4),
+               td:nth-last-child(5)
                {
                    min-width: 90px;
                }
                td:nth-last-child{
                    min-width: 200px;
                }
            }
        }
        .ant-table-column-sorter-full {
            display: block !important;
        }

        .ant-table-thead {
            th.ant-table-column-has-sorters {
                .ant-table-column-sorter-full {
                    display: initial;
                    margin-top: -10px;
                }

                .ant-table-filter-column {
                    .ant-table-column-sorter-full {
                        display: initial;
                        margin-top: -10px;
                    }
                }

                .ant-dropdown-trigger {
                    display: flex;
                    align-items: center;
                }

                &:hover {
                    .ant-table-filter-column {
                        .ant-table-column-sorter-full {
                            display: block !important;
                            height: auto;
                        }
                    }

                    .ant-dropdown-trigger {
                        display: flex !important;
                        align-items: center;
                        margin: -4px -8px -4px 4px;
                    }
                }
            }
        }
    }

    .loaderBody {
        margin-top: 8%;
    }
}

.alertConfigHeading {
    display: flex;
    align-items: center;
    color: #4d4d4d;
}

.header-checkbox {
    margin: 0px 0px 0px auto !important;
}

.alertNotifyIcon {
    height: 16px;
    width: 16px;
}

.alertAntdCustom{
    .loaderWrapper{
        z-index: 999;
        width: 100%;
        top: 0;
        background: #F9F9FE;
        height: 100%;
        overflow: hidden;
        position: absolute;
        .loaderBody {
            position: relative;
            max-width: 180px;
            background: #ededed;
            border-radius: 60px;
            width: 100%;
            height: 54px;
            z-index: 999;
            top: 10%;
            margin: auto;
        }
    }
}
