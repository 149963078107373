.location-heading {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4375rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1C1C1C;
    padding-left: 25px
}

.container {
    margin-top: 20px;
    padding: 0;
    border: 0px;
}

.card-right-border {
    border-right: 1px solid #EDEDED;
    background-color: #fff;
}

.card-right-border > .ant-card > .ant-card-body {
    padding: 0;
    padding-top: 24px;
}
.cardbody .ant-card-body {
    padding-top: 30px !important;
    
}
.cardbody {
    height: 100% !important;
    width: 100% !important;
}

.add-loc-sloc-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    button {
        @media (max-width: 1200px) {
            padding: 4px 10px 4px 10px;
        }
    }
}



.add-loc-sloc-button {
    background: #1C1C1C;
    box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.11), 0px 22.522px 40.0391px rgba(0, 0, 0, 0.0836366), 0px 13.5662px 24.1177px rgba(0, 0, 0, 0.0716748), 0px 8.69392px 15.4559px rgba(0, 0, 0, 0.0627984), 0px 5.6347px 10.0172px rgba(0, 0, 0, 0.055), 0px 3.54698px 6.30574px rgba(0, 0, 0, 0.0472016), 0px 2.03796px 3.62304px rgba(0, 0, 0, 0.0383252), 0px 0.896959px 1.59459px rgba(0, 0, 0, 0.0263634);
    border-radius: 50px;
    border: 1px solid #1C1C1C !important;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 4px 19px 4px 20px;
    outline: none;
    cursor: pointer;
}

.action-items {
    padding-left: 30px;
    border: 0px !important;

}

.action-items > div > label {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 16px;
    color: #1C1C1C;
}

.selectedLocation {
    border-left: 2px solid red!important;
}

.action-items .ant-list-item-action-split {
    display: none;
}

.list-items {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 10px;
    color: #1C1C1C;
}
.list-items .ant-list-item {
    border-bottom: 0px !important;
}

.add-form {
    padding: 12px 10px 12px 10px;
}

.add-button {
    background: transparent;
    margin: 24px 0px 0px 18px;
    border: 0px !important;
    padding: 0px !important;
    cursor: pointer;
}
.add-button img {
    width: 21px;
    height: 21px;
    box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.11), 0px 22.522px 40.0391px rgba(0, 0, 0, 0.0836366), 0px 13.5662px 24.1177px rgba(0, 0, 0, 0.0716748), 0px 8.69392px 15.4559px rgba(0, 0, 0, 0.0627984), 0px 5.6347px 10.0172px rgba(0, 0, 0, 0.055), 0px 3.54698px 6.30574px rgba(0, 0, 0, 0.0472016), 0px 2.03796px 3.62304px rgba(0, 0, 0, 0.0383252), 0px 0.896959px 1.59459px rgba(0, 0, 0, 0.0263634);
    border-radius: 5px;
}


.submit-button {
    width: 52px;
    font-size: 0.75rem;
    color: #FFFF;
    background: black;
    cursor: pointer;
}

li > a.action-items-a {
    display: none;
}

// li.active {
//     background: #F0F0F0;
//     border-left: 2px solid red!important;
// }

li.active li > a.action-items-a {
    display: block;
}

li.action-items {
    min-height: 40px!important;
}

li.action-items > label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.flexContainer::-webkit-scrollbar {display: none;}

.location-row .action {
    visibility: hidden;
    margin-left: 5px;
  }
  .location-row :hover .action{
    display: block;
    visibility: visible;
  }

  .location-row {
    width: 100%;
  }

//   .action-items active{
//     background: #F0F0F0;
//     border-left: 2px solid red!important;
// }

.locationGrid {
    display: flex;
    // align-items: center; 
    justify-content: flex-start;
    flex-wrap: inherit !important;
    @media only screen and (max-width: 1070px) {
        flex-wrap: nowrap !important;
      }
    // overflow: auto;
    // background: #FFFFFF;
    // box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    border-radius: 10px;
    .addColStyle{
        border-radius: 10px 10px 0 !important;
        box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    }
    .childDiv:last-child{
        box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
        border-radius: 10px 10px 0;
    }
}
.locationScrollStyle {
    overflow-x: scroll !important;
}
.locationScrollStyle::-webkit-scrollbar {
    height: 5px;
}
    
    /* Track */
.locationScrollStyle::-webkit-scrollbar-track {
    border-radius: 10px;
}
     
    /* Handle */
.locationScrollStyle::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 4px;
}
    
    /* Handle on hover */
.locationScrollStyle::-webkit-scrollbar-thumb:hover {
    background: #999; 
}
.addLocCont {
    padding: 5px;
}
.addLocation {
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 0.875rem !important;
    &:focus {
        border-color: #55C1F6 !important;
      }
}

.addLocContBtn {
    margin-left: 30px;
}

.ant-empty{
    display: none;
}
.locationShow {
    width: 65%;
    margin-left: -7px;
}
.locationShow div {
    margin: 0px;
}
.locationShow input {
    border: none !important;
}
.activeBox {
    padding-left: 4%;
}
.locationIcon img {
    padding-top: 15px;
    padding-left: 13px;
}
.selectLoc ul .selectedLocation {
 padding: 0px;
}
.list-items li {
    border-left: 2px solid white;
    &:hover {
        border-left: 2px solid red;
        background-color: #f0f0f0;
    };
}
.col-customized {
    @media only screen and (min-width: 1200px) {
        max-width: 19% !important;
    }
    @media only screen and (max-width: 1200px) {
        max-width: 150px !important;
        min-width: 150px;
    }
    overflow-x: hidden ;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 4px;
        &:hover {
        background: #999;
        }
    }
}

.locationLoading .loaderBody {
    margin: 10% auto auto auto;
}
.childDiv ul li {
    cursor: pointer;
    & .location-row label {
        cursor: pointer;
    }
}

.noMoreLocation {
    margin-right: 4px;
}

.locationScrollLength {
    padding-right: 0px !important;
}

.locationNameLabel {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

.addLocText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.addLocBtn {
    max-width: 160px;
}

.locationWrapper:focus-visible {
    border: 2px solid black;
    border-bottom: 2px solid black !important;
    & .location-row  .action{
        display: block;
        visibility: visible;
      }
}

.location-row:focus{
    border: 2px solid black !important
}

.locationWrapper:focus-within .location-row .action{
    display: block;
    visibility: visible;
  }

.EditlocationIcon:focus-visible {
    border: 2px solid black;
    border-radius: 5px;
}