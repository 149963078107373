@Disabled: url(../../../assets/images/disabled.svg),auto;
.rmsc .dropdown-container {
  height: 54px !important;
  padding-top: 5px;
  background: #ffffff !important;
  border: 1px solid #EDEDED !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  font-family: IBM Plex Sans !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: normal !important;
  text-indent: 10px !important;
  text-align: start !important;
  line-height: 20px !important;
  color: #1c1c1c !important;
  cursor: pointer !important;
  box-shadow: none !important;
  &:hover {
    border: 1px solid #cbcbcb !important;
  }
  &:focus {
    border: 1px solid #55c1f6 !important;
  }
}
.dropdown-content {
  border-radius: 10px;
  padding-top: 7px !important;
}
.rmsc .dropdown-content .options {
  background: #ffffff;
  border: 1px solid #FAFAFA;
  box-sizing: border-box;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
    0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
  border-radius: 10px;
  cursor: pointer !important;
}
.rmsc .dropdown-content .search {
  background: #ffffff !important;
  border: 1px solid #fafafa !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 4px 11px rgb(0 0 0 / 10%);
  z-index: 5;
  width: 90%;
  left: 1em;
  top: 1em;
  margin-bottom: 3vh;
  input {
    width: 90% !important;
  }
}
.rmsc .options {
  margin-top: 0px !important;
  padding: 10px 0px 21px 0px !important;
  background: #ffffff !important;
  border-left: none !important;
}
.rmsc .select-item {
  padding: 10px 10px 10px 24px;
  border-left: 1px solid transparent;
  background: #FFFFFF !important;
  &:focus-visible {
    border: 2px solid black !important;
  }
  &:hover{
    border-left: 1px solid #EE0000;
    background: #f0f0f0 !important;
  }
}
.rmsc .options > label {
  border-left: 3px solid transparent !important;
  background: #FFFFFF !important;
  &:focus-visible {
    border: 2px solid black !important;
  }
  &:hover{
    border-left: 3px solid #EE0000 !important;
    background: #f0f0f0 !important;
  }
}
.rmsc .clear-selected-button {
  display: none !important;
}
.rmsc .dropdown-heading {
  cursor: pointer !important;
}
.rmsc .select-item.selected {
  background: transparent;
  font-weight: 500;
}
.multiselectDisable .dropdown-container {
  background-color: #f5f5f5 !important;
  cursor: @Disabled;
  &:focus {
    border: 1px solid #CBCBCB !important;
    cursor: @Disabled;
  }
}
.multiselectDisable .dropdown-container,
.multiselectDisable .dropdown-container .dropdown-heading,
.multiselectDisable .dropdown-container .dropdown-heading .dropdown-heading-value,
.multiselectDisable .dropdown-container .dropdown-heading .dropdown-heading-value span {
  cursor: @Disabled;
}
.multiselectDisable .dropdown-container span {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #00000040;
  cursor: @Disabled;
}

.dropdown-content .item-renderer [type="checkbox"]:checked,
.dropdown-content .item-renderer [type="checkbox"]:not(:checked) {
  position: absolute;
  opacity: 0;
}
.dropdown-content .item-renderer [type="checkbox"]:checked + span,
.dropdown-content .item-renderer [type="checkbox"]:not(:checked) + span
{
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  color: #666;
}
.dropdown-content .item-renderer [type="checkbox"]:checked + span:before,
.dropdown-content .item-renderer [type="checkbox"]:not(:checked) + span:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0px;
  width: 14px;
  height: 14px;
  border: 1px solid #CBCBCB;
  border-radius: 3px;
  background: #fff;
  margin-top: 3px;
}
.dropdown-content .item-renderer [type="checkbox"]:checked + span:after,
.dropdown-content .item-renderer [type="checkbox"]:checked:hover + span:after {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  top: 4px;
  left:4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 1px;
  background-color: #1C1C1C;
  margin-top: 3px;
}
.dropdown-content .item-renderer [type="checkbox"]:not(:checked) + span:after {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  top: 4px;
  left:4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 1px;
  background-color: #FFFFFF;
  margin-top: 3px;
}
.dropdown-content .item-renderer [type="checkbox"]:hover + span:after {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  top: 4px;
  left:4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 1px;
  background-color: #CBCBCB;
  margin-top: 3px;
}
.dropdown-content .item-renderer [type="checkbox"]:not(:checked) + span:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.dropdown-content .item-renderer [type="checkbox"]:checked + span:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.dropdown-content .item-renderer [type="checkbox"] + span {
  padding-left: 15px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.dropdown-content .item-renderer [type="checkbox"]:hover + span,
.dropdown-content .item-renderer [type="checkbox"]:checked + span {
  padding-left: 15px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.dropdown-content .item-renderer [type="checkbox"]:checked + span, .dropdown-content .item-renderer [type="checkbox"]:not(:checked) + span {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  color: #1c1c1c;
}