.workPageDropdown {
  min-width: 80px !important;

  .ant-select-dropdown {
    top: 43px !important;
  }

  .ant-select-item-option-content {
    font-size: 0.8125rem;
    line-height: 35px !important;
    font-style: normal;
  }
}

.workPageDropdown {
  .ant-select-selector {
    .ant-select-selection-search {
      input {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 20px !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #1C1C1C;
        width: 100% !important;
        margin-top: 5px !important;
      }
    }

    .ant-select-selection-item {
      font-size: 0.875rem;
    }
  }
}

.ant-select.ant-select-borderless.filterDropDown.workPageDropdown.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid transparent !important;
}

.ant-select.ant-select-borderless.filterDropDown.workPageDropdown.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid black !important;
  border-radius: 5px !important;
}


.create-workitem-button {
  display: flex;
  align-items: center;
  margin-left: 1vw;
}

.myworkitem-button {
  display: flex;
  align-items: center;
  margin-left: 1vw;
}

.create-workitem-table {
  .ant-table-cell-scrollbar {
    box-shadow: 0 1px 0 1px #eee;
  }

  .ant-table-body {
    overflow-x: auto;
    overflow-y: auto;
    cursor: pointer !important;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 4px;

      &:hover {
        background: #999;
      }
    }
  }

  .ant-checkbox-inner {
    border: 1px solid rgba(28, 28, 28, 0.45) !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(66, 133, 244) !important;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: rgba(28, 28, 28, 0.45) !important;
    background-color: #E5E4E2 !important;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: white !important;
    cursor: pointer !important;
  }

  .ant-table-cell-ellipsis {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    word-break: keep-all !important;
    max-width: 10w !important;
  }

}

.create-workitem-table table tr td:last-child {
  min-width: 200px !important;
  padding: 0px !important;
}

.create-workitem-table table tr td {
  min-width: 110px !important;
  font-family: IBM Plex Sans !important;
  font-style: normal !important;
  font-weight: normal I !important;
  font-size: 0.75rem !important;
  border-right: none !important;
  line-height: 20px !important;
  color: #4d4d4d !important;
}

.create-workitem-table table thead tr {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
}

.create-workitem-table table thead tr th {
  border: none;
  border-right: none !important;
  position: relative;
  color: rgba(28, 28, 28, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}


.create-workitem-action-button ,
.create-workitem-cancel-button {
  margin: 10px auto;
  max-width: 200px;
  min-width: 90px;
  border-radius: 50px !important;
  box-sizing: border-box !important;
  padding: 0px !important;
  height: 30px !important;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  border: 1px solid #1C1C1C !important;
  cursor: pointer !important;
  width: 150px !important;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
  display: flex;
}

.create-workitem-action-button span,
.create-workitem-cancel-button span {
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.create-workitem-action-button {
  background: #1C1C1C !important;
  color: #FFFFFF !important;
  box-shadow: 0px 94px 138px rgba(0, 0, 0, 0.2),
    0px 47.0459px 69.0674px rgba(0, 0, 0, 0.152067),
    0px 28.3382px 41.603px rgba(0, 0, 0, 0.130318),
    0px 18.1606px 26.6614px rgba(0, 0, 0, 0.114179),
    0px 11.7703px 17.2797px rgba(0, 0, 0, 0.1),
    0px 7.40924px 10.8774px rgba(0, 0, 0, 0.0858211),
    0px 4.25707px 6.24974px rgba(0, 0, 0, 0.0696822),
    0px 1.87365px 2.75067px rgba(0, 0, 0, 0.0479335) !important;
}

.create-workitem-action-button:disabled {
  background: #ccc !important;
  color: #666 !important;
  box-shadow: none !important;
}

.create-workitem-cancel-button {
  color: #1C1C1C !important;
}


.create-workitem-modals {
  .loaderBody {
    margin-top: 2% !important;
    .loadingText{
      left: 8% !important;
    }
  }

}

.create-workitem-saving-btn {
  margin: 10px auto;
  width: 150px;
  font-size: 0.75rem;
  font-weight: 500;
  text-overflow: ellipsis;
}

.modal-footer-buttons span {
  display: flex;
  justify-content: center;
}

.not-found-text{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-weight: lighter;
}