.ant-page-header-heading {
    margin-top: 0px !important;
}

.ant-breadcrumb {
    margin-top: 0px;
}

.ant-page-header-content {
    margin: 0px;
    padding: 0px;
}

.ant-page-header-heading-left {
    margin: 0px;
}

.ant-page-header {
    padding: 0px;
}

.page-header-custom {
    padding: 0px 23px !important;
    /* padding-bottom: 50px; */
}

.ant-page-header-heading-extra{
    margin: 0px !important;
}

.ant-input.headerTextArea{
    width: 641.5px;
    height: 108px;
}
.customRoleName {
    max-width: 28% !important;
}
.pb-6 {
 padding-bottom: 6%;
}
.customRoleName input {
    font-size: 0.875rem;
}

.isEditRoleDetail {
    padding-bottom: 50px !important;
    padding-left: 23px !important;
}