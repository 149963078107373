
@Disabled:url(../../../../../../assets/images/disabled.svg),auto;

.branches-wrapper {
    background: #FFFFFF;
    margin-top:10px;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    display:flex;
}

.branches-wrapper-for-0 {
    background: #FFFFFF;
    margin-top:0px;
    border: 1px solid #CBCBCB;
    border-radius: 10px;
    display:flex;
}

.checkbox-label-cls-unselected {
    margin-top:7px;
    margin-left:16px;
    margin-bottom:7px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 40px;
    color: #4D4D4D;
}

.input-cls {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 20px;
    color: #1C1C1C;
    height: 54px !important;
}
.label-cls {
    margin-bottom: 0px !important;
}
.label-cls label {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px;
    color: #1C1C1C;
}

.invite-btn-cls {
    outline: none !important;
}

.pt-15{
    padding-top: 15px !important;
}
.pb-15 {
    padding-bottom: 15px !important;
}
.locationBox {
    height: 35vh;
    position: relative !important;
}
.inviteUserCover {
    padding-top: 54px;
}
.pb-30 {
    padding-bottom: 30px !important;
}
.pt-35 {
    padding-top: 35px !important;
}
.selectRole {
    .ant-select-item-option{
        font-size: 0.75rem !important;
    }
}
.inviteUserCover .errorMsg{
    padding-bottom: 10px;
}
.disableIcon{
    border: 1px solid #CBCBCB;
    cursor: @Disabled !important;
    background: #f5f5f5 !important;
}
.menuBtn [type="checkbox"]:checked + label {
    position: relative !important;
    width: 100% !important;
    padding-left: 30px !important;
    display: flex;
&:hover {
    background-color: #f5f5f5 !important;
  }
}

.menuBtn [type="checkbox"]:not(:checked) + label{
    position: relative !important;
    width: 100% !important;
    padding-left: 30px !important;
    display: flex;
&:hover {
    background-color: #f5f5f5 !important;
  }
}

.locationErrorStyle{
    text-align: left;
    .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-error{
        padding-top: 10px !important;
    }
}


.inviteUserMultiselect {
    .rmsc .dropdown-content .options {
        word-break: break-word;
        padding-right: 3px;
    }

    .rmsc .dropdown-heading{
        height: 100% !important;
    }
    .rmsc .dropdown-container{
        padding-top: 2px;
    }
    .rmsc .options {
        position: relative;
    }
}