.alertDropdown {
    cursor: pointer;
    text-transform: capitalize;

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 12px !important;
        border: none;
        background: none;
        font-weight: normal;
        font-size: 0.75rem;
        
        &:hover {
            border: none;
        }

        .ant-select-selection-search .ant-select-selection-search-input {
            height: 8px !important;
            display: flex;
            align-items: center;
        }

        .ant-select .ant-select-single .ant-select-show-arrow {
            display: flex;
            align-items: center;
        }
    }
}

.ant-select.alertConfigDropdown.ant-select-focused.ant-select-single.ant-select-show-arrow {
    border: 2px solid black;
}

.ant-select.ant-select-borderless.filterDropDown.insightsDropdown.undefined.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    border: 2px solid transparent !important;
}
.ant-select.ant-select-borderless.filterDropDown.insightsDropdown.undefined.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    border: 2px solid black !important;
    border-radius: 5px !important;
}

.customAlertDropdown {
    width: 136px !important;
    text-transform: capitalize;
}

.warnicons {
    display: flex;
    align-items: center;

    span {
        display: flex;
        align-items: center;
    }
}
