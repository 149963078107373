// @Disabled:url(../../../../../../assets/images/disabled.svg),auto;

.custom-table-filter {
  display: inline-block;
  padding-top: 2px;
  height: 18px;
  width: 18px;
  display: flex ;
  align-items: center;
  justify-content: center;
  float: right;
  .multiselectButton {
    .disable-pointer {
      cursor: none;
    }

    .dropdown-heading {
      cursor: pointer;

      .dropdown-heading-value {
        padding: 0px !important;
        text-align: end !important;

        span {
          padding-right: 0px;
        }
      }
    }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.7);
    height: 30px;
  }

    .show-multiSelect-dropdown {
      display: inherit !important;
    }
    .dropdown-container {
      position: relative;

      .dropdown-content {
        .item-renderer span {
          font-size: 0.75rem !important;
        }

        .panel-content .select-panel .options {
          position: relative;
          word-break: break-word !important;
          margin: 0 !important;
        }
        .filter-search-container {
          display: flex;
          margin: auto;
          margin-bottom: 5px !important;

          .filter-search-box {
            padding-top: 0px !important;
            height: 30px !important;
            padding-bottom: 0px !important;
            margin-left: 18px !important;
            margin-right: 18px !important;
          }
          .filter-search-box input {
            height: 27px !important;
          }
        }   
        .not-found-text{
          display: flex;
          justify-content: center;
          margin-bottom: 0px;
          font-weight: lighter;
        }           
      }

      .panel-content {
        position: fixed !important;
      }
    }

    .dropdown-content {
      display: none;
      padding-top: 5px;
      box-shadow: none !important;
      position: absolute;
      z-index: 5;
      top: 100%;
      width: 100%;
      min-width: 230px;
      right: 0px;

      .panel-content {
        padding: 10px 0px 5px;
        background: #ffffff !important;
        border: 1px solid #fafafa !important;
        box-sizing: border-box !important;
        border-radius: 10px !important;
        box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 4px 11px rgb(0 0 0 / 10%);
      }
    }

    .multiselect-options {
      margin-bottom: 40px !important;
      padding: 5px 0px !important;
      min-height: auto;
      max-height: 250px;
      overflow-y: auto;
      overflow-x: hidden !important;
      list-style: none;

      .menuitem-options {
        padding-right: 0px !important;
        height: 30px;
        display: flex;
        align-items: center;
        position: relative;
        word-break: break-word !important;
        margin: 0 !important;
        padding-top: 2px !important;
        border-left: 2px solid transparent;
        text-align: left;
        &:hover {
          border-left: 2px solid red !important;
          background: #f0f0f0 !important;
          font-weight: 500;
        }

        .ant-menu-title-content{
          width: 100%;
          display: flex;
        }

        .select-item{
          width: 100%;
        }
      }

      .item-renderer div {

        [type="checkbox"]:checked+label:after,
        [type="checkbox"]:not(:checked)+label:after {
          top: 14px;
        }

        [type="checkbox"]:checked+label:before,
        [type="checkbox"]:not(:checked)+label:before {
          top: 10px;
        }
      }
    }

    .custom-multiselect-footer {
      margin: auto;
      position: absolute;
      width: 100%;
      border-left: 0px solid transparent !important; 
      left: 0px;
      border-radius: 10px !important;
      bottom: 0px;
      border-top: 1px solid #f0f0f0;
      background-color: white;
      display: flex;
      align-items: center;
      padding: 0 8px;
      .ant-table-filter-dropdown li {
        border-left: 0px solid transparent !important; 
      }
      .ant-menu-title-content{
        width: 100%;
        display: flex;
      }
      margin-bottom: 0px;
      .primary{
        height: 25px;
        box-shadow: none;
        // &:hover {
        //   border-left: none !important;
        //   box-shadow: none !important;
        // }
      }
      margin-top: 0px ;
    }

    .multiselect-footer {
      border-top: 1px solid #f0f0f0;
      padding: 5px 5px 0px;
      display: flex;
    }

    .multiselect-btn {
      width: fit-content !important;
      height: auto;
      padding: 2px 10px;
    }

    .applyFilter {
      margin-left: auto;
    }

    .disableFilter {
      width: min-content !important;
      height: auto;
      padding: 2px 10px;
      color: rgba(0, 0, 0, 0.25) !important;
      border-color: transparent !important;
      background: transparent !important;
      box-shadow: none !important;
    }
  }

  .multiselectButton-checkbox {
    .ant-checkbox {
      .ant-checkbox-inner {
        border: 1px solid #cbcbcb;
        border-radius: 3px;
        height: 11px;
        width: 11px;
      }
    }

    .ant-checkbox-checked::after {
      border: 0px solid #1c1c1c !important;
      border-radius: 3px;
      height: 11px;
      width: 11px;
      background: #1c1c1c;
    }
  }

  .multiselectButton-checkbox+span {
    padding-left: 10px !important;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 0.8125rem;
    line-height: 30px;
    color: #1c1c1c;
  }

  .multiselect-options {
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 4px;

      &:hover {
        background: #999;
      }
    }
  }

  .noContentFound {
    text-align: center;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 0.8125rem;
    line-height: 30px;
    color: #737373;

  }

  .panel-content {
    position: fixed !important;
  }

  .multiselectButton .dropdown-content .panel-content {
    position: fixed !important;
    padding: 10px 0px 5px;
    background: #ffffff !important;
    border: 1px solid #fafafa !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
    min-width: 215px;
  }

  .custom-table-filter {
    .ljlVrE {
      height: 12px;
    }

    .filter-icon-container {
      .dropdown-heading-value {
        padding: 0px !important;

        .filter-icon{
          // padding: 5px;
          background-color: inherit;
          color: #bfbfbf;
          &:hover {
            color: rgb(28 28 28 / 45%);
            background-color: rgb(0 0 0 / 4%);
            border-radius: 20%;
          }
        }
      }
    }
  }

  .custom-table-filter .multiselectButton .dropdown-content{
    min-width: 120px !important;
  }

  .custom-table-filter .multiselectButton .applyFilter:focus-visible {
    border: 2px solid grey !important;
    margin-left: auto;
    }

    .custom-table-filter .multiselectButton .multiselect-btn:focus-visible {
      border: 2px solid grey !important;

    }
}
.custom-table-filter{
  .ant-menu-item-active{
    border-left: 2px solid red !important;
    background: #f0f0f0 !important;
    font-weight: 500 !important;
  }
  .display-none{
    display: none;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 215px;
}

.adjust-height{
  @media screen and (max-height: 900px) {
    max-height: 220px !important;
  }

  @media screen and (max-height: 830px) {
    max-height: 180px !important;
  }

  @media screen and (max-height: 750px) {
    max-height: 150px !important;
  }
  @media screen and (max-height: 720px) {
    max-height: 135px !important;
  }
}