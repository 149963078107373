.dashboardImageGrid {
  box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011),
    0px 2.7px 3.3px rgba(0, 0, 0, 0.0152067), 0px 5px 6.3px rgba(0, 0, 0, 0.02),
    0px 8.9px 11.2px rgba(0, 0, 0, 0.024),
    0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  max-height: 262px;
  @media only screen and (max-width: 999px) {
    max-height: 260px;
    margin-right: 6px;
     }
  height: auto;
  display: flex;
  width: 100%;
  background: white;
  .dashboardImageGridBox {
    display: inline-block;
    @media only screen and (max-width: 999px) {
    display: none;
     }
    .backgorundImg {
      background-image: url("../../../../../assets/images/dashboardImg.svg");
      background-size: cover;
      width: 284px;
      height: 262px;
      border-radius: 10px 0px 0px 10px;
    }
  }
  .dashboardGridIconBox {
    display: inline-block;
    @media only screen and (max-width: 999px) {
      display: none;
       }
    width: 1px;
    position: relative;
    img {
      position: absolute;
      right: -20px;
      top: 28px;
    }
  }
  .dashboardImageGridText {
    display: inline-block;
    .dashbordContent {
      display: block;
      background: white;
      max-width: 395px;
      @media only screen and (min-width: 1441px) {
       max-width: 100%;
       width: 100%;
      }
      max-height: 262px;
      width: auto;
      border-radius: 10px;
      height: auto;
      padding: 7% 14.8%;
      @media only screen and (max-width: 1117px) {
        padding: 9% 10%;
      }
      @media only screen and (max-width: 1050px) {
        padding: 9% 5%;
      }
      .text {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 18px;
        color: #000000;
        @media only screen and (max-width: 999px) {
          width: 90%;
        }
        margin: 10px 0px;
        padding-right: 0;
      }
      .heading {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4375rem;
        line-height: 32px;
        color: #1c1c1c;
        padding-right: 25%;
        @media only screen and (max-width: 1319px) {
          font-size: 1.25rem;
        }
        @media only screen and (max-width: 1238px) {
          padding-right: 0px;
        }
        @media only screen and (max-width: 1185px) {
          padding-right: 0px;
          font-size: 1.125rem;
        }
        @media only screen and (max-width: 1050px) {
          padding-left: 10px;
        }
        @media only screen and (max-width: 1004px) {
          font-size: 0.9375rem;
        }
        @media only screen and (max-width: 999px) {
          padding-left: 0;
          line-height: 20px;
          font-size: 1.125rem;
          width: 90%
        }
      }
    }
  }
}

.dashboardPieImageGrid {
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  padding-right: 25px !important;
  margin-right: 12px;
  box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011),
  0px 2.7px 3.3px rgba(0, 0, 0, 0.0152067), 0px 5px 6.3px rgba(0, 0, 0, 0.02),
  0px 8.9px 11.2px rgba(0, 0, 0, 0.024),
  0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  width: 50%;
  background-color: #fff;
  @media only screen and (max-width: 1150px) {
    width: 100%;
  }
}

.dashboardMyInsightGrid {
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-left: 10px;
  box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011),
  0px 2.7px 3.3px rgba(0, 0, 0, 0.0152067), 0px 5px 6.3px rgba(0, 0, 0, 0.02),
  0px 8.9px 11.2px rgba(0, 0, 0, 0.024),
  0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  width: 50%;
  background-color: #fff;
  height: 263px;
  overflow-y: hidden;
  @media only screen and (max-width: 1150px) {
    width: 100%;
    margin-top: 27px;
    margin-left: 0px;
  }
} 

.dashboardMyInsightGrid h3 {
  margin: 0px !important;
  padding-top: 40px !important;
  padding-bottom: 8px !important;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  color: #1c1c1c;
}

.dashboardPieImageGrid h3 {
  margin: 0px !important;
  padding-top: 40px !important;
  padding-bottom: 1px !important;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  color: #1c1c1c;
}

.pieGraph {
  height: 160px;
  padding-top: 3px !important;
  cursor: pointer;
  width: 100%;
  display: flex;
}

.dashboardBox {
  padding-left: 12.5px !important;
  padding-right: 12.5px !important;
  padding-bottom: 2%;
}
.upperBox {
  display: flex !important;
  padding-left: 1.08% !important;
  padding-right: 1.08% !important;
  @media only screen and (max-width: 1150px) {
    display:inline !important;
     }
  @media only screen and (max-width: 999px) {
    padding-left: 1px !important;
     }
}
.dashboardImage {
  display: block;
  max-width: 284px;
  max-height: 262px;
  width: auto;
  height: auto;
}
.dashboardIconBox {
  position: absolute;
  z-index: 5;
  display: flex;
  width: 100%;
  justify-content: center;
}
.dashboardIcon {
  z-index: 5;
  padding-right: 18%;
  padding-top: 5%;
}
.dashboardImageBox {
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  width: 100%;
}
.dashboardText {
  background-color: #fff;
  padding-top: 7.43%;
  padding-bottom: 7.43%;
  padding-left: 14.41%;
  padding-right: 14.41%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.dashboardText .heading {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4375rem;
  line-height: 32px;
  color: #1c1c1c;
  padding-right: 25%;
}
.dashboardText .text {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 18px;
  color: #000000;
  margin: 10px 0px;
  padding-right: 10%;
}
.dashBoardButton {
  max-width: 150px;
  width: 100%;
  margin-top: 30px;
  @media only screen and (max-width: 1261px) {
    margin-top: 8%;
  }
  @media only screen and (max-width: 999px) {
    a{
      button{
        width: 100%;
      }
    }
  }
}
.dashBoardButton button {
  padding-left: 18.5px;
  padding-right: 18.5px;
  height: 28px !important;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
  @media only screen and (max-width: 1000px) {
    padding-left: 5%;
  padding-right: 5%;
  }
}
.feedbackButton {
  width: 100%;
  font-size: 0.75rem !important;
  background: #1c1c1c !important;
  color: #ffffff !important;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.15),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.107828),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0894161),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.075),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0605839),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0421718) !important;
  border-radius: 50px !important;
  cursor: pointer;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  align-items: center;
  text-align: center !important;
  border: 1px solid #1c1c1c !important;
  &:hover {
    background: #4d4d4d !important;
    box-shadow: 0px 94px 138px rgba(0, 0, 0, 0.2),
      0px 47.0459px 69.0674px rgba(0, 0, 0, 0.152067),
      0px 28.3382px 41.603px rgba(0, 0, 0, 0.130318),
      0px 18.1606px 26.6614px rgba(0, 0, 0, 0.114179),
      0px 11.7703px 17.2797px rgba(0, 0, 0, 0.1),
      0px 7.40924px 10.8774px rgba(0, 0, 0, 0.0858211),
      0px 4.25707px 6.24974px rgba(0, 0, 0, 0.0696822),
      0px 1.87365px 2.75067px rgba(0, 0, 0, 0.0479335) !important;
    border-radius: 50px;
    border: 1px solid #4d4d4d !important;
  }
  &:active {
    background: #737373 !important;
    box-shadow: 0px 51px 80px rgba(0, 0, 0, 0.14),
      0px 21.3066px 33.4221px rgba(0, 0, 0, 0.10064),
      0px 11.3915px 17.869px rgba(0, 0, 0, 0.083455),
      0px 6.38599px 10.0172px rgba(0, 0, 0, 0.07),
      0px 3.39155px 5.32008px rgba(0, 0, 0, 0.056545),
      0px 1.4113px 2.21381px rgba(0, 0, 0, 0.0393604) !important;
    border-radius: 50px;
    border: 1px solid #737373 !important;
  }
}
.feedbackButton span {
  color: #ffffff;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 20px;
}
.dashboardGraph {
  margin: 0px !important;
  padding-top: 40px !important;
  padding-bottom: 20px !important;
  @media only screen and (max-width: 999px) {
    margin-right: 6px !important;
    padding-bottom: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
     }
  cursor: pointer;
}
.lowerBox {
  padding-left: 1.08% !important;
  padding-right: 1.08% !important;
  @media only screen and (max-width: 999px) {
    padding-left: 1px !important;
     }
}
.dashboardGraph h3 {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  color: #1c1c1c;
}
.graphSubHeading {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  color: #737373;
}
.graphCount {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  color: #1c1c1c;
  margin-left: 10px;
}
.dashboardGraph div div svg g g:nth-child(2) g text {
  font-family: IBM Plex Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 0.625rem !important;
  line-height: 20px;
  color: #1c1c1c;
}
.dashboardGraph div div svg g g:nth-child(3) g text {
  font-family: IBM Plex Sans !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem !important;
  line-height: 25px;
  color: #000000;
}
@media screen and (min-device-width: 1441px) and (max-device-width: 1920px) {
  .dashboardText .heading {
    padding-right: 40%;
  }
  .dashboardText .text {
    padding-right: 33%;
  }
}
@media screen and (min-device-width: 1025px) and (max-device-width: 1439px) {
  .dashboardText .heading {
    padding-right: 33%;
  }
}
@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
  .dashboardText .heading {
    padding-right: 0%;
  }
  .dashboardText .text {
    padding-right: 0%;
  }
}
@media screen and (min-device-width: 426px) and (max-device-width: 768px) {
  .dashboardText .heading {
    padding-right: 0%;
  }
  .dashboardText .text {
    padding-right: 0%;
  }
  
}
@media screen and (min-device-width: 376px) and (max-device-width: 425px) {
  .dashboardText .heading {
    padding-right: 0%;
  }
  .dashboardText .text {
    padding-right: 0%;
  }
  .dashboardGraph {
    margin: 0px !important;
  }
}
@media screen and (min-device-width: 321px) and (max-device-width: 375px) {
  .dashboardText .heading {
    padding-right: 0%;
  }
  .dashboardText .text {
    padding-right: 0%;
  }
  .dashboardGraph {
    margin: 0px !important;
  }
}
@media screen and (min-device-width: 300px) and (max-device-width: 320px) {
  .dashboardText .heading {
    padding-right: 0%;
  }
  .dashboardText .text {
    padding-right: 0%;
  }
  .dashboardGraph {
    margin: 0px !important;
  }
}
.graphStyle{
  display: flex;
}

.mainLegendDiv {
  display: flex;
  justify-content: space-around;
  @media only screen and (min-width: 891px) {
    display: none;
  }
  .legendsPie{
    margin-top: 10px;
  }
}

.mainDesktopLegendDiv {
  display: flex;
  align-items: center;
  padding-right: 10px;
  @media only screen and (max-width: 890px) {
    display: none;
  }  
}

.pieValues{
  float: right;
}

.pieLabels {
  padding-right: 10px !important;
}

.showPieColourCode {
  font-size: 0.75rem;
  word-spacing: 6px;
  width:120px;
}

.pieTextMessage {
  text-align: center;
    margin: auto;
}

.lineGraph{
  width: 100%;
  height:18vh
}
.dashGraph svg {
  overflow: initial !important;
}

.myInsightLinkAlign{
  margin-left: auto;
  color: #4d4d4d;
  text-decoration: underline;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-family: 'IBM Plex Sans';
  font-weight: 500;
}

.myInsightGrid {
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011),
  0px 2.7px 3.3px rgba(0, 0, 0, 0.0152067), 0px 5px 6.3px rgba(0, 0, 0, 0.02),
  0px 8.9px 11.2px rgba(0, 0, 0, 0.024),
  0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding-top: 20px;
  cursor: pointer;

  &:hover {    
    box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.016), 0px 5px 6.3px rgba(0, 0, 0, 0.12), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
    
  }
}

.myInsightGrid h3{
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  color: #1c1c1c;
}

.dashboardChart {
  justify-content: space-between;
}

.insightGraphDashboard {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  font-family: IBM Plex Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  color: #4D4D4D;
}

.dashboardTooltip {
  color: #737373 !important;
}

.dashboardAlertList {
  margin-bottom: 28px;
  margin-top: 55px;
  height: 70vh;
}

.insightNameList {
  width: 100%;
  height: calc(100% - 68px);
  overflow-y: auto;
  margin-top: 8px;
  &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #f0f0f0;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 4px;
  
      &:hover {
        background: #999;
      }
    }
}

.insightListItems {
  width: 100%;
  // box-shadow: 0px 1.1px 1.4px rgb(0 0 0 / 1%), 0px 2.7px 3.3px rgb(0 0 0 / 2%), 0px 5px 6.3px rgb(0 0 0 / 2%), 0px 8.9px 11.2px rgb(0 0 0 / 2%), 0px 16.7px 20.9px rgb(0 0 0 / 3%), 0px 40px 50px rgb(0 0 0 / 4%);
  // border-radius: 10px;
  background-color: #fff;
  padding-left: 8px;
  margin-top:3px ;
  margin-bottom: 3px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  height: 40px;
  cursor: pointer;
  line-height: 33px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;  
  &:hover {
    text-decoration: underline;
  }
}

.editMyInsight {
  cursor: pointer;
  padding-top: 40px;
}

.myinsightHeaderBox {
  justify-content: space-between;
}

.noReportData {
  text-align: center;
  margin-top: 40px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.85rem;
}

.dashboardAlertList .loaderBody {
  top: 0px;   
  margin-top: 20px;
}

.insightDivider {
  // width: 100%;
  overflow: hidden; 
  // border-color: #EDEDED !important;
  // margin: 0px !important;
  // margin-left: 18px !important;
  // min-width: 0px !important;
  width: 100% !important;
                        
}

.myinsight-edit-button {
  margin-top: 2.7em;
}