.gutter-row {
  padding-top: 15px;
  padding-bottom: 15px;
}
.cardWrapper {
  background: #ffffff;
  box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011),
    0px 2.7px 3.3px rgba(0, 0, 0, 0.016), 0px 5px 6.3px rgba(0, 0, 0, 0.02),
    0px 8.9px 11.2px rgba(0, 0, 0, 0.024),
    0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
  transition: all 0.1s ease-out;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #fff !important;
  height: 100%;
  &:hover {
    border-color: #cbcbcb !important;
    box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.028),
      0px 2.7px 3.3px rgba(0, 0, 0, 0.04), 0px 5px 6.3px rgba(0, 0, 0, 0.05),
      0px 8.9px 11.2px rgba(0, 0, 0, 0.06),
      0px 16.7px 20.9px rgba(0, 0, 0, 0.072), 0px 40px 50px rgba(0, 0, 0, 0.1);
  }
  &:active {
    transition: all 0.05s ease-out;
    opacity: 0.8;
  }
}
.cardWrapper .ant-card-body {
  padding: 2.813rem 2.188rem;
  // padding: 4.5% 3.5%;
}
.cardContainer {
  position: relative;
  float: right;
  cursor: pointer;
}
.cardContainer {
  position: absolute;
  display: inline-block;
  top: 25px;
  right: 19px;
}
.ant-card-body .img-top {
  display: block;
  visibility: hidden;
}
.ant-card-body:hover .img-top {
  display: block;
  visibility: visible;
}
.userCardProfilePic {
  display: flex;
  align-items: center;
}
.imgContainer {
  height: 35px;
  width: 35px;
  object-fit: cover;
  vertical-align: middle;
  filter: drop-shadow(0px 76px 60px rgba(0, 0, 0, 0.09)),
    drop-shadow(0px 31.751px 25.0666px rgba(0, 0, 0, 0.0646969)),
    drop-shadow(0px 16.9756px 13.4018px rgba(0, 0, 0, 0.0536497)),
    drop-shadow(0px 9.51638px 7.51293px rgba(0, 0, 0, 0.045)),
    drop-shadow(0px 5.05408px 3.99006px rgba(0, 0, 0, 0.0363503)),
    drop-shadow(0px 2.10311px 1.66035px rgba(0, 0, 0, 0.0253031));
  box-shadow: 0px 76px 60px rgba(0, 0, 0, 0.09),
    0px 31.751px 25.0666px rgba(0, 0, 0, 0.0646969),
    0px 16.9756px 13.4018px rgba(0, 0, 0, 0.0536497),
    0px 9.51638px 7.51293px rgba(0, 0, 0, 0.045),
    0px 5.05408px 3.99006px rgba(0, 0, 0, 0.0363503),
    0px 2.10311px 1.66035px rgba(0, 0, 0, 0.0253031);

  border-radius: 50px;
}
.imgContaineravt {
  height: 35px;
  width: 35px;
  object-fit: cover;
  vertical-align: middle;
  filter: drop-shadow(0px 76px 60px rgba(0, 0, 0, 0.09)),
    drop-shadow(0px 31.751px 25.0666px rgba(0, 0, 0, 0.0646969)),
    drop-shadow(0px 16.9756px 13.4018px rgba(0, 0, 0, 0.0536497)),
    drop-shadow(0px 9.51638px 7.51293px rgba(0, 0, 0, 0.045)),
    drop-shadow(0px 5.05408px 3.99006px rgba(0, 0, 0, 0.0363503)),
    drop-shadow(0px 2.10311px 1.66035px rgba(0, 0, 0, 0.0253031));
  box-shadow: 0px 76px 60px rgba(0, 0, 0, 0.09),
    0px 31.751px 25.0666px rgba(0, 0, 0, 0.0646969),
    0px 16.9756px 13.4018px rgba(0, 0, 0, 0.0536497),
    0px 9.51638px 7.51293px rgba(0, 0, 0, 0.045),
    0px 5.05408px 3.99006px rgba(0, 0, 0, 0.0363503),
    0px 2.10311px 1.66035px rgba(0, 0, 0, 0.0253031);

  border-radius: 50px;
}
.MB-6 {
  margin-bottom: -6px;
}
.blockUserContImg img {
  opacity: 0.2;
}
.blockUserContImg .imgContaineravt {
  vertical-align: baseline !important;
  opacity: 0.6;
}
.blockedUserCardImg {
  background-image: url(../../../../../../assets/images/blockUserRedIcon.svg);
  height: 12px;
  width: 12px;
  position: absolute;
  top: 70px;
  left: 55px;
}
.inviteUserCardImg{
  background-image: url(../../../../../../assets/images/resendMail.svg);
  height: 12px;
  width: 12px;
  position: absolute;
  background-repeat: no-repeat;
  top: 71px;
  left: 55px;
}
.newUsercard {
  background: #f5f5f5 !important;
  border: 1px dashed #e6e6e6 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  min-height: 255px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-out;
  &:hover {
    border-color: #cbcbcb !important;
    background-color: #ededed !important;
  }
  &:active {
    transition: all 0.05s ease-out;
    opacity: 0.8;
  }
}
.newUsercard:hover p {
  color: #1c1c1c;
}
.contentSlicing {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nameContentSlicing{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardContainer > div {
  padding: 11px 14.5px;
}
.imgContaineravt div:first-child span {
  cursor: pointer;
}