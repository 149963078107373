.orgListContainer {
  padding: 15px 0px;
}
.organisationListCard {
  background: #FFFFFF !important;
  box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.016), 0px 5px 6.3px rgba(0, 0, 0, 0.02), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
  border-radius: 10px !important;
  margin-bottom: 3px !important;
  height: 75px;
  border: 1px solid #fff !important;
  &:hover {
    border-color: #CBCBCB !important;
  }
}
.organisationListCard .ant-card-body {
  padding: 20px 0px;
}
.organisationLogoPic {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.organisationLogoImg {
  height: 35px;
  width: 35px;
  box-shadow: 0px 76px 60px rgba(0, 0, 0, 0.09),
    0px 31.751px 25.0666px rgba(0, 0, 0, 0.0646969),
    0px 16.9756px 13.4018px rgba(0, 0, 0, 0.0536497),
    0px 9.51638px 7.51293px rgba(0, 0, 0, 0.045),
    0px 5.05408px 3.99006px rgba(0, 0, 0, 0.0363503),
    0px 2.10311px 1.66035px rgba(0, 0, 0, 0.0253031);
  border-radius: 50px;
}
.organisationShortName {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  display: flex !important;
  align-items: center;
  color: #1C1C1C;
  padding-left: 10px;
}
.organisationName{
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  display: flex !important;
  align-items: center;
  color: #1C1C1C;
  padding-left: 10px;
}
.organisationCountry{
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  display: flex !important;
  align-items: center;
  color: #4D4D4D;
  padding-left: 10px;
}
.orgShortNameContentSlicing {
  white-space: nowrap; 
  // width: 15vw; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.organisationListWrapper {
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    border-radius: 10px;
    box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.028), 0px 2.7px 3.3px rgba(0, 0, 0, 0.04), 0px 5px 6.3px rgba(0, 0, 0, 0.05), 0px 8.9px 11.2px rgba(0, 0, 0, 0.06), 0px 16.7px 20.9px rgba(0, 0, 0, 0.072), 0px 40px 50px rgba(0, 0, 0, 0.1);
  }
  &:active {
    transition: all 0.05s ease-out;
    opacity: 0.8; 
  }
}
@media screen and (max-device-width: 1024px) {
  .orgShortNameContentSlicing {
    white-space: nowrap; 
    // width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.orgNameContentSlicing {
  white-space: nowrap; 
  width: 20vw; 
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-device-width: 768px) {
  .orgNameContentSlicing {
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.orgCountryContentSlicing {
  white-space: nowrap; 
  width: 29vw; 
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-device-width: 1024px) {
  .orgCountryContentSlicing {
    white-space: nowrap; 
    width: 90%; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.orgListContainer {
  padding-bottom: 30px !important;
}
.editInfoBtn {
  width: 70px;
  margin-top: 2px;
  margin-left: auto;
  right: 15px;
 }