@Disabled:url(../../../../../assets/images/disabled.svg),auto;
.myworkitem-list {
    table {
        text-align: center;
        tr {
            td {
                min-width: 200px;
                max-width: 250px;
            }
            td:first-child {
                border-radius: 10px 0 0px 10px;
              }
            td:last-child{
                border-radius: 0px 10px 10px 0px;
            }

        }
        .ant-table-thead {
           
            th.ant-table-cell{
                color: #4d4d4d !important;
            }
            th.ant-table-column-has-sorters {
                .ant-table-column-sorter-full {
                    display: initial;
                }

                .ant-table-filter-column {
                    .ant-table-column-sorter-full {
                        display: initial;
                    }
                }

                .ant-dropdown-trigger {
                    display: flex;
                    align-items: center;
                }

                &:hover {
                    .ant-table-filter-column {
                        .ant-table-column-sorter-full {
                            display: block !important;
                            height: auto;
                        }
                    }

                    .ant-dropdown-trigger {
                        display: flex !important;
                        align-items: center;
                        margin: -4px -8px -4px 4px;
                    }
                }
            }
        }
    }
}

.optionMenu .ant-form-item {
    margin: 0 8px 4px;

}

.runNowDisabled {
    cursor: @Disabled !important;
}

.schedule-workitem-name {
    min-height: 54px;  
    background: #ffffff;
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    cursor: @Disabled !important;
    caret-color: transparent;
    display: flex;
    align-items: center;
    padding-left: 15px;  
    color: #737373;
    text-align: left;  
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 100%;
    line-height: 20px !important;
    margin-bottom: 10px !important;
    flex: 1;
    min-width: 0;
}


.myworkitem-table-col-name {
    display: flex;
    flex: 1;
    min-width: 0;
}

.myworkitem-name-col {
    display: flex;
    flex: 1;
    min-width: 0;
    margin-top: 10px !important;
}

.more-actions-icon {
    font-size: 14px;
    cursor: pointer;
    color: black;
}
