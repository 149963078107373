.headerTextArea {
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left:20px;
    padding-right:40px;
    resize: none;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem !important;
    line-height: 20px !important;
    color: #1C1C1C;
    width: 100%;
}

.dlt-btn {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    --antd-wave-shadow-color: transparent !important;
    &:focus {
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        color: transparent !important;
        --antd-wave-shadow-color: transparent !important;
    }
    &:hover {
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        color: transparent !important;
        --antd-wave-shadow-color: transparent !important;
    }
}

.all-saved-btn {
    background: #EDEDED !important;
    border-radius: 50px !important;
    border:none;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #4D4D4D !important;
    height: 30px !important;
    width: 150px !important;
    margin-left: 25px;
    box-shadow: none;
    padding: 0px !important;
    &:disabled{
        cursor: url("../../../../../../assets/images/disabled.svg"), auto;
    }
}
.apply-btn{
    background: #1C1C1C !important;
    color: #FFFFFF !important;
    border-radius: 50px !important;
    border:none;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 20px;
    align-items: center;
    text-align: center;
    height: 30px !important;
    width: 150px !important;
    margin-left: 25px;
    box-shadow: none;
    padding: 0px !important;
    span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block !important;
        padding-left: 5px;
    }
    &:focus-visible {
        border: 3px solid grey !important;
    }
}
.roleStyle {
    padding: 0px 23px 29px 23px !important;
}