.device-config-body {
  font-style: normal;
  font-weight: 500;
}

.device-property-main {
  margin-left: 1em;

  .options-container {
    overflow: hidden;
  }

  .options {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .slide-left {
    transform: translateX(-100%);
  }

  .slide-right {
    transform: translateX(0%);
  }
}

.slider-menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.slider-menu-items {
  border-left: 3px solid transparent;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;

  &:hover {
    border-left: 3px solid #ee0000;
    background: #f0f0f0 !important;
    cursor: pointer;
  }
}

.slider-icon {
  align-items: center;
  display: flex !important;
  cursor: pointer;
  height: 1.5rem;
  color: #36454f !important;
  margin-left: 1rem !important;
}

.folder-items {
  white-space: nowrap;
  display: flex;
  margin-bottom: 0px;
}
.nodeDeviceText{
  display: inline-block !important;
  width: 308px ;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selected-menu-item {
  border-left: 3px solid #ee0000;
  margin-right: 0.5rem;
}

.default-menu-item {
  border-left: 3px solid transparent;
  margin-right: 0.5rem;
}

.red-dot {
  background-color: red;
  border-radius: 50%;
  margin-top: 0.6rem;
}

.changed-settings {
  width: 5px;
  height: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

.config-bread-crumbs {
  display: inline-block;
  font-size: 12px !important;
  padding: 2px;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  cursor: default;
}

.bread-crumb-item {
  cursor: pointer !important;
}

.option-name {
  text-align: left;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem !important;
  line-height: 26px;
  align-items: center;
  margin-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  cursor: pointer;
}

.config-property-title {
  margin-bottom: 1.5rem;
  margin-left: 1.1rem;
}

.home-icon {
  margin-right: 0.2em;
}

.copy-image-properties {
  cursor: pointer;
  margin-top: 0.2em;
  margin-right: 1em;

  .ant-checkbox-inner {
    border: 1px solid rgba(28, 28, 28, 0.45);
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(66, 133, 244);
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: rgba(28, 28, 28, 0.45) !important;
    background-color: #E5E4E2 !important;
  }
}

.property-description {
  margin-top: 0.1em;
  margin-left: 0.5em;
}

.device-property-description {
  display: "flex";
  align-items: "baseline";
}

.propery-node-id {
  cursor: default !important;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: #1c1c1c !important;
}

.config-delete-icon {
  cursor: pointer;
  padding-right: 10px;
  padding-left: 2px;
}

.config-arrow-icon {
  cursor: pointer;
  padding-right: 10px;
  padding-left: 2px;
}

.config-toggle-switch {
  margin-left: 5px !important;
  border: 1px solid #cbcbcb !important;
  box-sizing: border-box !important;
  border-radius: 50px !important;

  .ant-switch-handle {
    top: 1px;
  }
}

.config-toggle-on {
  background: #13a922 !important;
}

.config-toggle-on .ant-switch-handle::before {
  right: 8px !important;
  left: -8px !important;
}

.config-views .ant-tabs-tab {
  padding: 8px 12px 8px 0px !important;
}

.config-views > div.ant-tabs-nav {
  padding: 0 !important;
}

.config-views .ant-tabs-nav-wrap {
  padding-left: 10px;
}

.config-input-fields {
  width: 100% !important;
}

.tool-icon {
  align-items: center;
  display: flex !important;
  cursor: pointer;
  height: 1.5rem;
  margin-left: 1rem !important;
  color: #36454f !important;
}

.disabled-delete-icon{
  align-items: center;
  display: flex !important;
  cursor: pointer;
  height: 1.5rem;
  margin-left: 1rem !important;
  color: #969696 !important;
}

.title-checkbox {
  margin-right: 1rem !important;

  .ant-checkbox-inner {
    border: 1px solid rgba(28, 28, 28, 0.45);
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(66, 133, 244);
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: rgba(28, 28, 28, 0.45) !important;
    background-color: #E5E4E2 !important;
  }
}

.select-property {
  margin-left: -1em;
  margin-right: 0.2em;
  margin-top: 0.1em;
}

.config-divider {
  max-width: 3% !important;
}

.config-icons {
  display: flex;
}

.multi-select-checkbox {
  display: flex;
  cursor: pointer;
  margin-left: 0.4em;

  .ant-checkbox-inner {
    border: 1px solid #CBCBCB !important;
    border-radius: 3px !important;
    transition: all 0s !important;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: #CBCBCB !important;
    outline: 4px solid #f5f5f5 !important;
    outline-offset: -5px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: black;
    outline: 4px solid white;
    outline-offset: -5px;
    border: 1px solid #CBCBCB !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 0px !important;
  }

  .ant-checkbox-checked::after {
    border: 0px !important;
  }
}

.config-body-divider {
  height: 62vh !important;
}

.config-property-divider {
  width: 100%;
}

.config-menu-container {
  height: 58vh !important;
  
  .ant-popover-placement-right{
    left: 26px !important;
  }
}

.config-property-container {
  height: 62vh;
}

.config-no-data {
  height: 50vh;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: gray;
}

.config-reset-button {
  margin-top: 0.5em;
}

.node-item-container{
  display: flex;
  justify-content: space-between;
  width: calc(100% - 30px) !important;
}

.red-dot-for-tab{
  background-color: red;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin-right: 5px;
  margin-left: 5px;
  position: absolute;
  left: -15px !important;
}

.reset-default-button {
  max-width: 9em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.groups-header{
  display: flex;
  justify-content: space-between;
}

.device-config-picker {
  border-radius: 10px;
  height: 53px;
  width: 100%;
  margin: 0;
  color: #1c1c1c;
  font-size: 0.875rem;
  font-variant: tabular-nums;
  line-height: 20px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  padding: 0px 4px !important;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  transition: border 0.3s, box-shadow 0.3s;

  .ant-picker-input > input {
    text-align: start;
    padding: 0 11px !important;
  }

  .ant-picker-suffix {
    display: none;
  }
}

.config-locked-message {
  border: 1px solid #f5c2c7;
  color: #842029;
  padding: 1em;
  margin-bottom: 1em;
  background-color: #f8d7da;
}

.config-locked-icon {
  margin-right: 0.5em;
}

.config-info-popover .ant-popover-content {
  max-width: 38vw !important;
  @media screen and (max-width: 900px) {
    .folder-items{
      white-space: normal !important;
      word-wrap: break-word;
      display: block;
    }
  }
}

.custom-validation-input {
  .ant-form-item-explain-error{
    display: none !important;
  }
  .ant-form-item-explain-success{
    display: none !important;
  }
  .ant-show-help-leave {
    border: 1px solid red;
    display: none !important;
  }
  .ant-show-help-enter {
    border: 1px solid red;
    display: none !important;
  }
  .ant-show-help{
    display: none !important;
  }
}

.validationMessage{
  color: #EE0000 !important;
  font-family: IBM Plex Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem !important;
  line-height: 20px !important;
}

.property-default {
  white-space: pre-line;
}

.node-id-container {
  display: flex;
  align-items: center;
}
.node-id-copy-button {
  padding-left: 1vw;
  cursor: pointer;
}

.pr-5{
  padding-right: 5px;
}

.add-group {
  margin-left: 5px;
  margin-right: 5px;
  .export-icon {
    margin-right: 6px;
  }
}

.add-group .loaderButton {
  top: 0% !important;
  margin-bottom: 8px !important;  
  padding-left: 5px !important;
}


.description-input {
  color: #4d4d4d !important;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem !important;
  margin-left: 5px;
}