.roleListRow{
  .arrowButton{
    display: none !important;
  }
}

.subscribeSubtitle{
  padding-bottom: 0;
  .sc-giImIA{
font-family: IBM Plex Sans;
font-style: normal;
font-weight: 500;
font-size: 0.75rem ;
line-height: 20px;
color: #1C1C1C;
  }
}

.orgDetailContainer{
  .sc-eCstlR{
    position: absolute;
    bottom: 0;
  }
  button{
    max-width: 200px;
    width: 93%;
    margin-left: auto;
  }
}
.subsHeading {
font-size: 0.75rem !important;
}
.genBasicInfo {
  .loaderBody {
    top: 0%;
    margin-top: 5%;
  }
}
.genSubscription {
  .loaderBody {
    top: 0%;
    margin-top: 4.2%;
  }
}
.genSettingHead {
  padding-top: 2.1%;
}

.generalSettingsDiv {
  height: 100% !important;
}

.generalSettingWrapper {
  display: flex !important;
  align-items: center;
}

.generalSettingWrapper .manageUserWrapper {
  margin-left: 10px;
}

.generalSettingWrapper .smallBlack {
   margin-left: 10px;
   max-width: 160px;
   display: flex;
   align-items: center;
   padding-left: 10px;
}
.generalSettingCover {
  padding-bottom: 3px;
}

.generalSettingBtn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15vw;
}

.smallBlack .manageUserImg {
  margin-bottom: 4px;
}