.alertMessage {
    color: #1c1c1c;
    font-weight: 500;
    font-size: 0.875rem;
}

.warningMessage {
    color: #1c1c1c;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
}
.alertBlock {
    width: 95%; 
    align-items: center;
    justify-content: center;
    display: block;
    margin-left: 7px;
}
.userAlertIcon {
    width: 24.36px;
    height: 30px;
}
.warningEllipsis {
    white-space: nowrap;
    width: 16vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.alertTime {
  white-space: nowrap;
}
.alertDescription {
  white-space: nowrap;
  width: 18vw;
  overflow: hidden;
  text-overflow: ellipsis;
}
  @media screen and (min-device-width: 1441px) and (max-device-width: 1920px) {
    .warningEllipsis {
      white-space: nowrap;
      width: 18vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .alertDescription {
      white-space: nowrap;
      width: 20vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
    .warningEllipsis {
      white-space: nowrap;
      width: 14vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .alertDescription {
      white-space: nowrap;
      width: 16vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (min-device-width: 426px) and (max-device-width: 768px) {
    .warningEllipsis {
      white-space: nowrap;
      width: 17vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .alertDescription {
      white-space: nowrap;
      width: 21vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (min-device-width: 376px) and (max-device-width: 425px) {
    .warningEllipsis {
      white-space: nowrap;
      width: 58vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .alertDescription {
      white-space: nowrap;
      width: 65vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (min-device-width: 321px) and (max-device-width: 375px) {
    .warningEllipsis {
      white-space: nowrap;
      width: 65vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .alertDescription {
      white-space: nowrap;
      width: 76vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (min-device-width: 300px) and (max-device-width: 320px) {
    .warningEllipsis {
      white-space: nowrap;
      width: 74vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .alertDescription {
      white-space: nowrap;
      width: 88vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .dashboardAlertCard {
    width: 100%;
  }
  
  .alertDivider {
    width: 100%;
    overflow: hidden; 
    // border-color: #EDEDED !important;
    // margin: 0px !important;
    // margin-left: 18px !important;
    // min-width: 0px !important;
    // width: 100% !important;
                          
  }