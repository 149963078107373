.column-1{
    padding-right: 57px;
}
.eventTypeIcon{
    margin-right:12px;
    width:8px;
    height:8px;
}
.hrStyle{
    border-top: 1px solid #EDEDED;
    margin-top: 22px;
    margin-bottom: 16px;
    width: 277px;
}
.hrStyle-2{
    border-top: 1px solid #EDEDED;
    margin-top: 15px;
    width: 277px;
}
.eventDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.eventShow{
    margin-left: 8px;
    display: none;
}
.eventDescription{
    margin-top: 7px;
    pre{
        font-family: IBM Plex Sans;
        font-size: 0.75rem;
    }
    .CopyOutputBtn{
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem !important;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute !important;
        width: 124px;
        height: 28px;
        right: 20px !important;
        top: 27px ;
        background: #1C1C1C !important;
        box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.15), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.107828), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0894161), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.075), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0605839), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0421718);
        border-radius: 50px;
        padding-top: 4px;
        padding-bottom: 4px;
        color: #FFFFFF !important;
        cursor: pointer;
        border: none;
        &:hover {
            background: #4D4D4D !important;
            box-shadow: 0px 94px 138px rgba(0, 0, 0, 0.2), 0px 47.0459px 69.0674px rgba(0, 0, 0, 0.152067), 0px 28.3382px 41.603px rgba(0, 0, 0, 0.130318), 0px 18.1606px 26.6614px rgba(0, 0, 0, 0.114179), 0px 11.7703px 17.2797px rgba(0, 0, 0, 0.1), 0px 7.40924px 10.8774px rgba(0, 0, 0, 0.0858211), 0px 4.25707px 6.24974px rgba(0, 0, 0, 0.0696822), 0px 1.87365px 2.75067px rgba(0, 0, 0, 0.0479335);
        }
        &:focus-visible {
            border: 2px solid white !important;
        }
    }
}
.mt-24{
    margin-top: 24px;
}
.mt-31{
    margin-top: 31px;
}
.pt-5{
    padding-top: 5px;
}
.pt-8{
 padding-top: 8px;
}
.pt-18{
    padding-top: 18px;
}
@media screen and (max-width: 992px) {
    .column-2{
        flex: 100% !important;
    }
    .eventHide{
        display: none;
    }
    .eventShow{
        display: block ! important;
    }
    .eventDescription{
        margin-top: 23px;
        div{
            height: 65vh;
        }
    }
    .CopyOutputBtn{
        top:43px !important;
    }
    .eventTypeIcon{
        margin-right: 9px !important;
    }
    .hrStyle{
        margin-top: 17px;
        margin-bottom: 15px;
        width: 277px;
    }
}