.color-picker-input {
  border: 0px solid transparent !important;
}

/* WebKit browsers (Chrome, Safari) */
.color-picker-input::-webkit-color-swatch {
  border-radius: 8px;
}

/* Firefox */
.color-picker-input::-moz-color-swatch {
  border-radius: 8px;
}

/* Edge and other browsers */
.color-picker-input::-ms-color-swatch {
  border-radius: 8px;
}