.work-item-conatiner{
  display: flex;
  align-items: center;
  padding-top: 1.1vh;
  margin-bottom: 30px;
  justify-content: space-between;
  padding-left: 2.75vw;
  padding-right: 6px;
}

.work-item-title {
  display: flex !important;
  align-items: center;
  font-size: 1.4375rem;
  font-weight: 500;
  line-height: 30px;
  padding-bottom: 5px;
  flex-wrap: wrap;
  grid-row-gap: 0.5em !important;
  .work-item-heading {
    white-space: nowrap;
    margin-right: 20px;
  }
}

.work-item-table{
  table {
    thead {
      tr {
        th:nth-child(2) {
          padding-left: 0px;
        }
      }
    }
    thead > tr > th:hover .ant-table-filter-trigger {
      visibility:visible;
    }
    // .ant-table-filter-trigger {
    //   visibility: hidden;
    // }
  

    tbody {
      tr {
        td {
          min-width: 100px;
          max-width: 140px;
          cursor: pointer;

          .work-item-lastColumn {
            display: flex;
            align-items: center;

            .work-item-expandIcon {
              padding-left: 20px;
              margin-left: auto;
            }
          }
        }

        td:first-child {
          border-radius: 10px 0 0px 10px;
          min-width: 16px !important;
          max-width: 16px !important;
          width: 1%;
          padding: 0px !important;
        }

        td:last-child {
          border-radius: 0px 10px 10px 0px;
        }
      }

      .work-item-expanded {
        td .work-item-expandIcon img {
          transform: rotate(90deg);
        }
      }

      .ant-table-expanded-row {
        td {
          background: #fafafa;
          cursor: auto !important;

          &:hover {
            background: #fafafa !important;
          }
        }
      }
    }
  }
}

.progress-icons {
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
}

.progress-icons > span {
  margin-right: 1rem !important;
}

.work-item-description {
  margin: 25px 5% 40px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
    0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
  border-radius: 10px;
  padding: 30px 50px;
}

.work-item-header{
 height: 43px;
}
.work-item-header p{
  font-weight: normal;
  font-size: 14px;
  color: black;
  line-height: 20px;
  margin: 0;
}

.workItemName {
  @media screen and (max-width:1300px){
    max-width: 100%;
  }
}
.work-item-col-1 {
  width: 33%;

  .work-item-content-1 {
    margin-left: 58px;
  }

  .work-item-content-2 {
    padding-left: 25%;
  }
}

.work-item-progress {
  text-transform: capitalize;
}

.work-centre-div {
  margin-left: 3.5%;
}

.work-retry-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  min-width: 12vw !important;
  white-space: nowrap;
}

.delete-work-icon {
  width: 23px;
  padding-right: 8px;
}