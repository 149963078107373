@import "../colors.less";

@menu-item-color: @GRAY;
@menu-item-vertical-margin: 1.875rem;
@menu-item-active-bg: fade(#3e3e3e, 70%);
@menu-highlight-color: @WHITE;
@menu-item-active-border-width: 0px;
@menu-horizontal-line-height: 40px;

.ant-menu-inline .ant-menu-item {
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75rem;
  }
}

.ant-menu-inline .ant-menu-item-selected::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  content: "";
  box-sizing: border-box;
  transform: scaleY(1);
  opacity: 1;
  // border-left: 0.125rem solid @RED;
  border-right: none;
}
