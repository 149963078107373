@Disabled:url(../../../../../assets/images/disabled.svg),auto;
  
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 54px !important;
    background: #ffffff;
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: justify;
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: normal;
    color: #1c1c1c;
    cursor: pointer;
    caret-color: transparent;
    display: flex;
    align-items: center;
    padding-top: 0px !important;
    &:hover {
      cursor: pointer;
      border: 1px solid #cbcbcb;
    }
    &:active {
      border: 1px solid #ededed;
    }
    &:focus {
      border-color: 1px solid #55c1f6 !important;
    }
  }
  .ant-select-single .ant-select-selector:focus {
    border-color: 1px solid #55c1f6 !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: transparent;
  }
  .ant-select-item-option-selected .ant-select-item-option-content {
    font-weight: 500 !important;
  }
  .dropdown-header .ant-select .ant-select-single .ant-select-show-arrow{
    width: 100% !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 20px;
}
.ant-select-disabled .ant-select-selector .ant-select-selection-item,
.ant-select-disabled .ant-select-selector,
.ant-select-disabled .ant-select-selector:hover,
.ant-select-disabled .ant-select-selector .ant-select-selection-search,
.ant-select-disabled .ant-select-selector .ant-select-selection-search:hover,
.ant-select-disabled .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input,
.ant-select-disabled .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input:hover {
  cursor: @Disabled !important;
  color: rgba(0, 0, 0, 0.55);
}
// .ant-select-single .ant-select-selector,
// .ant-select-single .ant-select-selector:hover,
// .ant-select-single .ant-select-selector .ant-select-selection-search,
// .ant-select-single .ant-select-selector .ant-select-selection-search:hover,
// .ant-select-single .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input,
// .ant-select-single .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input:hover,
// .ant-select-single .ant-select-selector .ant-select-selection-item,
// .ant-select-single .ant-select-selector .ant-select-selection-item:hover {
//   // cursor: pointer;
// }

.ant-select.ant-select-borderless.filterDropDown.eventDropdown.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid transparent !important;
}

.ant-select.ant-select-borderless.filterDropDown.eventDropdown.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid black !important;
  border-radius: 5px !important;
}

