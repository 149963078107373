.health-card{
    background: #FFFFFF;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    border-radius: 10px;
    height: 100%;
    .ant-card-body{
        padding: 0;
        display: flex;
        height: 100%;
    }
}

.deviceHealthDiv {
    width: 100%;
}
.health-status{
    width: 8px;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    float: left;
}

.health-info{
    padding: 18px 18px 18px 26px;
}
.health-card-div{
    margin-bottom: 10px;
    img{
        width: auto;
        height: 18px;
    }
    .health-card-alertImg{
        width: 18px;
        margin-left: 1em;
        cursor: pointer;
    }
}
.health-popUp{
    background: #FFFFFF;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    border-radius: 10px;
    border: 1px solid #EDEDED !important;
    padding: 5px 0px 5px !important;
    .ant-popover-arrow{
        display: none;
    }
    .ant-popover-inner{
        box-shadow: none;
    }
    .ant-popover-inner-content{
        padding: 0px;
        border-radius: 10px;
        max-height: 300px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: #f0f0f0;
        }
        &::-webkit-scrollbar-thumb {
            background: #ccc;
            border-radius: 4px;
            &:hover{
            background: #999; 
            }
        }
        .health-card-overlay{
            border-radius: 0px;
            border: 0px !important;
            padding: 30px 24px !important;
            width: 270px;
            &:last-child{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            &:first-child{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            .health-card-overlayInfo{
                width: 100%;
                .ant-col{
                    margin-right: 10px;
                }
                .ant-col:last-child{
                    margin-right: 0px;
                }
            }
        }
        .health-card-overlay + .health-card-overlay{
            border-top: 1px solid #EDEDED !important;
        }
    }
}
.health-card-description{
    width: 148px;
}
.health-card-occurredAt{
    margin-left: auto;
}

.linkDeviceComponent{
    cursor: pointer;
    &:hover{
        .health-card-deviceName{
            text-decoration: underline;
        }
    }
}

@media screen and (min-width: 992px){
    .health-card-div{
        display: flex;
        align-items: center;
    }
}

.deviceHealthIcon {
    padding-right: 10px;
    height: 26px !important;
}

.deviceSpinner {
    margin-left: 1em !important;
}