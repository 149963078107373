.permissions .ant-modal-close {
  display: none;
}

.permissions .ant-modal {
  width: 300px !important;
  height: 230px !important;
  padding-bottom: 0px !important ;
}

.permissions .ant-modal-content {
  height: 100%;
  border-radius: 10px !important;
  font-family: IBM Plex Sans;
}

.permissions .ant-modal-body {
  padding: 15px !important;
}

.permissions .ant-modal-footer {
  border-top: none;
  padding: 50px !important;
  padding-left: 21px !important;
  text-align: center;
}

.permissionRoleModal {
  padding-top: 40px;
}