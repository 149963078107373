.btnPrimary {
    width: 100%;
    height: 51px;
    background: #1C1C1C !important;
    border: 1px solid #1C1C1C !important;
    color: #FFFFFF;
    /* box-shadow: 0px 94px 138px rgb(0 0 0 / 20%), 0px 47.0459px 69.0674px rgb(0 0 0 / 15%), 0px 28.3382px 41.603px rgb(0 0 0 / 13%), 0px 18.1606px 26.6614px rgb(0 0 0 / 11%), 0px 11.7703px 17.2797px rgb(0 0 0 / 10%), 0px 7.40924px 10.8774px rgb(0 0 0 / 9%), 0px 4.25707px 6.24974px rgb(0 0 0 / 7%), 0px 1.87365px 2.75067px rgb(0 0 0 / 5%); */
    border-radius: 50px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    text-align: center;
}

.btnPrimary:hover {
    background: #4D4D4D !important;
    /* box-shadow: 0px 94px 138px rgb(0 0 0 / 20%), 0px 47.0459px 69.0674px rgb(0 0 0 / 15%), 0px 28.3382px 41.603px rgb(0 0 0 / 13%), 0px 18.1606px 26.6614px rgb(0 0 0 / 11%), 0px 11.7703px 17.2797px rgb(0 0 0 / 10%), 0px 7.40924px 10.8774px rgb(0 0 0 / 9%), 0px 4.25707px 6.24974px rgb(0 0 0 / 7%), 0px 1.87365px 2.75067px rgb(0 0 0 / 5%) !important; */
    border-radius: 50px;
    border: 1px solid #4D4D4D !important;
}

.dropDownLabel{
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C;
}

.dropDownBorder > div.ant-select-selector {
    height: 54px !important;
    border: 1px solid #d9d9d9 !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 54px !important;
    // border: 1px solid #d9d9d9 !important;
}

.orgLogo{
    font-family: IBM Plex Sans!important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem!important;
    line-height: 20px!important;
    border-radius: 20px!important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none;
}
.ant-form-item .ant-upload.ant-upload-drag{
    background: #FFFFFF;
}

.addOrganisationContainer {
    margin: 2px 0 54px 0;
}

.addOrgErrorContainer{
    margin: 2px 0 34px 0;
}
.addOrgErrorMsgStyle{
    margin-top: 20px;
}

.addOrganisationHeading {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4375rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1C1C1C;
}

.formControl {
    margin-top: 20px;
}

.formControlRow {
    margin-top: 30px;
}
.uploadDragger {
    border-radius: 20px;
    border: 1px solid #EDEDED;
    &:hover {
        border: 1px solid #CBCBCB;
    }
}
.uploadDragger .ant-upload-drag-container .ant-upload-text{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem !important;
    line-height: 20px;
    color: #737373 !important;
}
.ant-upload-hint {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.625rem !important;
    line-height: 20px;
    text-align: center;
    color: #737373 !important;
}
.logoPreview {
    height: 100px;
    width: 100px;
}
.uploadDragger {
    border-style: solid !important;
}
.customHint {
    line-height: 14px;
}
.draggerIcon {
    margin-bottom: 0px !important;
}
.orgErrorMsg {
    color: #EE0000;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 20px;
    padding-left: 4%;
}
.orgListView  .loaderBody{
    margin-top: 10%;
}
.AddOrgButton button {
    font-size: 0.875rem !important;
}
.addOrgCol input{
    font-size: 0.875rem !important;
}
.dropDownBorder .ant-select-selector {
    padding-left: 20px !important;
}

.addOrgDeleteImg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom:10px;
}