.device-health-header {
  display: flex;
  align-items: center;
  padding-top: 8px;
  margin-bottom: 30px;

  .ant-col {
    height: 54px;
    display: flex;
    align-items: center;
  }
}

.health-dropdown-div {
  margin-left: auto;
}

.healthDropdown {
  .ant-select-selector {
    .ant-select-selection-search {
      input {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 20px !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #1C1C1C;
      }
    }

    .ant-select-selection-item {
      font-size: 0.75rem;
    }
  }
}

.healthDropdown {
  min-width: 50px;

  span {
    img {
      width: 4.61px;
      height: 9px;
    }
  }
}

.healthDropdown div span {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  color: #1C1C1C;
}

.healthDropdown {
  .ant-select-item-option-content {
    font-size: 0.8125rem;
    line-height: 35px !important;
    font-style: normal;
  }

  div div .ant-select-dropdown div .rc-virtual-list .rc-virtual-list-holder div .rc-virtual-list-holder-inner div {
    padding-top: 0px !important;
    height: 35px !important;
    min-height: 35px !important;
  }
}

.informationIcon {
  cursor: pointer;
  margin: 6px 15px 0px 4px;
}
.customInfoIcon {
  font-size: 16px;
  margin: 6.5px 15px 0px 4px !important;
}

.showColourCode {
  font-size: 0.75rem;
  word-spacing: 6px;

  .ant-popover-inner-content {
    font-size: 0.75rem;
    word-spacing: 6px;
  }
}

.deviceHealthScreen .ant-popover-inner-content {
  min-width: 100px;
}
@media screen and (max-device-width: 901px) {
  .deviceHealthScreen {
    margin-bottom: 30px;
  }
}

.device-location {
  max-width: 100% !important;
}

.deviceStatusInfo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.deviceStatusInfo img {
  margin-right: 5px;
}

.ant-select.ant-select-borderless.filterDropDown.alertPageDropdown.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid transparent !important;
}

.ant-select.ant-select-borderless.filterDropDown.alertPageDropdown.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid black !important;
  border-radius: 5px !important;
}