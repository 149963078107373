.confirmPasswordLabel {
    margin-bottom: 0px !important;
}
.CreatePassButton {
    margin-bottom: 0px !important;
}
.changePasswordLabel  div label{
    height: 20px !important;
}
.changePasswordLabel div div div input {
    height: 54px !important;
}
.resetPassword {
    margin-bottom: 20px !important;
}
.resetHeading {
    font-size: 1.4375rem;
}
.createPassDes {
    font-size: 0.875rem;
}
.changePasswordLabel label {
    font-size: 0.875rem !important;
}