.customInput{
  height: 54px !important;
  color: #1C1C1C;
  background: #ffffff;
  &:focus {
    border-color: #55C1F6 !important;
  }
}
.labelName label {
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
    height: auto !important;
  }
  .ant-form-item-has-error .ant-input {
    background-image: url('./errorIcon.svg');
    background-repeat: no-repeat;
    background-position: right 20px center;
    border: 1px solid #ee0000 !important;
    color: #ee0000;
    padding-right: 46px;
  }
.pt-15{
    padding-top: 15px;
}
.pb-15 {
    padding-bottom: 15px;
}

.loginLabelName label{
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C !important;
    padding-top: 10.295%;
    height: auto !important;
    // padding-bottom: 1%;
  }
.loginError input {
 border: 1px solid #EE0000;
}
.mb-0 {
  margin-bottom: 0px;
}


  .ant-form-item-control {
      .ant-form-item-control-input {
          .ant-form-item-control-input-content {
              .ant-input.ant-input-disabled {
                 color: rgba(0, 0, 0, 0.55);
              }
          }
      }
  }

  .customInput-container{
    display: flex;
    align-items: center;
    .copy-icon {
      display: flex;
      cursor: pointer;
      align-items: center;
      padding: 5px;
      margin-left: 5px;
      span {
          height: 14px;
          width: 14px;
      
          svg {
            height: 14px;
            width: 14px;
          }
        }
      &:hover {
        box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.0152067), 0px 5px 6.3px rgba(0, 0, 0, 0.02), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
        border-radius: 20px;
        background: #FFFFFF;
      }
    }
  }