.itemCenter {
  display: flex !important;
  align-items: center !important;
}
.organisationHed {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4375rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1C1C1C;
  padding-left: 16px;
  padding-right: 15px;
}
.filter-wrapper {
  display: flex !important;
  justify-content: flex-end;
  @media (max-width: 992px) {
    justify-content: flex-start;
  }
}
.regionsDropdown .ant-select-selector {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.regionsDropdown .ant-select-selection-item {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #1C1C1C;
}
.addBtnIcon {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 20px !important;
  display: flex !important;
  align-items: center;
  text-align: right;
  color: #EE0000;
}
.addBtnIcon > svg {
  width: 11px;
  height: 11px;
  color: #EE0000;
}
.addOrgBtn > span + span {
  margin-left: 3px !important;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #1C1C1C !important;
  padding: 15px 0px !important;
}
.orgAddBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;

}
.orgSearchBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 35px;
  input {
    font-size: 0.875rem;
  }
}
.serOrgBtn {
  color: #1C1C1C !important;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  color: black;
}

.CountrieswidthFix > div > span {
  padding-top: 5px;
  font-size: 0.75rem;
}

.CountrieswidthFix {
  min-width: 120px;
  width: 120px !important;
  .ant-select-selector {
    padding-left: 0px !important;
  }
  @media screen and (max-width: 992px) {
    min-width: 100px;
    width: 100px !important;
    .ant-select-selector {
      padding-right: 0px !important;
    }
  }
}

.regionsWidthFix {
  min-width: 103px;
}
.regionsWidthFix > div > span {
  padding-top: 5px;
}
.addOrgIcon {
  width: 6px;
  height: 8px;
  margin-right: 3px;
  margin-bottom: 2px;
}
.addOrgBtn {
  border-radius: 50px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0px 15px;
  height: 24px;
  cursor: pointer;
  border: 1px solid #1c1c1c;
  background: #1c1c1c;
  color: #ffffff;
  &:hover {
    background: #4D4D4D;
    border-radius: 50px;
  }
}

.orgSearchBox {
  &:focus-visible {
    border: 1px solid #000 !important;
  }
  @media (max-width: 800px) {
    margin: 0px;
  }
}
.orgSearchBox input{
  @media (max-width: 800px) {
  width: 60px !important;
  padding-left: 5px;
  padding-right: 5px;
  }
}
.OrganisationFilter {
  padding-left: 2% !important;
  margin-left: auto !important;
}

@media (max-width: 1200px) {
  .OrganisationFilter .userFilter {
    margin-left: auto;
    display: flex;
    justify-content: flex-start !important;
  }
}

.orgListTableHeader {
 margin-top: 15px;
  padding-left: 1.5vw;
}

.orgTableHeaderSearch {
  width: 130px !important;
  &:hover {
    border: 1px solid #CBCBCB;
  }
  &:focus {
    border: 1px solid #55C1F6 !important;
  }
}

.bibFilterPos .ant-select-selection-search {
  left: 0px !important;
}

.itemCenter .smallBlack {
  padding-left: 10px;
  display: flex;
  align-items: center;
}