@Disabled:url(../../../../../assets/images/disabled.svg),auto;
.myInsights-list {
    table {
        text-align: center;
        tr {
            td {
                min-width: 200px;
                max-width: 250px;
            }
            td:first-child {
                border-radius: 10px 0 0px 10px;
              }
            td:last-child{
                border-radius: 0px 10px 10px 0px;
            }

        }
        .ant-table-thead {
           
            th.ant-table-cell{
                color: #4d4d4d !important;
            }
            th.ant-table-column-has-sorters {
                .ant-table-column-sorter-full {
                    display: initial;
                }

                .ant-table-filter-column {
                    .ant-table-column-sorter-full {
                        display: initial;
                    }
                }

                .ant-dropdown-trigger {
                    display: flex;
                    align-items: center;
                }

                &:hover {
                    .ant-table-filter-column {
                        .ant-table-column-sorter-full {
                            display: block !important;
                            height: auto;
                        }
                    }

                    .ant-dropdown-trigger {
                        display: flex !important;
                        align-items: center;
                        margin: -4px -8px -4px 4px;
                    }
                }
            }
        }
    }
}
.myInsights-icon{
    display: inherit !important;
    font-size: 18px;
    color: #4d4d4d;
    width: 30px;
    cursor: pointer;
    &:hover{
        color: #A0A0A0;
    }
}
.myInsights-rename{
    font-size: 14px !important;
    text-align: center;
    border: 1px solid #4d4d4d;
    border-radius: 5px;
    &:hover{
        border: 1px solid #A0A0A0;
    }
}
.myInsights-schedule{
    display: flex;
    button{
        margin-right: 15px;
    }
}
.myInsights-scheduleBtn{
    background: transparent !important;
    border: 1px solid #4d4d4d;
    color: #4d4d4d;

    &:hover {
        color: #A0A0A0;
        border: 1px solid #A0A0A0;
    }

    &:disabled {
        background-color: #f5f5f5 !important;
        border-color: #ededed;
        color: rgba(0, 0, 0, 0.55);
        cursor: @Disabled;
        box-shadow: none;
    }
}
.myInsights-delete{
    cursor: pointer;
    padding: 0px 5px;
}

.ScheduleToggleSwitch {
    border: 1px solid #CBCBCB !important;
    box-sizing: border-box !important;
    border-radius: 50px !important;
    height: 14px !important;
    min-width: 28px !important;
    width: 28px !important;
    text-align: start;
    margin-left: auto !important;
    margin-bottom:10px ;

    .ant-switch-handle {
        width: 10px !important;
        height: 10px !important;
        top: 1px;
    }
}

.scheduleToggleOff {
    background-color: #fff !important;

    .ant-switch-handle::before {
        background: #737373 !important;
    }
}

.scheduleToggleCheck {
    background: #13A922 !important;
}

.schedule_dropDown {
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C;
    margin-bottom: 10px !important;

    label {
        font-family: IBM Plex Sans !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 0.75rem !important;
        line-height: 20px !important;
        color: #1C1C1C !important;
        padding-right: 20px !important;
    }
}

.frequencyDropdown{
    width: 92% !important;
    float: right;
}


.switchScheduleForm {
    margin-bottom: 0px !important;

    .ant-form-item-control-input {
        margin-left: auto !important;
    }
}

.ScheduletimePickerStyl {
    border-radius: 10px;
    height: 53px;
    width: 100%;
    margin: 0;
    color: #1C1C1C;
    font-size: 0.875rem;
    font-variant: tabular-nums;
    line-height: 20px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    padding: 0px 4px !important;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: #fff;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    transition: border 0.3s, box-shadow 0.3s;
    .ant-picker-input > input{
        text-align: start;
        padding: 0 11px !important;
    }
    .ant-picker-suffix {
            display: none;
          }
  }
  .tooltipWrapper{
    margin-left: auto;
  }
  .ScheduleDeleteIcon{
    width: 17px;
    height: 17px;
    margin-left: auto;
    cursor: pointer;
}
.nameLink{
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}

.scheduleDropdown .ant-select-selector {
    height: 100px;
    width: 80%;
    float: right;
    background: #ffffff;
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: justify;
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: normal;
    color: #1c1c1c;
    caret-color: red;
    display: flex;
    align-items: center;
    padding-top: 0px !important;
    cursor: pointer;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 4px;
        &:hover{
        background: #999; 
        }
    }
    
}

.optionMenu .ant-form-item {
    margin: 0 8px 4px;

}

.myInsightsDropdown {
    margin-bottom: 10px !important;
    display: flex;
    align-items: center;
    label {
        font-family: IBM Plex Sans !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 0.75rem !important;
        line-height: 20px !important;
        color: #1C1C1C !important;
        padding-right: 20px !important;
    }
    .ant-form-item-control{
        width: 76% !important;
    }
}

.addFormButton {
    border: none !important;
    &:focus{
        border: 1px solid black !important;
    }
}

.addEmail {
    width:60px
}
.addEmailBtn{
    display: flex;
}
.menuIconStyle{
    font-size: 20px !important;
    color: #4d4d4d !important;
    font-weight: 300 !important;
}
.menuDropdown{
   top: 0px !important;
   right: 24px;
}
.hide-tableScroll{
overflow: hidden !important;
}

.runNowDisabled {
    cursor: @Disabled !important;
}
.myinsighttableColNameStyle{
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   display: block;
}

.exportDropdown {
    width: 82% !important;
    float: right;
}

.more-actions-icon {
    font-size: 14px;
    cursor: pointer;
    color: black;
}