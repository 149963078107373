.insightsDropdown {
  .ant-select-selector {
    .ant-select-selection-search{
      input{
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 20px !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #1C1C1C;
        margin-top : 5px !important;
      }
    }
    .ant-select-selection-item {
      font-size: 0.875rem;
    }
  }
}
.insightsDropdown {
  min-width: 80px;
  span {
    img {
      width: 5.61px;
      height: 9px;
    }
  }
}

.custom-multiselect .dropdown-container {
  border: none !important;
  background: none !important;
  padding-top: 0px !important;
  align-items: center !important;
  display: flex !important;

  &:hover {
    border: none !important;
  }

  &:focus {
    border: none !important;
  }
}

.custom-multiselect1 .dropdown-heading .dropdown-heading-value {
  padding-right: 12px !important;
  text-align: end !important;
}

.graph-card {
  margin-bottom: 60px;
  cursor: pointer;
}
.graphNumber {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  color: #737373;
  margin-left: auto;
  padding-left: 5px;
  b {
    padding-left: 10px;
    font-weight: 500;
    font-size: 1rem;
    color: #1C1C1C;
  }
}
.custom-multiselect1 .dropdown-heading .dropdown-heading-value span {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  color: #1C1C1C;
}
.custom-multiselect1 .dropdown-container {
  position: relative;
  .dropdown-content {
    min-width: 200px;
    right: 0px;
    .item-renderer span{
      font-size: 0.75rem !important;
    }
    .panel-content .select-panel .options{
      position: relative;
    }
  }
}
.insightsDropdown div span {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  color: #1C1C1C;
}
.insightsHeader {
  padding-left: 1.9%;
  width: 100%;
  padding-top: 10px;
}

@media only screen and (max-width: 1159px) {
.addReportCard {
  margin-left: 6.2% !important;
  margin-right: 3.37% !important;
}
}

.graphSubHead {
  font-size: 0.625rem !important;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  font-family: IBM Plex Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  color: #4D4D4D;
  button {
    font-size: 0.625rem !important;
  }
}
.insightGraph div div svg g g:nth-child(2) g text {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.625rem !important;
  line-height: 20px;
  color: #1C1C1C;
}
.insightGraph div div svg g g:nth-child(3) g text {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem !important;
  line-height: 25px;
  color: #000000;
}
.dateHeader{
  font-size: 14px !important;
}
.insight-heading{
  padding-right: 15px;
}

.insight-heading-center{
  display: flex;
  align-items: center;
  
}

.insight-filter{
  margin-left: auto;
  .ant-row{
    justify-content: end;
    .ant-col{
      display: flex;
      align-items: center;
    }
  }
}
.toggle-insight{
  display: flex;
  background: #EDEDED;
  border: 1px solid #EDEDED;
  border-radius: 5px;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;
  .toggleIcon{
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .active-icon{
      //set initially inactive
      filter: opacity(0.3);
    }
  }
  .display-left{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-right: 1px solid #EDEDED;
  }
  .display-right{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
  .activeToggle{
    background: white;
    cursor: auto;
    .inactive-icon{
      filter: invert(1);
    }
    .active-icon{
      filter: none;
    }
  }
}
.groupBy-checkbox{
  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border: 1px solid #737373;
        border-radius: initial;
        width: 12px;
        height: 12px;
      }
    }
  }      
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
  }
}
.insightsDropdown {
  .ant-select-dropdown {
    top: 43px !important;
  }
  .ant-select-item-option-content {
    font-size: 0.8125rem;
    line-height: 35px !important;
    font-style: normal;
  }
  div div .ant-select-dropdown div .rc-virtual-list .rc-virtual-list-holder div .rc-virtual-list-holder-inner div {
    padding-top: 0px !important;
    height: 35px !important;
    min-height: 35px !important;
  }
}
.export-report {
  margin-left: 5px;
  margin-right: 5px;
  .export-icon {
    margin-right: 6px;
  }
}
@media screen and (max-device-width: 1024px) {
  .export-icon {
    margin-right: 4.65px;
  }
}

.editMyinsight-DropDownHeight{
  height: 32px !important;
  display: flex !important;
  align-items: center;
}
.editMyinsight-multiSelectHeight{
  height: 32px !important;
  max-width: 100% !important;
}

.insightLineGraph {
  height: 15px !important;
  padding: 5px 10px 5px 10px !important;
  display: flex !important;
  font-family: IBM Plex Sans !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: #4D4D4D !important;
  justify-content: center !important;
  align-items: center !important;
}

.graphDownOutlined{
  font-size: 6px !important;
}

.graphSubHeadSpan{
  margin-inline-start: 10px;
}

.InsightGrapghTooltip {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
}

// .InsightGrapghTooltip{
//   color: point.serieColor, 
//   marginRight: 10 }}
// }

.InsightTooltipXDiv{
  margin-right: 10px !important;
}

.InsightTooltipYDiv {
  margin-left: auto !important;
}
.graphHeaderColor{
  color: #737373 !important;
}

.insightHeaderDiv{
  margin-bottom: 30px ;
}

.insightReportView {
  font-family: IBM Plex Sans;
  font-style:normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 20px !important;
  display: flex;
  align-items: center;
  color: #1C1C1C;
}

.peopleInInsight {
  margin-right: 10px;
}

.progressImg {
  height: 30px;
  width: 35px;
  padding-left: 2px;
  transform: rotate(0deg);
	transition: transform 0.7s linear;
}

.export-report .loaderButton {
   top: 0% !important;
   margin-bottom: 8px !important;  
   padding-left: 5px !important;
}

.exportText {
  padding-right: 5px;
}
.insightPaddingdiv {
  line-height: 30px !important;
}
.myInightNameWrapper span{
  line-height: 30px;
}

.insightLocation div:first-of-type {
  max-width: 100% !important;
}

.MessageInisght {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exportTitle {
  font-size: 1rem;
  color: #1c1c1c;
  font-weight: 500;
  line-height: 20px;
}

.exportRadio {
  font-size: 1rem;
  color: #1c1c1c;
  font-weight: 400;
  line-height: 20px;
}

.exportRadioWrapper {
  margin-bottom: 10px !important;
}

.insightSave {
  display: none !important;
}
.filterBtn{
  margin-left: 10px;
}