.eventLogsHeader {
  justify-content: space-between;
}
.duration-dropDown {
  margin: "auto";
}

.customHeader {
  align-items: center;
  padding-left: 4px;
  .updateTimeFrameBtn {
    padding: 0;
    background: #1c1c1c;
    box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.11),
      0px 22.522px 40.0391px rgba(0, 0, 0, 0.0836366),
      0px 13.5662px 24.1177px rgba(0, 0, 0, 0.0716748),
      0px 8.69392px 15.4559px rgba(0, 0, 0, 0.0627984),
      0px 5.6347px 10.0172px rgba(0, 0, 0, 0.055),
      0px 3.54698px 6.30574px rgba(0, 0, 0, 0.0472016),
      0px 2.03796px 3.62304px rgba(0, 0, 0, 0.0383252),
      0px 0.896959px 1.59459px rgba(0, 0, 0, 0.0263634);
    border-radius: 50px;
    max-width: 150px;
    height: 30px;
  }
  input {
    border-radius: 10px!important;
    height: 43px!important;
}
}
.updateTimeFrameBtn {
  width: 150px !important;
  height: 30px !important;
  background: #1c1c1c !important;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.11),
    0px 22.522px 40.0391px rgba(0, 0, 0, 0.0836366),
    0px 13.5662px 24.1177px rgba(0, 0, 0, 0.0716748),
    0px 8.69392px 15.4559px rgba(0, 0, 0, 0.0627984),
    0px 5.6347px 10.0172px rgba(0, 0, 0, 0.055),
    0px 3.54698px 6.30574px rgba(0, 0, 0, 0.0472016),
    0px 2.03796px 3.62304px rgba(0, 0, 0, 0.0383252),
    0px 0.896959px 1.59459px rgba(0, 0, 0, 0.0263634);
  border-radius: 50px;
  border-color: transparent !important;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 	0.75rem;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #ffffff;

  &:focus-visible {
    border: 2px solid white !important;
  }
}

.hrBorder {
  height: 1px;
  border-width: 0;
  color: #ededed;
  background-color: #ededed;
  margin-left: 1.5vw;
  margin-right: 6px;
}
.eventHeaderRow {
  padding-left: 1.9vw;
  padding-right: 6px;
}
.marCustomHeader {
  padding-left: 2.5vw;
  padding-right: 6px;
}
.updateTimeFrameBtn > span {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 20px;
}
.traceLogData li {
  border-left: none !important;
}

.more-prev-btn {
  background: #EDEDED !important;
  border-radius: 50px !important;
  border:none !important;
  font-family: IBM Plex Sans !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 	0.75rem !important;
  line-height: 20px !important;
  align-items: center !important;
  text-align: center !important;
  color: #4D4D4D !important;
  height: 30px !important;
  width: 150px !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.more-prev-btn-div {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  justify-content: space-evenly !important;
}

.ant-table-footer {
  border: none !important;
}
.eventDownBox:hover  button {
  color: #EE0000;
}
.spiner {
  margin-top: 30px;
}
.pl-table {
  padding-left: 1.9%;
}
.rowDesIcon {
  color: #1c1c1c;
}
.logHeader-2{
  display: flex;
  align-items: center;
}
.eventSearchBar {
  border: 1px solid #55C1F6 !important;
}
.EventLogTitle {
  display: flex !important;
  align-items: center;
  font-size: 1.4375rem;
  font-weight: 500;
  line-height: 30px;
  padding-bottom: 5px;
  flex-wrap: wrap;
  .EventLogTitle-heading{
    white-space: nowrap;
    margin-right: 20px;
  }
}

.ant-btn.ant-btn-link.ant-btn-circle.ant-btn-icon-only.serOrgBtn.eventSearIcon.eventSearchTooltip:focus {
  border: 2px solid black !important;
  border-radius: 5px !important;
}
.eventLog-subHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}