.detailScrollY > div.ant-tabs-content-holder {
    height: 70vh;
    overflow-y: scroll;
    padding-bottom: 30px;
    overflow-x: hidden;
}

.orgDetailContainer {
    padding: 0 0 0 20px;
    margin-bottom: 25px;
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 20px;
}

.orgTabContainer > div.ant-tabs-nav {
    padding: 0 20px;
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 20px;
}
.orgTabContainer .ant-tabs-tab {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #737373 !important;
    padding: 8px 0;
}
.orgTabContainer .ant-tabs-tab div {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #737373 !important;
}
.orgTabContainer .ant-tabs-tab-active {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 20px;
    display: flex !important;
    align-items: center;
    color: #1C1C1C !important;
    padding: 8px 0;
}
.orgTabContainer .ant-tabs-tab-active > div {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 20px;
    display: flex !important;
    align-items: center;
    color: #1C1C1C !important;
    text-shadow: none !important;
}
.orgTabContainer .ant-tabs-tab-btn {
    color: #737373;
    &:active {
        color: #737373 !important;
    }
}
.ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 60px;
}
.orgTabContainer .ant-tabs-nav::before {
    border-bottom: none !important;
}
.linkClientPortal {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8125rem;
    line-height: 20px;
    color: #1C1C1C;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    padding-right: 6px;
}
.pr-11 {
 padding-right: 11px;
}
.orgTabContainer .ant-tabs-ink-bar {
    background: #ee0000;
}
.paddLeft .ant-tabs-nav-wrap {
    padding-left: 1.5vw;
}
.linkPortalImg {
    margin-bottom: 2px;
    margin-right: 11px;
    width: 11.79px;
    height: 12px;
}
.loaderSkelton {
    min-height: 50vh;
}
.orgDetailsTab {
    .loaderBody {
        top: 0%;
        margin-top: 5%;
    }
}
.linkBtnWrapper {
    display: flex !important;
    justify-content: flex-end;
    .btnCommon {
        width: 200px !important;
    }
}