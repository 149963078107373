
@Disabled:url(../../../../../assets/images/disabled.svg),auto;
// .primary {
//   width: 100%;
//   height: 51px;
//   background: #1C1C1C !important;
//   border: 1px solid #1C1C1C !important;
//   color: #FFFFFF;
//   box-shadow: 0px 94px 138px rgba(0, 0, 0, 0.2),
//     0px 47.0459px 69.0674px rgba(0, 0, 0, 0.152067),
//     0px 28.3382px 41.603px rgba(0, 0, 0, 0.130318),
//     0px 18.1606px 26.6614px rgba(0, 0, 0, 0.114179),
//     0px 11.7703px 17.2797px rgba(0, 0, 0, 0.1),
//     0px 7.40924px 10.8774px rgba(0, 0, 0, 0.0858211),
//     0px 4.25707px 6.24974px rgba(0, 0, 0, 0.0696822),
//     0px 1.87365px 2.75067px rgba(0, 0, 0, 0.0479335);
//   border-radius: 50px;
//   cursor: pointer;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
//   align-items: center;
//   text-align: center;
//   outline: none;
//   &:hover {
//     background: #4D4D4D !important;
//     box-shadow: 0px 94px 138px rgba(0, 0, 0, 0.2), 0px 47.0459px 69.0674px rgba(0, 0, 0, 0.152067), 0px 28.3382px 41.603px rgba(0, 0, 0, 0.130318), 0px 18.1606px 26.6614px rgba(0, 0, 0, 0.114179), 0px 11.7703px 17.2797px rgba(0, 0, 0, 0.1), 0px 7.40924px 10.8774px rgba(0, 0, 0, 0.0858211), 0px 4.25707px 6.24974px rgba(0, 0, 0, 0.0696822), 0px 1.87365px 2.75067px rgba(0, 0, 0, 0.0479335) !important;
//     border-radius: 50px;
//     border: 1px solid #4D4D4D !important;
//   }
//   &:active {
//     background: #737373 !important;
//     box-shadow: 0px 51px 80px rgba(0, 0, 0, 0.14), 0px 21.3066px 33.4221px rgba(0, 0, 0, 0.10064), 0px 11.3915px 17.869px rgba(0, 0, 0, 0.083455), 0px 6.38599px 10.0172px rgba(0, 0, 0, 0.07), 0px 3.39155px 5.32008px rgba(0, 0, 0, 0.056545), 0px 1.4113px 2.21381px rgba(0, 0, 0, 0.0393604) !important;
//     border-radius: 50px;
//     border: 1px solid #737373 !important;
//   }
//   &:disabled{
//     background: #EDEDED !important;
//     border-radius: 50px;
//     border: 1px solid #EDEDED !important;
//     color: #745d5d !important;
//     cursor: url(../../../../../assets/images/disabled.svg),auto;
//     box-shadow: none;
//   }
// }

// .secondary {
//   width: 100%;
//   height: 51px;
//   border: 1px solid #1c1c1c;
//   background: transparent;
//   box-sizing: border-box;
//   border-radius: 60px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
//   text-align: center;
//   cursor: pointer;
//   &:hover {
//     border: 1px solid #4d4d4d;
//   }
//   &:active {
//     border: 1px solid #737373;
//     color:#737373;
//   }
//   &:disabled{
//     border: 1px solid #CBCBCB;
//     cursor: url(../../../../../assets/images/disabled.svg),auto;
//   }
// }

// .medium {
//   width: 100%;
//   height: 40px;
//   background: #1c1c1c;
//   box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.15),
//     0px 41.7776px 33.4221px rgba(0, 0, 0, 0.107828),
//     0px 22.3363px 17.869px rgba(0, 0, 0, 0.0894161),
//     0px 12.5216px 10.0172px rgba(0, 0, 0, 0.075),
//     0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0605839),
//     0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0421718);
//   border: 2px;
//   border-radius: 50px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
//   text-align: center;
//   color: #ffffff;
//   cursor: pointer;
//   &:hover {
//     background: #4c4c4c;
//   }
//   &:active {
//     background: #737373;
//   }
//   &:disabled{
//     background: #EDEDED;
//     color: #4d4d4d;
//     cursor: url(../../../../../assets/images/disabled.svg),auto;
//     box-shadow: none;
//   }
// }
// .smallBlack {
//   width: 100%;
//   height: 30px;
//   background: #1c1c1c;
//   box-shadow: 0px 94px 138px rgba(0, 0, 0, 0.2), 0px 47.0459px 69.0674px rgba(0, 0, 0, 0.152067), 0px 28.3382px 41.603px rgba(0, 0, 0, 0.130318), 0px 18.1606px 26.6614px rgba(0, 0, 0, 0.114179), 0px 11.7703px 17.2797px rgba(0, 0, 0, 0.1), 0px 7.40924px 10.8774px rgba(0, 0, 0, 0.0858211), 0px 4.25707px 6.24974px rgba(0, 0, 0, 0.0696822), 0px 1.87365px 2.75067px rgba(0, 0, 0, 0.0479335);
//   border: 2px;
//   border-radius: 50px;
//   font-family: IBM Plex Sans;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
//   text-align: center;
//   color: #ffffff;
//   cursor: pointer;
//   &:hover {
//     background: #4D4D4D;
//     box-shadow: 0px 94px 138px rgba(0, 0, 0, 0.2), 0px 47.0459px 69.0674px rgba(0, 0, 0, 0.152067), 0px 28.3382px 41.603px rgba(0, 0, 0, 0.130318), 0px 18.1606px 26.6614px rgba(0, 0, 0, 0.114179), 0px 11.7703px 17.2797px rgba(0, 0, 0, 0.1), 0px 7.40924px 10.8774px rgba(0, 0, 0, 0.0858211), 0px 4.25707px 6.24974px rgba(0, 0, 0, 0.0696822), 0px 1.87365px 2.75067px rgba(0, 0, 0, 0.0479335);
//     border-radius: 50px;
//   }
//   &:active {
//     background: #737373;
//     box-shadow: 0px 51px 80px rgba(0, 0, 0, 0.14), 0px 21.3066px 33.4221px rgba(0, 0, 0, 0.10064), 0px 11.3915px 17.869px rgba(0, 0, 0, 0.083455), 0px 6.38599px 10.0172px rgba(0, 0, 0, 0.07), 0px 3.39155px 5.32008px rgba(0, 0, 0, 0.056545), 0px 1.4113px 2.21381px rgba(0, 0, 0, 0.0393604);
//     border-radius: 50px;
//   }
//   &:disabled{
//     background: #EDEDED;
//     color: #4d4d4d;
//     cursor: url(../../../../../assets/images/disabled.svg),auto;
//     box-shadow: none;
//   }
// }

// .smallRed {
//   width: 100%;
//   height: 30px;
//   background: #EE0000;
//   box-shadow: 0px 45px 80px rgba(238, 0, 0, 0.14),
//     0px 22.522px 40.0391px rgba(238, 0, 0, 0.106447),
//     0px 13.5662px 24.1177px rgba(238, 0, 0, 0.0912224),
//     0px 8.69392px 15.4559px rgba(238, 0, 0, 0.0799253),
//     0px 5.6347px 10.0172px rgba(238, 0, 0, 0.07),
//     0px 3.54698px 6.30574px rgba(238, 0, 0, 0.0600747),
//     0px 2.03796px 3.62304px rgba(238, 0, 0, 0.0487776),
//     0px 0.896959px 1.59459px rgba(238, 0, 0, 0.0335534);
//   border: 2px;
//   border-radius: 50px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 20px;
//   text-align: center;
//   color: #ffffff;
//   cursor: pointer;
//   &:hover {
//     background: #ff2424;
//   }
//   &:active {
//     background: #ff5757;
//   }
//   &:disabled{
//     background: #EDEDED;
//     color: #4d4d4d;
//     cursor: url(../../../../../assets/images/disabled.svg),auto;
//     box-shadow: none;
//   }
// }

// .loginButton {
//   margin-top: 10.65%;
// }

.actionButton{
  border-radius: 50px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0px 18px;
  height: 24px;
  cursor: pointer;
  border: 1px solid #1c1c1c;
  background: #1c1c1c;
  color: #ffffff;
  &:hover {
    background: #4D4D4D;
    border-radius: 50px;
  }
  &:disabled{
    background: #EDEDED;
    color: #4d4d4d;
    cursor: @Disabled;
    box-shadow: none;
  }
  &:focus-visible {
    border: 3px solid white !important;
  }
}