.ant-modal {
  max-width: 60% !important;
}

.copy-modal-info {
  height: 16px !important;
  width: 16px !important;
  color: #1c1c1c !important;
  cursor: pointer;
  svg {
    height: 16px !important;
    width: 16px !important;
  }
}

.copy-modal-discard {
  height: 16px !important;
  width: 16px !important;
  cursor: pointer;
  svg {
    height: 16px !important;
    width: 16px !important;
  }
}


.ant-popover-content {
  margin-inline-start: -10em;
  position: absolute;
  z-index: 1000;
}
.device-popup-header {
  padding-top: 10px;
  padding-bottom: 15px;

  .deviceSearchIcon {
    min-height: 45px !important;
    display: flex;
  }

  .device-popup-subHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .reason-for-change {
    input {
      height: 44px !important;
    }
  }
}

.device-popup-heading {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4375rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
  height: 54px;
  margin-right: 15px;
  font-size: 21px;
  text-align: left;

  div {
    margin-right: 20px;
  }
}
@media (max-width: 810px) { // 710 
  .storeDevice {
    white-space: normal; 
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}
@media (max-width: 810px) {
  .discardDevice {
    white-space: normal;
    word-wrap: break-word; 
    overflow-wrap: break-word;
  }
}

.justify-center {
  display: flex;
  justify-content: center;
}

.checkbox-header {
  display: flex;
  justify-content: center;
}

.checkbox {
  cursor: pointer;

  .ant-checkbox-inner {
    border: 1px solid rgba(28, 28, 28, 0.45);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(66, 133, 244);
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: rgba(28, 28, 28, 0.45) !important;
    background-color: #e5e4e2 !important;
  }
  
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #787474;;
  }
}

.device-config-table {
  table {
    thead {
      tr {
        th {
          &:first-child {
            overflow: visible;
          }
        }
      }
    }
    tr {
      td {
        cursor: pointer;
        min-width: 110px;
        &:first-child {
          min-width: 55px;
          max-width: 55px;
          padding: 0px;
          text-align: center;
          overflow: visible;
        }
        &:nth-child(4) {
          min-width: 200px;
        }
        &:nth-last-child(2) {
          min-width: 200px;
        }
    
        &:last-child {
          min-width: 200px;
          padding: 0px;
          text-align: center;
        }
      }
    }

    .ant-table-thead {
      th.ant-table-column-has-sorters {
        .ant-table-column-sorter-full {
          display: initial;
          margin-top: -10px;
        }

        .ant-table-filter-column {
          .ant-table-column-sorter-full {
            display: initial;
            margin-top: -10px;

          }
        }

        .ant-dropdown-trigger {
          display: flex;
          align-items: center;
        }

        &:hover {
          .ant-table-filter-column {
            .ant-table-column-sorter-full {
              display: block !important;
              height: auto;
            }
          }

          .ant-dropdown-trigger {
            display: flex !important;
            align-items: center;
            margin: -4px -8px -4px 4px;
          }
        }
      }
    }
  }

  .loaderBody {
    margin-top: 8%;
  }
}

.copy-modal-table {
  table {
    tr {
      td {
        cursor: pointer;
        min-width: 110px;
        &:last-child {
          min-width: 55px !important;
          padding: 0px;
          text-align: center;
        }
        &:nth-last-child(2) {
          min-width: 55px !important;
          padding: 0px;
          text-align: center;
          
        }
      }
    }

    .ant-table-thead {
      th.ant-table-column-has-sorters {
        .ant-table-column-sorter-full {
          display: initial;
          margin-top: -10px;

        }

        .ant-table-filter-column {
          .ant-table-column-sorter-full {
            display: initial;
            margin-top: -10px;

          }
        }

        .ant-dropdown-trigger {
          display: flex;
          align-items: center;
        }

        &:hover {
          .ant-table-filter-column {
            .ant-table-column-sorter-full {
              display: block !important;
              height: auto;
            }
          }

          .ant-dropdown-trigger {
            display: flex !important;
            align-items: center;
            margin: -4px -8px -4px 4px;
          }
        }
      }
    }
  }

  .loaderBody {
    margin-top: 8%;
  }
}

.device-list-warn-icon{
  display: flex;
  justify-content: center;
}

.warning-tooltip {
  max-width: 1000px !important;

  .product-version {
    text-align: left;
  }

  .warning-info {
    white-space: nowrap;
    display: flex;
  }

  .product-version-container {
    padding-top: 6px;
  }
}

.ant-table-cell-ellipsis {
  max-width: 10vw;  
}

.discard-popup-heading {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
  margin-bottom: 15px;
}

.device-list-container{
  .loaderBody {
    margin-top: 2%;
    .loadingText{
      left: 8% !important;
    }
  }
}

.multiline-text {
  white-space: normal; 
  word-break: break-word;
  padding-left: 10px;
  padding-right: 10px;
}

.color-green{
  color: green !important;
}

.device-info-popover{
  white-space: nowrap;
}

.device-list-info {
  color: #4D4D4D;
  height: 12px !important;
  width: 12px !important;
}

.deviceName-column{
  display: flex;
  align-items: center;
}

.device-list-header-checkbox{
  height: 40px;
  display: flex;
  align-items: center;
  overflow: visible;
}

.pl-0 {
  padding-left: 0px !important;
}

.work-item {
  padding: 8px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 4px;
}

.work-item.selected {
  background-color: #007bff;
  color: white;
}

.sub-header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.app-restart-toggle {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 20px;
  color: #737373;
  flex-wrap: wrap;
}