.uploadFileBox {
  position: relative;
  .uploadPictureInput {
    display: none !important;
  }
  .uploadbtnContent {
    cursor: pointer;
  }
  .uploadedImag {
    .image {
      img {
        border-radius: 50%;
      }
    }
    .deletbutton {
      cursor: pointer;
    }
  }
}
.profilePicModal {
  .ant-modal-content {
    padding: 0;
    .ant-modal-body {
      padding: 0;
      .cropImage {
        background: #1c1c1c;
        padding: 41px 0;
      }
      .zoomButtonCol {
        .dragtext {
          font-family: IBM Plex Sans;
          font-style: normal;
          padding: 12px 0 0 0;
          justify-content: center;
          font-weight: normal;
          font-size: 0.75rem;
          line-height: 14px;
          margin-bottom: 30px;
          color: #737373;
          display: flex;
          align-items: center;
          text-align: center;
        }
        .slider {
          .textZoom{
            text-align: right;
            padding-right: 12px;
          }
          span {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: 500;
            display: inline-block;
            font-size: 0.75rem;
            line-height: 20px;
            color: #1c1c1c;
            width: 185px;
            .ant-slider {
              margin: 0 !important;
              .ant-slider-track{
                background: #737373;
              }
              .ant-slider-step {
                // background: #cbcbcb;
                border-radius: 20px;
              }
              .ant-slider-handle {
                background: #1c1c1c;
                border: none;
                box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.11),
                  0px 22.522px 40.0391px rgba(0, 0, 0, 0.0836366),
                  0px 13.5662px 24.1177px rgba(0, 0, 0, 0.0716748),
                  0px 8.69392px 15.4559px rgba(0, 0, 0, 0.0627984),
                  0px 5.6347px 10.0172px rgba(0, 0, 0, 0.055),
                  0px 3.54698px 6.30574px rgba(0, 0, 0, 0.0472016),
                  0px 2.03796px 3.62304px rgba(0, 0, 0, 0.0383252),
                  0px 0.896959px 1.59459px rgba(0, 0, 0, 0.0263634);
              }
            }
          }
        }
        .ConfirmButton {
          .thirdFinishBtn {
            max-width: 150px !important;
            width: 100%;
            height: 30px !important;
            margin-top: 25px;
          }
        }
      }
    }
    max-width: 681px;
    height: auto;
    width: 100%;
    padding: 36px 5px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 54px 29px rgb(0 0 0 / 2%),
      0px 27.0264px 14.5142px rgb(0 0 0 / 2%),
      0px 16.2794px 8.74265px rgb(0 0 0 / 1%),
      0px 10.4327px 5.60275px rgb(0 0 0 / 1%),
      0px 6.76164px 3.63125px rgb(0 0 0 / 1%),
      0px 4.25637px 2.28583px rgb(0 0 0 / 1%),
      0px 2.44555px 1.31335px rgb(0 0 0 / 1%),
      0px 1.07635px 0.57804px rgb(0 0 0 / 0%);
  }
}
.circleInner {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  z-index: 2;
  background: transparent;
  left: 28%;
}
.gridLine1 {
  width: 1px;
  height: 200px;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  left: 37%;
  top: 19%;
  opacity: 0.2;
}
.gridLine2 {
  width: 1px;
  height: 196px;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  right: 37%;
  top: 19.2%;
  opacity: 0.2;
}
.gridLine3 {
  height: 1px;
  width: 236px;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  left: 29.2%;
  top: 30%;
  opacity: 0.2;
}
.gridLine4 {
  height: 1px;
  width: 239px;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  left: 29%;
  top: 43%;
  opacity: 0.2;
}
@media screen and (min-device-width: 1441px) and (max-device-width: 1920px) {
  .circleInner {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    background: transparent;
    left: 31.3%;
}
.gridLine1 {
    width: 1px;
    height: 200px;
    z-index: 1;
    position: absolute;
    left: 39%;
    top: 19%;
}
.gridLine2 {
    width: 1px;
    height: 196px;
    z-index: 1;
    position: absolute;
    right: 39%;
    top: 19.2%;
}
.gridLine3 {
    height: 1px;
    width: 236px;
    z-index: 1;
    position: absolute;
    left: 32.2%;
    top: 30%;
}
.gridLine4 {
    height: 1px;
    width: 239px;
    z-index: 1;
    position: absolute;
    left: 32%;
    top: 43%;
}
}
@media screen and (min-device-width: 1025px) and (max-device-width: 1439px) {
  .circleInner {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    background: transparent;
    left: 31.5%;
}
.gridLine1 {
    width: 1px;
    height: 200px;
    z-index: 1;
    position: absolute;
    left: 39%;
    top: 19%;
}
.gridLine2 {
    width: 1px;
    height: 196px;
    z-index: 1;
    position: absolute;
    right: 39%;
    top: 19.2%;
}
.gridLine3 {
    height: 1px;
    width: 236px;
    z-index: 1;
    position: absolute;
    left: 32.3%;
    top: 30%;
}
.gridLine4 {
    height: 1px;
    width: 239px;
    z-index: 1;
    position: absolute;
    left: 32.2%;
    top: 43%;
}
}
@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
  .circleInner {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    background: transparent;
    left: 23%;
}
.gridLine1 {
    width: 1px;
    height: 219px;
    z-index: 1;
    position: absolute;
    left: 37%;
    top: 17%;
}
.gridLine2 {
    width: 1px;
    height: 216px;
    z-index: 1;
    position: absolute;
    right: 37%;
    top: 17.4%;
}
.gridLine3 {
    height: 1px;
    width: 236px;
    z-index: 1;
    position: absolute;
    left: 24.8%;
    top: 30%;
}
.gridLine4 {
    height: 1px;
    width: 239px;
    z-index: 1;
    position: absolute;
    left: 24%;
    top: 43%;
}
}
@media screen and (min-device-width: 426px) and (max-device-width: 768px) {
  .circleInner {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    background: transparent;
    left: 31.5%;
}
.gridLine1 {
    width: 1px;
    height: 200px;
    z-index: 1;
    position: absolute;
    left: 39%;
    top: 19%;
}
.gridLine2 {
    width: 1px;
    height: 196px;
    z-index: 1;
    position: absolute;
    right: 39%;
    top: 19.2%;
}
.gridLine3 {
    height: 1px;
    width: 236px;
    z-index: 1;
    position: absolute;
    left: 32.3%;
    top: 30%;
}
.gridLine4 {
    height: 1px;
    width: 239px;
    z-index: 1;
    position: absolute;
    left: 32%;
    top: 43%;
}
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .circleInner {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    background: transparent;
    left: 32%;
}
.gridLine1 {
    width: 1px;
    height: 200px;
    z-index: 1;
    position: absolute;
    left: 40%;
    top: 19%;
}
.gridLine2 {
    width: 1px;
    height: 196px;
    z-index: 1;
    position: absolute;
    right: 39%;
    top: 19.2%;
}
.gridLine3 {
    height: 1px;
    width: 236px;
    z-index: 1;
    position: absolute;
    left: 33.2%;
    top: 30%;
}
.gridLine4 {
    height: 1px;
    width: 239px;
    z-index: 1;
    position: absolute;
    left: 33%;
    top: 43%;
}
}