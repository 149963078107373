.ant-input:focus {
  border-color: @BLUE;
  box-shadow: none;
  font-weight: normal;
}
.ant-input {
  caret-color: red;
  padding-left: 20px;
  padding-right: 20px;
  background-image: none;
  background-repeat: no-repeat;
  background-position: right 20px center;
}
.ant-input:hover{
    cursor: pointer;
    border: 1px solid #CBCBCB;
  }
