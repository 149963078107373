.deviceCompDiv{
    width: 100%;
}

.device-comp-card{
    background: #FFFFFF;
    // box-shadow: 0px -4.9736px 14.5142px rgb(0 0 0 / 2%), 0px 12.2794px 8.74265px rgb(0 0 0 / 1%), 0px 10.4327px 5.60275px rgb(0 0 0 / 1%), 0px 6.76164px 3.63125px rgb(0 0 0 / 1%), 0px 4.25637px 2.28583px rgb(0 0 0 / 1%), 0px 2.44555px 1.31335px rgb(0 0 0 / 1%), 0px 1.07635px 0.57804px rgb(0 0 0 / 0%);
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    display: inline-flex;
    width: 100%;
    border-radius: 10px;
    .ant-card-body{
        padding: 0;
        display: flex;
        width: 100%;
    }
}
.device-comp-status{
    width: 8px;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    float: left;
}
.device-comp-info{
    padding: 15px 26px;
}
.device-comp-alert{
    margin-left: auto;
    img{
        width: 18px;
        height: 18px;
    }
    .device-comp-alertImg{
        margin-left: 5px;
    }
}
.device-comp-popUp{
    background: #FFFFFF;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    border-radius: 10px;
    padding-bottom: 0px !important;
    .ant-popover-arrow{
        display: none;
    }
    .ant-popover-inner-content{
        padding: 0px;
    }
}
.device-comp-overlay{
    border: 1px solid #EDEDED !important;
    padding: 30px 24px !important;
    width: 270px;
    .device-comp-overlayInfo{
        width: 100%;
        .ant-col{
            margin-right: 10px;
        }
        .ant-col:last-child{
            margin-right: 0px;
        }
    }
}
.device-comp-errorInfo{
    width: 148px;
}
.device-comp-occurredAt{
    margin-left: auto;
}

.devicePopover {
    min-width: 300px;
    display: flex;
    word-break: break-word;
    font-weight:500 !important;
    justify-content: space-around;
}