.create-device-header{
    padding: 8px 0px 23px 23px !important;
}
.create-device-dropDown{
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C;
    margin-bottom: 0px !important;
    label {
        font-family: IBM Plex Sans !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 0.75rem !important;
        line-height: 20px !important;
        color: #1C1C1C !important;
    }
}
.add-device-btn{
    // margin-top: 40px;
    // margin-bottom: 30px;
    &:disabled{
        background: #EDEDED !important;
        border-radius: 50px;
        border: 1px solid #EDEDED !important;
        color: #745d5d !important;
        box-shadow: none;
        &:hover {
            box-shadow:none !important;
        }
      }
}
.create-device-separator{
    border-top: 1px solid #EDEDED;
    margin: 30px 0px;
}
.create-device-additionalInfo{
    display: flex;
    align-items: center;
    .create-device-checkbox{
        margin-right: 10px;
    }
}
.create-device-additionalInfoForm{
    margin-top: 15px;
}

.add-device-wrapper {
    padding-top: 40px;
    padding-bottom: 30px;
}