.scrollY {
    max-height: 74vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 70px;
}

.orgListContainer {
    padding: 0;
    margin-top: 20px;
}

.noOrgFound {
    background: #FFFFFF;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    border-radius: 10px;
    text-align: center;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 20px;
    color: #1C1C1C;
}
.pl-vw {
    padding-left: 1.5vw;
}
.pb-50 {
    padding-bottom: 50px;
}
.addOrgCard {
    background: #F5F5F5 !important;
    border: 1px dashed #E6E6E6 !important;
    box-sizing: border-box;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 30px !important;
    transition: all 0.15s ease-out; 
    color: #4d4d4d;
    &:hover {
        background: #EDEDED !important;
        border-color: #CBCBCB !important;
        span {
            color: #1c1c1c;
        }
    }
    &:active {
        transition: all 0.05s ease-out; 
        opacity: 0.8;
    }
}
.addOrgCardInner {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #4D4D4D;
}