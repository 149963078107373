.breadCrumbsStyle {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.6875rem !important;
  line-height: 20px !important;
  display: flex;
  align-items: center;
  color: #574646 !important;
  padding-bottom: 1px !important;
}
.breadCrumbsStyle .ant-breadcrumb-link {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.6875rem !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  color: #574646 !important;
}
.breadCrumbsStyle .ant-breadcrumb-separator {
  margin: 0px 5px 0px 4px !important;
}
.breadCrumbsStyle .ant-breadcrumb-link a {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.6875rem !important;
  line-height: 20px !important;
  color: #4D4D4D !important;
}
.breadCrumbsStyle span {
  display: flex;
}