.customHeader {
  .updateTimeFrameBtn {
    height: 30px !important;
  }
}

.edittimeframeCol {
  display: flex;
  align-items: center;
  .edittimeframe {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    color: #ee0000;
    text-decoration: underline;
    line-height: 20px;
    margin: 10px !important;

  }
}

.customRange-hrBorder {
  height: 1px;
  border-width: 0;
  color: #ededed;
  background-color: #ededed;
}

.customRange-Text {
  font-size: 0.875rem;
  font-family: IBM Plex Sans;
}

.customRange-timePicker input {
  font-size: 0.875rem !important;
}

.ant-picker-header-view button {
  font-size: 0.875rem !important;
}