.alertPageDropdown {
  min-width: 80px !important;
  .ant-select-dropdown {
    top: 43px !important;
  }
    .ant-select-item-option-content {
      font-size: 0.8125rem;
      line-height: 35px !important;
      font-style: normal;
    }
  }

  .alertPageDropdown {
    .ant-select-selector {
      .ant-select-selection-search{
        input{
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 0.75rem;
          line-height: 20px !important;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: #1C1C1C;
          width:100% !important;
          margin-top : 5px !important;
        }
      }
      .ant-select-selection-item {
        font-size: 0.875rem;
      }
    }
  }