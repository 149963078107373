.headerContainer {
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
.logo {
  line-height: 20px;
}
.logoOne {
  padding-right: 15.2px;
  padding-left: 3px;
}
.juntoLogoSty {
  padding-left: 12px;
  width: 128.06px;
  height: 24px;
}
.closePage{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background: transparent;
  border: none;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 	0.875rem;
  line-height: 20px;
  text-align: center;
  color: #EE0000;
  img{
    margin-left: 8px;
  }
}