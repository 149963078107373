.ant-form-vertical .ant-form-item-label {
    padding-bottom: 5px !important;
  }

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #EE0000;
    font-size: 0.75rem;
    font-family: IBM Plex Sans;
    line-height: 1;
    content: '*';
  }

  label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: "" !important;
  }

.ant-form-item-explain.ant-form-item-explain-error {
    color: #EE0000 !important;
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem !important;
    line-height: 20px !important;
}

.ant-form-item-has-error .ant-input {
    // background-image: url('../../../common/custominput/errorIcon.svg');
    background-repeat: no-repeat;
    background-position: right 20px center;
    border: 1px solid #ee0000 !important;
    color: #ee0000;
  }
