.ant-modal-content .ant-modal-footer {
  border-top: none;
}

.ant-modal-wrap .ant-modal {
  height: auto !important;
  min-height: 30%;
}

.ant-modal-content {
  display: inline-block;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  padding: 50px;
  font-family: "IBM Plex Sans";
  text-align: center;
}

.ant-modal-header {
  border: none;
  padding-top: 0;
}

.ant-modal-body {
  padding-top: 0;
  padding-bottom: 0;
  display: block;
}

.ant-modal-footer {
  padding: 0;
  display: block;
}

.ant-modal {
  width: auto !important;
  max-width: 60%;
}
@media screen and (max-width: 1200px) {
  .ant-modal {
    max-width: 70%;
  }
}
@media screen and (max-width: 900px) {
  .ant-modal {
    max-width: 90%;
  }
}
