.roleArrowButton {
    justify-content: center;
    float: right;
    display: grid !important;
    align-content: center;
    background: #1C1C1C !important;
    box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.11), 0px 22.522px 40.0391px rgba(0, 0, 0, 0.0836366), 0px 13.5662px 24.1177px rgba(0, 0, 0, 0.0716748), 0px 8.69392px 15.4559px rgba(0, 0, 0, 0.0627984), 0px 5.6347px 10.0172px rgba(0, 0, 0, 0.055), 0px 3.54698px 6.30574px rgba(0, 0, 0, 0.0472016), 0px 2.03796px 3.62304px rgba(0, 0, 0, 0.0383252), 0px 0.896959px 1.59459px rgba(0, 0, 0, 0.0263634) !important;
    border-radius: 5px !important;
    border:none !important;
    width: 21px !important;
    height:21px !important;
}

.roleArrowButtonImg {
    width: 0.4em !important;
}

.roleListRow {
    margin-top: 20px;
    padding-top: 34px;
    padding-bottom: 35px;
    padding-right: 48px;
    padding-left: 40px;
    font-family: IBM Plex Sans;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    border-radius: 10px;
    background: #FFFFFF;
    cursor: pointer;
}
.roleImg {
    width: 17px;
    height: 23px;
}
.pb-17 {
    padding-bottom: 17px;
}
.roleListItem {
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: 0px 1.1px 1.4px rgba(209, 166, 166, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.016), 0px 5px 6.3px rgba(0, 0, 0, 0.02), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
    transition: all 0.1s ease-out;
}
.roleListItem:hover {
    border-color:#CBCBCB;
    box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.028), 0px 2.7px 3.3px rgba(0, 0, 0, 0.04), 0px 5px 6.3px rgba(0, 0, 0, 0.05), 0px 8.9px 11.2px rgba(0, 0, 0, 0.06), 0px 16.7px 20.9px rgba(0, 0, 0, 0.072), 0px 40px 50px rgba(0, 0, 0, 0.1);
}
.roleListItem:active {
    transition: all 0.05s ease-out;
    opacity: 0.8;
}
.scrollContentRole{
    padding-bottom: 5%;
}
.pt-2 {
    padding-top: 2%;
}
.rolesBtnWrapper {
    display: flex;
}