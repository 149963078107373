[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left:-9999px;
}
[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label
{
    position: absolute;
    cursor: pointer;
    color: #666;
}
[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(203, 203, 203, 1);
    border-radius: 3px;
    background: #fff;
}
[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: 4px;
    left:4px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
	background-color: #000;
}
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.roleCheckBox label{
    cursor: default !important;
    display: none;
}

.roleCheckbox  .ant-checkbox-inner {
    border-radius: initial;
    border: 2px solid rgba(203, 203, 203, 1);
}

.roleCheckBoxWrapper {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

// .ant-checkbox-checked .ant-checkbox-inner::after {
//     content: '';
//     display: block;
//     border: 2px solid rgba(203, 203, 203, 1) !important;
//     transform: none;
// }

