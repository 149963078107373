@Disabled:url(../../../../../assets/images/disabled.svg),auto;

mark{
  background-color: yellow !important;
}
.eventAntdcustomtable {
  table {
    thead tr th:nth-last-child(3){
      &::before {
        border-right: 1px solid #FAFAFA
      }
    }
    .ant-table-thead {
      th.ant-table-column-has-sorters {
          .ant-table-column-sorter-full {
              display: initial;
              margin-top: -10px;
          }

          .ant-table-filter-column {
              .ant-table-column-sorter-full {
                  display: initial;
                  margin-top: -10px;
              }
          }

          .ant-dropdown-trigger {
              display: flex;
              align-items: center;
          }

          &:hover {
              .ant-table-filter-column {
                  .ant-table-column-sorter-full {
                      display: block !important;
                      height: auto;
                  }
              }

              .ant-dropdown-trigger {
                  display: flex !important;
                  align-items: center;
                  margin: -4px -8px -4px 4px;
              }
          }
      }
  }
    tbody {
      tr {
        td {
          min-width: 122px;
          max-width: 140px;
        }
        td:first-child {
          border-radius: 10px 0 0px 10px;
        }
        td:last-child {
          border-radius: 0px 10px 10px 0px;
          padding:0px 11px 0px 0px;
          min-width: 60px;
        }
      }
    } 
  }
  .loaderBody{
    margin-top: 8%;
  }
}

.timePickerStyl {
  border-radius: 10px;
  height: 40px;
  margin: 0;
  color: #1C1C1C;
  font-size: 0.875rem;
  font-variant: tabular-nums;
  line-height: 20px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  padding: 0px 4px !important;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  transition: border 0.3s, box-shadow 0.3s;
}
.datepicker {
  height: 40px;
  background: #ffffff;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 	0.875rem;
  line-height: 20px;
  color: #1C1C1C;
  padding: 0px 4px !important;
}
.ant-picker-clear{
  font-size: 12px;
}
.ant-picker-time-panel {
  padding-top: 12px !important;
}
.ant-picker-date-panel {
  width: 282px !important;
}
.ant-picker-time-panel-column {
  width: 54px !important;
}
.ant-picker-time-panel-cell-inner {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 31px !important;
  color: #1C1C1C !important;
  height: 30px !important;
  padding: 0 0 0 18px !important;
}
.ant-picker-time-panel {
  .ant-picker-content {
    height: 253px !important;
  }
}
.ant-picker-body {
  padding-top: 3px !important;
  padding-bottom: 2px !important;
  .ant-picker-content {
    thead {
      tr {
        th {
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 0.8125rem;
          line-height: 29px;
          text-align: center;
          color: #1C1C1C;

        }
      }
    }
    tbody {
      tr {
        td {
          padding: 2px 0 !important;
          .ant-picker-cell-inner {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 0.8125rem;
            line-height: 29px;
            text-align: center;
          }
        }
      }
    }
  }
}
.ant-dropdown-menu-without-submenu {
  padding-top: 11px !important;
  .ant-dropdown-menu-item {
    line-height: 30px !important;
    .ant-dropdown-menu-title-content {
      .ant-checkbox-wrapper {
        .ant-checkbox {
          .ant-checkbox-inner {
            border: 1px solid #cbcbcb;
            border-radius: 3px;
            height: 11px;
            width: 11px;
          }
        }
        .ant-checkbox-checked::after {
          border: 0px solid #1c1c1c !important;
          border-radius: 3px;
          height: 11px;
          width: 11px;
          // background: #1c1c1c;
        }
      }
      .ant-checkbox-wrapper + span {
        padding-left: 10px !important;
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.8125rem;
        line-height: 30px;
        color: #1C1C1C;
      }
    }
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}
.ant-dropdown-menu-item-selected {
  color: #1c1c1c;
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item-selected {
  color: #1c1c1c;
  background-color: transparent !important;
}
.ant-picker-ranges {
  .ant-btn {
    border: 0px black;
    box-shadow: none;
    background-color: black;
    width: auto;
    height: auto;
  }
  .ant-picker-now-btn {
    color: black;
  }
  .ant-picker-ok {
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      cursor: @Disabled;
      color: #4D4D4D !important;
      background:#EDEDED !important;
      border-color: black;
      text-shadow: none;
      box-shadow: none;
    }
  }
}
.Rowdescription {
  position: relative;
  padding: 10px 20px;
  font-family: IBM Plex Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 0.688rem;
  line-height: 13px;
  color: #4D4D4D;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  span {
    width: 100%;
  }
  button {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: -35px;
    border: none;
    background: transparent;
    font-size: 1.125rem;
  }
  .RowdescriptionTitle{
    font-size: 	0.6875rem;
    line-height: 15px;
    color: #737373;
    padding-top: 10px;
    padding-right: 50px;
  }
  .RowdescriptionBox{
    font-size: 	0.75rem !important;
    line-height: 20px;
    color: #1C1C1C;
    height: 104px;
    background: linear-gradient(180deg, #EDEDED 53.12%, #EDEDED 100%);
    border-top: 0px !important;
    padding-left: 33px;
    padding-top: 10px;
    overflow-y:auto;

    pre{
      font-family: IBM Plex Sans !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 0.75rem !important;

    }
  }
  .RowdescriptionShadow{
    height: 10px;
    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.18);
  }
  .RowdescriptionButton{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem !important;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .CopyOutputBtn{
    position: absolute !important;
    width: 124px;
    height: 28px;
    right: 20px !important;
    top: 20px !important;
    background: #1C1C1C !important;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.15), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.107828), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0894161), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.075), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0605839), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0421718);
    border-radius: 50px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #FFFFFF !important;
    &:hover {
      background: #4D4D4D !important;
      box-shadow: 0px 94px 138px rgba(0, 0, 0, 0.2), 0px 47.0459px 69.0674px rgba(0, 0, 0, 0.152067), 0px 28.3382px 41.603px rgba(0, 0, 0, 0.130318), 0px 18.1606px 26.6614px rgba(0, 0, 0, 0.114179), 0px 11.7703px 17.2797px rgba(0, 0, 0, 0.1), 0px 7.40924px 10.8774px rgba(0, 0, 0, 0.0858211), 0px 4.25707px 6.24974px rgba(0, 0, 0, 0.0696822), 0px 1.87365px 2.75067px rgba(0, 0, 0, 0.0479335);
    }
  }
  .OpenOutputBtn{
    position: relative !important;
    color: #4D4D4D;
    top:auto !important;
    right: auto !important;
    padding: 0;
    left: 33px;
  }
  .openOutputIcon{
    margin-right: 8px;
    width: 10px;
    height: 10px;
  }
}
.eventLogsHeader {
  .dateDuration {
    font-weight: 500;
    line-height: 20px;
    padding-right: 8px;
  }
  .dateText {
    font-weight: 300;
    line-height: 20px;
    span {
      margin: 0 5px;
    }
    .auditIcon{
      margin: 0 0 0 13px;
      @media screen and (max-width:992px) {
        margin: 0 0 0 5px;
      }
      img{
        vertical-align: baseline;
        width: 11.74px;
        height: 14px;
      }
    }
  }
    .leftrightarrow {
      padding-right: 8px;
      height: 54px;
      display: flex;
      align-items: center;
  
      .ant-btn-sm {
        background: #ffffff;
        vertical-align: middle;
        box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
          0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
          0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
          0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
          0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
          0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
          0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
          0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
        border-radius: 5px;
      }
    }

  .edittimeframeCol{
    display: flex;
    align-items: center;
    .edittimeframe {
      border-radius: 50px;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 0px 18px;
      height: 24px;
      cursor: pointer;
      border: 1px solid #1c1c1c;
      background: #1c1c1c;
      color: #ffffff;
      float:right;
      text-decoration: none;
    }
  }
  @media screen and (max-width: 1450px) {
    .edittimeframeCol{
    width:  -webkit-fill-available;
   }
  }
  .refereshBtn{
    margin-left: -5px;
  }
  .orgSearchBtn {
    margin-right: 0 !important;
    input {
      font-size: 0.875rem !important;
    }
  }
  .rc-virtual-list-holder-inner {
    .ant-select-item {
      text-align: left;
    }
  }
}
.tablesearchbar {
  width: 130px !important;
  height: 44px;
  border-radius: 10px;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.875rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum", "tnum";
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  /* border-radius: 2px; */
  transition: border 0.3s, box-shadow 0.3s;
  .ant-input-group-addon:last-child {
    left: -1px;
    padding: 0;
    border: 0;
    background: white;
    display: none;
  }
}
.tablesearchbar > input.ant-input{
  height: 30px !important;
}
.ant-picker-panel-container {
  .ant-picker-time-panel-column::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }
  .ant-picker-time-panel-column::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  .ant-picker-now {
    border-left: none !important;
    .ant-picker-now-btn {
      color: #ee0000;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8125rem;
      line-height: 30px;
    }
  }
  .ant-picker-now:hover {
    border-left: none !important;
    background: transparent !important;
  }
  .ant-picker-ok {
    border-left: none !important;
    button {
      width: 41.67px;
      background: #1C1C1C;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.15), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.107828), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0894161), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.075), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0605839), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0421718);
      border-radius: 50px;
    }
    button:hover {
      background: #4d4d4d !important;
    }
    span {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 20px;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      padding: 2.5px 0px !important;
    }
  }
  .ant-picker-ok:hover {
    border-left: none !important;
    background: transparent !important;
  }
}
.ant-picker-panel-container {
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      color: #fff !important;
      border-radius: 50px;
      background: #ee0000 !important;
    }
    .ant-picker-cell-inner::before {
      border: none;
    }
  }
  .ant-picker-today-btn {
    color: #ee0000;
  }
  .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: none;
    border-radius: 2px;
    border-radius: 50px;
    content: "";
  }
  .ant-picker-cell .ant-picker-cell-inner {
    min-width: 28px;
    height: 28px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: 1px solid red;
    min-width: 28px;
    height: 28px;
    border-radius: 50px;
    content: "";
  }
  .ant-picker-panel {
    .ant-picker-footer {
      a {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.8125rem;
        line-height: 30px;
        text-align: center;
        color: #EE0000;
      }
    }
  }
}
.ant-tooltip-placement-top {
  display: none !important;
}

.eventDropdown .ant-select-item-option-content {
  font-size: 0.8125rem;
  line-height: 30px !important;
  font-style: normal;
  font-weight: normal !important;
}
.eventDownBox {
  margin: auto 1px auto 0px;
  height: 20px;
  padding-bottom: 24px;
}
.export-logs{
  margin-left: 10px;
}
.eventDownIcon {
  margin-right: 6px;
}
.eventDownText a {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  color: #1C1C1C;
}
.eventSearIcon > span > svg {
  width: 10px;
  height: 10px;
}
.eventLogList {
  padding-left: 1.5vw;
  padding-right: 1px;
}

.ant-picker-input > input {
  text-align: center;
  line-height: 20px !important;
}
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.55) !important;
  cursor: @Disabled !important;
}
.customHeader .ant-picker-suffix {
  display: none;
}
.eventDropdown {
  div {
    div {
      .ant-select-dropdown {
          width: 159px !important;
          padding-bottom: 9px !important;
      }
    }
  }
}
.eventDropdown {
  div {
    div {
      .ant-select-dropdown {
         div {
           .rc-virtual-list {
             .rc-virtual-list-holder {
               div {
                 .rc-virtual-list-holder-inner {
                   div {
                     padding-top: 0px !important;
                     height: 30px !important;
                     min-height: 30px !important;
                   }
                 }
               }
             }
           }
         }
      }
    }
  }
}
.eventDropdown {
  span {
    img {
      width: 5.61px;
      height: 9px;
      margin-left: 1.5px;
    }
  }
}
.warnicons > span > img {
 margin-right: 11px !important;
}
.eventFilterBox {
  padding-left: 24px !important;
  border-left: 2px solid transparent;
  text-align: left;
  &:hover {
    border-left: 2px solid red !important;
    background: #f0f0f0 !important;
    font-weight: normal !important;
  }
}
.eventDropdown {
  min-width: 86px;
  input {
    padding-top: 9px !important;
    font-size: 0.75rem;
  }
}
.eventHeading{
  display: flex !important;
  align-items: center;
  padding-right: 15px;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1206px) {
  .tablesearchbar {
    width: 100px !important;
  }
}

@media screen and (max-device-width: 1024px) {
  .eventDropdown span img {
    margin-left: 3.5px;
  }
  .eventDownIcon {
    margin-right: 4.65px;
  }
  .eventSearchIcon{
    button {
      min-width: 34px !important;
      width: 34px !important;
    }
  }
  .eventSearchIcon {
      input {
        width: 100px !important;
      }
  }
  .eventLogsHeader .dateDuration {
    padding-right: 5px;
    font-size: 1.25rem;
}
  .eventLogsHeader .dateText {
    font-weight: 300;
    font-size: 1.25rem;
  }
  .eventSearchIcon {
    margin-left: 5px;
  }
}

.eventLogHeaderRight {
  margin-top: -6em;
  justify-content: flex-end;
}
.customBtn{
  margin-top: -4em;
}

.eventFilterBox {
  padding-left: 24px !important;
  border-left: 2px solid transparent;
  text-align: left;
  &:hover {
    border-left: 2px solid red !important;
    background: #f0f0f0 !important;
    font-weight: normal !important;
  }
}

.timePickerBox {
  width: 96px;
  margin: 10px;
}
.datepickerBox {
  width: 170px;
  margin: 10px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: none !important;

}
.ant-collapse-header {
  border: none !important;
  width: 50%;
}
.ant-collapse-header {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.688rem;
  line-height: 13px;
  color: #4D4D4D;
  .ant-collapse-arrow{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.688rem;
    line-height: 13px;
    color: #4D4D4D;
  }
  .ant-collapse-content {
    border: none !important;
    background-color: transparent !important;
}
}
.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
  background: white;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 5px !important;
  border: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border: none;
  ul{
    li{
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 0.688rem;
      line-height: 13px;
      color: #4D4D4D !important;
    }
    li:hover {
      background: transparent;
  }
  }
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.688rem;
  line-height: 13px;
  color: #4D4D4D;
  font-feature-settings: 'tnum', "tnum";
  background-color: transparent;
  border: none !important;
  border-bottom: 0;
  border-radius: 10px;
}
.eventDownText a:hover {
  color: #EE0000;
}

.dateTime{
  font-weight: 700 !important;
}

.warnicons > span {
  text-transform: capitalize;
}
.eventSearIcon > span:hover {
  color: #EE0000;
}


.filter-button{
  border-radius: 50px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0px 18px;
  height: 24px;
  margin-left: 5px;
  cursor: pointer;
}
.clearButton{
  background: transparent;
  border: 1px solid #737373;
  color: #737373;
  &:hover {
    color: #4d4d4d;
    border: 1px solid #4d4d4d;
  }
  &:disabled{
    border: 1px solid #A0A0A0;
    color: #A0A0A0;
    cursor: @Disabled;
    box-shadow: none;
  }

  
}
.applyButton{
  border: 1px solid #1c1c1c;
  background: #1c1c1c;
  color: #ffffff;
  &:hover {
    background: #4D4D4D;
    border-radius: 50px;
  }
  &:disabled{
    background: #EDEDED;
    color: #4d4d4d;
    cursor: @Disabled;
    box-shadow: none;
  }
}
  

.menuImage{
  cursor: pointer;
  float: right;
  padding: 11px 14.5px;
}



// Audit css

.auditCustomtable{
  table{
    .ant-table-thead {
      th.ant-table-column-has-sorters {
          .ant-table-column-sorter-full {
              display: initial;
              margin-top: -10px;
          }

          .ant-table-filter-column {
              .ant-table-column-sorter-full {
                  display: initial;
                  margin-top: -10px;
              }
          }

          .ant-dropdown-trigger {
              display: flex;
              align-items: center;
          }

          &:hover {
              .ant-table-filter-column {
                  .ant-table-column-sorter-full {
                      display: block !important;
                      height: auto;
                  }
              }

              .ant-dropdown-trigger {
                  display: flex !important;
                  align-items: center;
                  margin: -4px -8px -4px 4px;
              }
          }
      }
  }
    tbody {
      tr {
        td{
          min-width: 125px;
          max-width: 140px;
        }
        td:first-child {
          padding-left: 20px;
        }
        td:last-child {
          padding-right: 20px;
        }
      }

  }
  }
  .loaderBody {
    margin-top: 8%;
  }
}

.eventLog-AlignCustom {
  margin-left: auto !important;
}

.ant-btn[disabled]{
  cursor: @Disabled !important;
}



.eventLogsHeaderDiv {
  height: 100%;
}

.eventHeaderButton {
  background: rgb(230 230 230 / 40%) !important;
  border-radius: 5px;
  height: 30px;
  width: 25px;
  margin-right: 4px;
}

.eventHeaderButtonImg {
  height: 8px;
  width: 4px;
  vertical-align: initial;
}

.eventHeaderPrevious {
  background: white;
  border-radius: 5px;
  height: 30px !important;
  width: 25px !important;
  margin-right: 4px;
}

.eventHeaderPrevious:focus {
  border: 2px solid black !important;
}

.eventHeaderPreviousImg {
  height: 8px;
  width: 4px;
  vertical-align: initial;
}

.eventHeadPrev {
  background: rgb(230 230 230 / 40%) !important;
  border-radius: 5px;
  height: 30px !important;
  width: 25px !important;
}

.eventHeadPrevImg {
  height: 8px;
  width: 4px;
}

.eventHeadNext {
  background: white;
  border-radius: 5px;
  height: 30px !important;
  width: 25px !important;
  border: 2px solid black !important;
}

.eventHeadNextImg {
  height: 8px;
  width: 4px;
}

.eventSearchTooltip {
  height: 100% !important;
}

.ant-picker.ant-picker-disabled {
  cursor: @Disabled !important;
}