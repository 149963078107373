@Disabled:url(../../../../assets/images/disabled.svg),auto;

.deviceSetting-header {
    padding-top: 10px;
    padding-bottom: 15px;

    .deviceSearchIcon {
        min-height: 45px !important;
        display: flex;
    }
    .deviceSetting-subHeader{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.deviceSetting-heading {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4375rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1C1C1C;
    height: 54px;
    margin-right: 15px;
}

.deviceSetting-actionBtn {
    display: flex;
    align-items: center;
}

.deviceSetting-addDevice {
    background: transparent !important;
    border: 1px solid #4d4d4d;
    color: #4d4d4d;

    &:hover {
        color: #737373;
        border: 1px solid #737373;
    }

    &:disabled {
        border: 1px solid #A0A0A0;
        color: #A0A0A0;
        cursor: @Disabled;
        box-shadow: none;
    }
}

.deviceSetting-list {
    table {
        tr {
            td {
                cursor: pointer;
                min-width: 110px;

                &:first-child {
                    min-width: 200px;
                }

                &:last-child{

                    min-width: 55px;
                    max-width: 55px;
                    padding: 0px;
                    text-align: center;
                }
            }
        }
        .ant-table-thead {
            th.ant-table-column-has-sorters {
                .ant-table-column-sorter-full {
                    display: initial;
                }

                .ant-table-filter-column {
                    .ant-table-column-sorter-full {
                        display: initial;
                    }
                }

                .ant-dropdown-trigger {
                    display: flex;
                    align-items: center;
                }

                &:hover {
                    .ant-table-filter-column {
                        .ant-table-column-sorter-full {
                            display: block !important;
                            height: auto;
                        }
                    }

                    .ant-dropdown-trigger {
                        display: flex !important;
                        align-items: center;
                        margin: -4px -8px -4px 4px;
                    }
                }
            }
        }
    }

    .loaderBody {
        margin-top: 8%;
    }
}
.deleteDeviceIcon{
    cursor: pointer;
    padding: 12.5px 16px;
}

.deviceSetting-page .loaderBody{
    margin-top: 10%;
}

.copy-column-container{
    display: flex;
    justify-content: space-between;
}
.apply-maxWidth{
    text-overflow: ellipsis;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
}
@media screen and (max-device-width: 1024px) {
    .deviceSearchIcon {
        margin-left: 5px;

        button {
            min-width: 34px !important;
            width: 34px !important;
        }

        input {
            width: 100px !important;
        }
    }
}