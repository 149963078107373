.main {
  background: #ffffff;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
    0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
  border-radius: 10px;
  padding: 3em;
  position: absolute;
  margin-top: 2.5%;
  z-index: 10;
  left: -1px;
  width: 100%;
  max-width: 365px;
  padding-left: 0em;
  padding-right: 0em;
}

.subMenu {
  margin-bottom: 2em;
  margin-top: 2em;
  cursor: pointer;
}

.arrowOpen {
  border-radius: 10px;
  border-color: black;
  background: #1c1c1c;
  height: 2em;
  width: 1px;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.11),
    0px 22.522px 40.0391px rgba(0, 0, 0, 0.0836366),
    0px 13.5662px 24.1177px rgba(0, 0, 0, 0.0716748),
    0px 8.69392px 15.4559px rgba(0, 0, 0, 0.0627984),
    0px 5.6347px 10.0172px rgba(0, 0, 0, 0.055),
    0px 3.54698px 6.30574px rgba(0, 0, 0, 0.0472016),
    0px 2.03796px 3.62304px rgba(0, 0, 0, 0.0383252),
    0px 0.896959px 1.59459px rgba(0, 0, 0, 0.0263634);
  border-radius: 5px;
  margin-left: 7em;
}
.subMenuHed {
  display: flex;
  align-items: center;
}
.forwardButton {
  width: 1.5em;
  height: 1.5em;
  background: #737373;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.11),
    0px 22.522px 40.0391px rgba(0, 0, 0, 0.0836366),
    0px 13.5662px 24.1177px rgba(0, 0, 0, 0.0716748),
    0px 8.69392px 15.4559px rgba(0, 0, 0, 0.0627984),
    0px 5.6347px 10.0172px rgba(0, 0, 0, 0.055),
    0px 3.54698px 6.30574px rgba(0, 0, 0, 0.0472016),
    0px 2.03796px 3.62304px rgba(0, 0, 0, 0.0383252),
    0px 0.896959px 1.59459px rgba(0, 0, 0, 0.0263634);
  border-radius: 5px;
  border: #1c1c1c;
  margin-left: 4em;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: #1c1c1c;
  }
}
.arrowImage {
  margin-top: -0.3em;
  padding: 0;
  width: 0.4em;
  margin-left: -0.2em;
}

.portalNav {
  margin-top: 4.58125em;
  width: fit-content;
  cursor: pointer;
}

.cloudlibrary {
  margin-top: 0.6em;
}
.serviceHeading {
font-family: IBM Plex Sans;
font-style: normal;
font-weight: 500;
font-size: 1rem;
line-height: 20px;
display: flex;
align-items: center;
color: #1C1C1C;
padding-left: 2em;
padding-right: 2em;
}
.serviceJuntoIcon{
  width: 128px;
  height: 24px;
}

.portalText{
  margin-left: 0.688em;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1C1C1C;
}
.gotoImg{
  margin-top: 4px;
}
.forwardArrowIcon{
 width: 0.4em;
}
.cloudLibraryCover {
  cursor: pointer;
  margin-bottom: 2.40625em;
}
.subMenuItem:hover .forwardButton{
  background-color: #1c1c1c;
}

.subMenuItem:hover {
  background-color: #F0F0F0;
}

.subMenuItem{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.redirectKey {
  font-size: 16px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mainScroll {
  overflow-y: auto;
  max-height: 36vh;
}

.subMenuTop {
  align-items: baseline;
}

.notAllowedMenu {
  cursor: not-allowed;
  color: #7d7777;
}