.insight-tableDiv{
    padding-left: 1.5vw;
}
.insight-val{
    cursor: pointer;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}
.insight-count{
    padding: 0px 15px;
    cursor: pointer;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}
.insight-total{
    font-weight: 500;
}

.insightBold-col{
    font-weight: 700 !important; 
}

.insight-table{
    .ant-table-header{
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border: 1px solid #ddd;
        border-bottom: 0;
    }
    .ant-table-body{
        overflow: auto !important;
        border: 1px solid #ddd;
        border-top: 0;
        table > tbody {
            .ant-table-row { 
                .ant-table-cell .ant-table-row-expand-icon{
                    border: 1px solid #bbb;
                    border-radius: initial;
                    &:hover{
                        border: 1px solid #737373;
                    }
                    &:focus-visible {
                        border: 1px solid black !important;
                      }
                }
                td{
                    border-bottom: 3px solid #f0f0f0;
                    margin-bottom: 5px;
                    background-color: white;
                    font-family: IBM Plex Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.75rem;
                    border-right: none !important;
                    &:first-child{
                        padding-left: 16px !important;
                    }
                }
                td+td{
                    text-align: center;
                    min-width: 150px;
                }
            }
            tr{
                position: relative;
                background-color: #e5e5e5;
                box-shadow: 0px 54px 29px rgb(0 0 0 / 2%), 0px 27.0264px 14.5142px rgb(0 0 0 / 2%), 0px 16.2794px 8.74265px rgb(0 0 0 / 1%), 0px 10.4327px 5.60275px rgb(0 0 0 / 1%), 0px 6.76164px 3.63125px rgb(0 0 0 / 1%), 0px 4.25637px 2.28583px rgb(0 0 0 / 1%), 0px 2.44555px 1.31335px rgb(0 0 0 / 1%), 0px 1.07635px 0.57804px rgb(0 0 0 / 0%);
                border-radius: 10px;
                td:first-child{
                    border-radius: 10px 0px 0px 10px;
                    min-width: 150px;
                }
                td:last-child {
                    border-radius: 0px 10px 10px 0px;
                }
            }
        }
    }
    .ant-table-thead > tr > th{
        text-align: center !important;
        font-weight: 500;
        font-family: IBM Plex Sans;
        font-style: normal;
        font-size: 0.75rem;
        line-height: 20px;
        color: #4d4d4d;
        border-bottom: 1px solid #ddd !important;
        background-color: #eee !important;
        &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell)::before {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 100% !important;
            background-color: #ddd !important;
            transform: translateY(-50%);
            transition: background-color 0.3s;
            content: '';
        }
        &:nth-child(4){
            font-weight: 500;
        }
        &:last-child {
            font-weight: 700;
        }
    }
    .ant-table-cell-scrollbar {
        box-shadow: 0 1px 0 1px #eee;
    }
    .ant-table-body::-webkit-scrollbar {
        width: 6px;
        height: 8px;
        border-radius: 10px;
    }
    .ant-table-body::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #f0f0f0;
    }
    .ant-table-body::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 4px;
        &:hover{
            background: #999; 
        }
    }
}

.InsightdeviceDeleteIcon{
    width: 23px;
    padding-right: 10px;
    padding-left: 2px;
}
.deviceRowDivStye{
    display: flex;
    justify-content:space-between;
}

.deviceNamestyle{
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: block !important;
}

.subChildHide .ant-table-header thead tr:last-child {
    display: none;
}

.payment-details-table{
    .ant-table-header{
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border: 1px solid #ddd;
        border-bottom: 0;
    }
    .ant-table-body{
        overflow: auto !important;
        border: 1px solid #ddd;
        border-top: 0;
        table > tbody {
            .ant-table-row { 
                .ant-table-cell .ant-table-row-expand-icon{
                    border: 1px solid #bbb;
                    border-radius: initial;
                    &:hover{
                        border: 1px solid #737373;
                    }
                    &:focus-visible {
                        border: 1px solid black !important;
                      }
                }
                td{
                    border-bottom: 3px solid #f0f0f0;
                    margin-bottom: 5px;
                    background-color: white;
                    font-family: IBM Plex Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.75rem;
                    border-right: none !important;
                    &:first-child{
                        overflow: visible !important;
                        min-width: 10vw;
                    }
                }
                td+td{
                    text-align: center;
                    min-width: 10vw;
                }
            }
            tr{
                position: relative;
                background-color: #e5e5e5;
                box-shadow: 0px 54px 29px rgb(0 0 0 / 2%), 0px 27.0264px 14.5142px rgb(0 0 0 / 2%), 0px 16.2794px 8.74265px rgb(0 0 0 / 1%), 0px 10.4327px 5.60275px rgb(0 0 0 / 1%), 0px 6.76164px 3.63125px rgb(0 0 0 / 1%), 0px 4.25637px 2.28583px rgb(0 0 0 / 1%), 0px 2.44555px 1.31335px rgb(0 0 0 / 1%), 0px 1.07635px 0.57804px rgb(0 0 0 / 0%);
                border-radius: 10px;
                td:first-child{
                    text-align: center !important;
                    border-radius: 10px 0px 0px 10px;
                    min-width: 10vw;
                }
                td:last-child {
                    border-radius: 0px 10px 10px 0px;
                }
            }
        }
    }
    .ant-table-thead > tr > th{
        text-align: center !important;
        font-weight: 500;
        font-family: IBM Plex Sans;
        font-style: normal;
        font-size: 0.75rem;
        line-height: 20px;
        color: #4d4d4d;
        border-bottom: 1px solid #ddd !important;
        background-color: #eee !important;
        &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell)::before {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 100% !important;
            background-color: #ddd !important;
            transform: translateY(-50%);
            transition: background-color 0.3s;
            content: '';
        }
        &:nth-child(4){
            font-weight: 500;
        }
        &:last-child {
            font-weight: 700;
        }
    }
    .ant-table-cell-scrollbar {
        box-shadow: 0 1px 0 1px #eee;
    }
    .ant-table-body::-webkit-scrollbar {
        width: 6px;
        height: 8px;
        border-radius: 10px;
    }
    .ant-table-body::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #f0f0f0;
    }
    .ant-table-body::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 4px;
        &:hover{
            background: #999; 
        }
    }
}