.library-icon {
  height: 35px;
  width: 35px;
  border-radius: 50px;
  box-shadow: 0px 76px 60px rgba(0, 0, 0, 0.09),
    0px 31.751px 25.0666px rgba(0, 0, 0, 0.0646969),
    0px 16.9756px 13.4018px rgba(0, 0, 0, 0.0536497),
    0px 9.51638px 7.51293px rgba(0, 0, 0, 0.045),
    0px 5.05408px 3.99006px rgba(0, 0, 0, 0.0363503),
    0px 2.10311px 1.66035px rgba(0, 0, 0, 0.0253031);
}

.img-responsive {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.logo-container {
  overflow: hidden;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  padding: 2px;
  margin-bottom: 27px;
  box-shadow: 0px 76px 60px rgba(0, 0, 0, 0.09),
    0px 31.751px 25.0666px rgba(0, 0, 0, 0.0646969),
    0px 16.9756px 13.4018px rgba(0, 0, 0, 0.0536497),
    0px 9.51638px 7.51293px rgba(0, 0, 0, 0.045),
    0px 5.05408px 3.99006px rgba(0, 0, 0, 0.0363503),
    0px 2.10311px 1.66035px rgba(0, 0, 0, 0.0253031);
}

.organisation-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1C1C1C;
  margin-bottom: 4px;
}
.other-details {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #4D4D4D;
}
.website {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 20px;
  display: table-cell;
  align-items: center;
  color: #EE0000 !important;
  word-break: break-all;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1c1c1c !important;
}

.orgTabContainer .ant-tabs-tab .ant-tabs-tab-btn:focus-visible {
  border: 1px solid grey; 
}


.ant-tabs-tab:hover {
  color: #1c1c1c;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #ee0000;
  pointer-events: none;
}

.summary-container{
  font-family: 'IBM Plex Sans';
  background-color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 15px;
  // padding: 5em 5em 5em 5em;
  padding: 80px 10%;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
    0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
}

.summary-subcontainer {
  margin-bottom: 25px;
}

.secondary-container{
  background-color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 3.5em 2.5em 2.5em 3.6em;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
    0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    @media (max-width: 992px) {
      padding-left: 2em;
      padding-right: 1em;
    }
}
.secondary-container img {
  margin-right: 11px !important;
}
.tertiaryContainer {
  background-color: #FFFFFF;
  // margin-top: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 2.5em;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
    0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
    @media (max-width: 992px) {
      padding-left: 1em;
      padding-right: 1em;
    }
}

.specific-details {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1C1C1C;
}
.specific-details-number {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 20px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  color: #1C1C1C;
  padding-right: 19px;
  margin-top: 3px;
  @media (max-width: 992px) {
    margin-top: 0px;
    padding-right: 7px;
  }
}
.arrow-open {
  margin-top: 1em;
  border-radius: 5px;
  border-color: black;
  background: #1c1c1c;
  height: 21px;
  width: 21px;
  padding-bottom: 2px;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.11),
    0px 22.522px 40.0391px rgba(0, 0, 0, 0.0836366),
    0px 13.5662px 24.1177px rgba(0, 0, 0, 0.0716748),
    0px 8.69392px 15.4559px rgba(0, 0, 0, 0.0627984),
    0px 5.6347px 10.0172px rgba(0, 0, 0, 0.055),
    0px 3.54698px 6.30574px rgba(0, 0, 0, 0.0472016),
    0px 2.03796px 3.62304px rgba(0, 0, 0, 0.0383252),
    0px 0.896959px 1.59459px rgba(0, 0, 0, 0.0263634);
}

.edit-button {
  margin-top: 5.7em;
  width: 100px;
  @media (max-width: 992px) {
    margin-top: 4em;
  }
}
.fs-12 {
  font-size: 0.75rem;
}

.redi-icons {
  margin-right: 11px;
}

// .redi-button {
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }

.redi-button > img {
  margin-right: 0 !important;
}

.mt-36 {
  margin-top: 36px;
}

.mb-8 {
  margin-bottom: 8px;
}

.subscriptionHeading {
  margin-bottom: 22px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1C1C1C;
}
.activeSub {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 20px;
  color: #1C1C1C;
}

.subscription-edit-button {
  margin-top: 55px !important;
  width: 100px;
}
.mb-0{
  margin-bottom: 0px !important;
}
.forwardBtn {
  padding: 0px !important;
  border: 3px transparent;
}

.forwardBtn:focus-visible {
  border: 1px solid grey !important;
  border-radius: 0px !important;
}
.forwardArrowBtn {
  width: 21px;
  height: 21px;
  background: #1C1C1C;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.11), 0px 22.522px 40.0391px rgba(0, 0, 0, 0.0836366), 0px 13.5662px 24.1177px rgba(0, 0, 0, 0.0716748), 0px 8.69392px 15.4559px rgba(0, 0, 0, 0.0627984), 0px 5.6347px 10.0172px rgba(0, 0, 0, 0.055), 0px 3.54698px 6.30574px rgba(0, 0, 0, 0.0472016), 0px 2.03796px 3.62304px rgba(0, 0, 0, 0.0383252), 0px 0.896959px 1.59459px rgba(0, 0, 0, 0.0263634);
  border-radius: 5px;
}
.scrollSummary{
  position: inherit !important;
}
.orgSummaryInfo {
  margin-bottom: 35px;
}

.orgSummaryInfor {
  margin-bottom: 35px;
}

.basicInfoEllipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}