.scrollContent {
  max-height: 100%;
  overflow-y: auto;
  padding-right: 10px;
  // position: absolute;
  width: 100%;
}
.scrollStyle::-webkit-scrollbar {
  width: 5px;
}
  
  /* Track */
.scrollStyle::-webkit-scrollbar-track {
  border-radius: 10px;
}
   
  /* Handle */
.scrollStyle::-webkit-scrollbar-thumb {
  background: #ccc; 
  border-radius: 4px;
}
  
  /* Handle on hover */
.scrollStyle::-webkit-scrollbar-thumb:hover {
  background: #999; 
}


