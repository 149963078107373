.device-info-header{
    padding: 8px 0px 0px 23px !important;
    margin-bottom: 23px;
    align-items: center;
}
.device-info-breadcrumb{
    padding: 8px 0px 0px 23px !important;
}
.device-info-saveBtn{
    margin-left: 15px;
    .smallBlack{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ant-form-item-control-input{
        min-height: auto;
    }
    .ant-row.ant-form-item {
        margin-bottom: 0px !important;
    }
}
.device-info-dropDown{
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 20px !important;
    color: #1C1C1C;
    margin-bottom: 0px !important;
    label {
        font-family: IBM Plex Sans !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 0.75rem !important;
        line-height: 20px !important;
        color: #1C1C1C !important;
    }
}

.deviceDeleteButton {
    margin-left: auto;
    cursor: pointer;
    padding: 13px 23px;
    font-size: 14px;
}

.copy-style{
    position: absolute;
    top: 43px !important;
    right: 24px !important;
    height: 24px !important;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.0152067), 0px 5px 6.3px rgba(0, 0, 0, 0.02), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
        border-radius: 20px;
        background: #FFFFFF;
      }
}
.pr-54{
    padding-right: 54px !important;
}
.cursor-disabled {
    &:hover {
        cursor: url(../../../../../assets/images/disabled.svg),auto !important;
    }
}
.visibilty-hidden{
    visibility: hidden;
}