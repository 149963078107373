.ant-layout-header {
  background-color: transparent;
}
.loginLogoBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.loginLangBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.login-box {
  display: flex;
  align-items: center;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 
    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 
    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 
    0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 
    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 
    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 
    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 
    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
  border-radius: 10px;
}
h1 {
  font-size: 1.4375rem;
}
h4 {
  font-size: 0.875rem;
  margin-top: 15px;
  margin-bottom: 25px;
}
label {
  font-size: 0.75rem;
}
.mt-25 {
  margin-top: 25px !important;
}
.ant-layout {
  background-color: #fafafa !important;
  height: 100% !important;
}
.width-auto {
  width: 100%;
}
.loginLogoHed {
  // width: 8em;
  // height: 1.5em;
  width: 140px;
  height: 18.7px;
}
.dropdownFix {
  width: 10em !important;
  // padding-top: 3.93%;
  // padding-right: 1.95%;
}
.login-header{
  padding-left: 3.27%;
  padding-right: 1.95%;
  margin-top: 0.63%;
}
.scrollAuth {
  height: 100vh;
  max-height: 100vh;
}

.deviceLayout {
  padding-top: 5px !important;
}