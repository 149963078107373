.userFilterHed {
  display: flex !important;
  align-items: center !important;
  padding-left: 20px;
  padding-top: 2%;
  margin-left: auto;

}
.userListHed {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.438rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1C1C1C;
  padding-left: 23px;
}
.userFilter {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-device-width: 991px){
    justify-content: flex-start;
  }
}
.userFilterTab {
  padding: 0px 5px;
}
.filterDropDown .ant-select-selector {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.filterDropDown .ant-select-selection-item {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 20px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #1C1C1C;
}
.filterBoxDropdown {
  padding-top: 11px !important;
  padding-bottom: 14px !important;
}
.filterBox {
  padding-left: 1.5em !important;
  padding-top: 0px !important;
  border-left: 2px solid transparent;
  text-align: left;
  &:hover {
    border-left: 2px solid red !important;
    background: #f0f0f0 !important;
    font-weight: normal !important;
  }
}
.filterBox .ant-select-item-option-selected > div {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.8125rem;
  line-height: 30px;
  color: #1C1C1C;
}
.filterBox .ant-select-item-option-content {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 30px;
  color: #1C1C1C;
}
.addUserBtn {
  border-radius: 50px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0px 18px;
    height: 24px;
    cursor: pointer;
    border: 1px solid #1c1c1c;
    background: #1c1c1c;
    color: #ffffff;
    &:hover {
      background: #4D4D4D;
      border-radius: 50px;
    }
}
.addUserImg {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.searchBtn {
  // padding-right: 17px;
  &:focus-visible {
    border: 1px solid grey !important;
  }

  input{
    width: 100%;
    max-width: 130px;
    font-size: 0.875rem;
    @media screen and (max-device-width: 1024px){
      max-width: 100px;
    }
    @media screen and (max-device-width: 992px){
      max-width: 75px;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
  }
  @media screen and (max-device-width: 992px){
    button {
      min-width: 25px !important;
    }
  }
}
.gutterRow {
  padding-top: 15px;
  padding-bottom: 15px;
}
.cardItem {
  background: #FFFFFF !important;
  box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.016), 0px 5px 6.3px rgba(0, 0, 0, 0.02), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);       
  border-radius: 10px !important;
  cursor: pointer;
  border: 1px solid #fff;
  transition: all 0.1s ease-out; 
}
.cardItem:hover {
  border-color:#CBCBCB;
  box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.028), 0px 2.7px 3.3px rgba(0, 0, 0, 0.04), 0px 5px 6.3px rgba(0, 0, 0, 0.05), 0px 8.9px 11.2px rgba(0, 0, 0, 0.06), 0px 16.7px 20.9px rgba(0, 0, 0, 0.072), 0px 40px 50px rgba(0, 0, 0, 0.1);
}
 .cardItem:active {
  transition: all 0.05s ease-out;
  opacity: 0.8; 
 }

.cardItem .ant-card-body {
  padding: 0px;
}
.cardItem .rowInfo{
  padding: 20px 0px;
}
.userProfilePic {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.blockUserListCont .ant-image {
  margin-bottom: -5.455px;
  opacity: 0.2;
}
.blockUserListCont .imgContaineravt {
  opacity: 0.6;
}
.blockedUserListImg {
  background-image: url(../../../../../../assets/images/blockUserRedIcon.svg);
  height: 12px;
  width: 12px;
  position: absolute;
  top: 24px;
  left: 55px;
}
.inviteUserRowImg{
  background-image: url(../../../../../../assets/images/resendMail.svg);
  height: 12px;
  width: 12px;
  position: absolute;
  background-repeat: no-repeat;
  top: 24px;
  left: 48px;
}
.menuImg {
  padding: 11px 14.5px;
}
.displayCenter{
  display: flex !important;
  align-items: center !important;
  padding-left: 10px;
}
@media screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .blockedUserListImg {
    background-image: url(../../../../../../assets/images/blockUserRedIcon.svg);
    height: 12px;
    width: 12px;
    position: absolute;
    top: 24px;
    left: 34px;
  }
  .inviteUserRowImg{
    background-image: url(../../../../../../assets/images/resendMail.svg);
    height: 12px;
    width: 12px;
    position: absolute;
    background-repeat: no-repeat;
    top: 24px;
    left: 34px;
  }
}
@media screen and (min-device-width: 1025px) and (max-device-width: 1440px) {
  .blockedUserListImg {
    background-image: url(../../../../../../assets/images/blockUserRedIcon.svg);
    height: 12px;
    width: 12px;
    position: absolute;
    top: 24px;
    left: 45px;
  }
}
.userStyl {
  padding-right: 15px;
}
.threeDotIconItem {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.newUserList {
  background: #F5F5F5 !important;
  border: 1px dashed #E6E6E6 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 
    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 
    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 
    0px 10.4327px 5.60275px #00000003, 
    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 
    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 
    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 
    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
  transition: all 0.15s ease-out; 
    &:hover {
      background: #EDEDED !important;
      border: 1px dashed #CBCBCB !important;
    }
    &:active {
      transition: all 0.05s ease-out; 
      opacity: 0.8;
    }
}
.newUserList:hover .newUserListRow span {
  color: #1C1C1C;
}
.newUserListRow,
.newUserListRow span {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 20px;
  display: flex !important;
  align-items: center;
  color: #4D4D4D;
  margin-bottom: 0px;
}
.userNameContentSlicing {
  white-space: nowrap; 
  width: 13vw; 
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
  .userNameContentSlicing {
    white-space: nowrap; 
    width: 11vw; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-device-width: 426px) and (max-device-width: 768px) {
  .userNameContentSlicing {
    white-space: nowrap; 
    width: 14vw; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .userNameContentSlicing {
    white-space: nowrap; 
    width: 26vw; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.userListRoleContentSlicing {
  white-space: nowrap; 
  width: 20vw; 
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-device-width: 426px) and (max-device-width: 768px) {
  .userListRoleContentSlicing {
    white-space: nowrap; 
    width: 19vw; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .userListRoleContentSlicing {
    white-space: nowrap; 
    width: 37vw; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.userDetailContentSlicing {
  white-space: nowrap; 
  width: 20vw; 
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-device-width: 426px) and (max-device-width: 768px) {
  .userDetailContentSlicing {
    white-space: nowrap; 
    width: 19vw; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .userDetailContentSlicing {
    white-space: nowrap; 
    width: 37vw; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.userInfoContentSlicing {
  white-space: nowrap; 
  width: 15vw;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-device-width: 426px) and (max-device-width: 768px) {
  .userInfoContentSlicing {
    white-space: nowrap; 
    width: 9vw; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .userInfoContentSlicing {
    white-space: nowrap; 
    width: 18vw; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.userLocationDropdown {
  min-width: 110px;
  .ant-select-selector {
    padding-left: 0px !important;
  }
  @media screen and (max-width: 992px) {
    min-width: 100px;
    width: 100px !important;
    .ant-select-selector {
      padding-right: 0px !important;
    }
  }
}

.userLocationDropdown > div > span {
  padding-top: 4px;
}
.userRolesDropdown {
  min-width: 89px;
  @media screen and (max-width: 992px) {
    min-width: 89px;
    width: 89px !important;
    .ant-select-selector {
      padding-left: 0px !important;
    }
  }
}
.rc-virtual-list-scrollbar{
  width: 5px !important;
  border-radius: 10px;
}
.rc-virtual-list-scrollbar-thumb{
  background: rgb(204, 204, 204) !important;
}
.userRolesDropdown > div > span {
  padding-top: 4px;
}
.userViewDropdown {
  min-width: 96px;
}
.userViewDropdown > div > span {
  padding-top: 4px;
}
.userLocationDropdown > div > span {
  font-size: 0.875rem;
}
.userRolesDropdown > div > span {
  font-size: 0.875rem;
}
.userViewDropdown > div > span {
  font-size: 0.875rem;
}
.manageUserImg {
  width: 15px;
  height: 13px;
  margin-left: 4px;
  margin-right: 3px;
}
.manageUserWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 4px;
}
.manageUserText {
  font-size: 0.75rem;
  font-weight: 500px;
} 
.manageUserWrapper .smallBlack {
  padding-left: 10px;
  display: flex;
  align-items: center;
}

@media screen and (min-device-width: 1441px) and (max-device-width: 1920px) {
  .inviteUserRowImg{
    background-image: url(../../../../../../assets/images/resendMail.svg);
    height: 12px;
    width: 12px;
    position: absolute;
    background-repeat: no-repeat;
    top: 24px;
    left: 56%;
  }
}

.userSearchButton {
  color: black;
  height: 100%;
}

.searchBtn input {
  &:hover {
    border: 1px solid #CBCBCB;
  }
  &:focus {
    border: 1px solid #55C1F6 !important;
  }
}

.userLocationDropdown.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid transparent !important;
}

.userLocationDropdown.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid black !important;
  border-radius: 5px !important;
}

.userRolesDropdown.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid transparent !important;
}

.userRolesDropdown.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid black !important;
  border-radius: 5px !important;
}

.userViewDropdown.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid transparent !important;
}

.userViewDropdown.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  border: 2px solid black !important;
  border-radius: 5px !important;
}

.ant-btn.ant-btn-link.ant-btn-circle.userSearchButton:focus {
  border: 2px solid black !important;
  border-radius: 5px !important;
}