.alertConfig-Input{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.alertConfig-edit{
    margin-left: auto;
    padding-left: 5px;
    cursor: pointer;
}
.clearedBy-Input{
    .ant-form-item-control{
        .ant-form-item-control-input{
            min-height: 20px !important;
            .ant-form-item-control-input-content{
                .customInput{
                    padding: 3px 5px;
                    height: 20px !important;
                    font-size: 0.75rem;
                    border-radius: 5px !important;
                }
            }
        }
    }
}
.clearedBy-Input.ant-form-item-has-error .ant-input{
    background-position: right 5px center;
    background-size: 14px;
}