#mainHeader{
    position : fixed;
    top : 0;
    width : 100%;
    z-index: 90;
    background : #FAFAFA;
}

#content{
    margin-top : 10em;
    z-index : 0;
}

#sidePanel-area{
    position : fixed;
}

#component-area{
    margin-left : 20em;
    width : 100%;
  }

.toaster-close-btn {
    position: absolute !important;
    top: 15px !important;
    right: 15px !important;
}