.bib-menu-item-row {
  border-left: 3px solid transparent;
  margin-bottom: 25px !important;
  margin-top: 25px !important;
}
.bib-menu-item-row:hover {
  border-left: 3px solid #EE0000;
  background-color: rgba(255,255,255,0.2)!important;
  cursor: pointer;
}
.sideNavLayout{
  height: 100%;
  border-right: 0px !important;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  &:focus-visible {
    border: 3px solid red !important;
  }
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;

    &:hover {
        background: #999;
    }
  }
   
  li {
    padding-left: 0px !important;
    &:focus-visible {
      border: 3px solid brown !important;
    }
  }
}


.sidebarHeader{
  // padding-top: 38px;
  padding-bottom: 14px;
  padding-left: 28px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.shadow-bottom{
  box-shadow: 0px 5px 11px 1px rgb(0 0 0 / 8%);
}
.selectedSidebarWhite{
  background: #F0F0F0 !important;
  border-left: 3px solid #EE0000;
}

.selectedItemBlack{
  background-color: rgba(255,255,255,0.2)!important;
  border-left: 3px solid #EE0000;
}

.selectedSidebarRed{
  background-color: rgba(255,255,255,0.2)!important;
  // background-color: #FAFAFA;
  // opacity: 0.2;
}
.menuBack {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.6875rem;
  line-height: 20px;
  display: flex !important;
  align-items: center;
  color: #1C1C1C;
}
.sidebarRow {
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sideNavSlice {
  text-align: left;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem !important;
  line-height: 20px;
  // display: flex !important;
  align-items: center;
}
.generalIconFix {
  width: 13px;
  height: 13.59px;
}
.locationIconFix {
  width: 13px;
  height: 16.25px;
}
.deviceIconFix {
  width: 13px;
  height: 13px;
}
.userIconFix {
  width: 15.98px;
  height: 14.75px;
}
.rolesIconFix {
  width: 11px;
  height: 14.88px;
}
.generalTextFix {
  padding-left: 2.42%;
  color: #000;
}
.locationTextFix {
  padding-left: 2.2%;
  color: #000;
}
.deviceTextFix {
  padding-left: 2.01%;
  color: #000;
}
.userTextFix {
  padding-left: 2.42%;
  color: #000;
}
.rolesTextFix {
  padding-left: 2.42%;
  color: #000;
}
.overviewIconFix {
  width: 15px;
  height: 15px;
}
.organisationIconFix {
  width: 13px;
  height: 13.59px;
}
.biblioUserIconFix {
  width: 15.98px;
  height: 12.75px;
}
.overviewTextFix {
  padding-left: 2.42%;
}
.organisationTextFix {
  padding-left: 2.42%;
}
.biblioUserTextFix {
  padding-left: 2.871%;
}
.dashboardIconFix {
  width: 11.87px;
  height: 15px;
}
.devicesIconFix {
  width: 12px;
  height: 15px;
}
.deviceSettingIconFix{
  filter: invert(1);
}
.insightsIconFix {
  width: 10px;
  height: 15px;
}
.activityLogIconFix {
  width: 10px;
  height: 12px;
}
.masterConfigurationIconFix {
  width: 10px;
  height: 12px;
}
.alertIconFix {
  width: 12px;
  height: 15px;
}
.eventLogsIconFix {
  width: 13px;
  height: 14.24px;
}
.dashboardTextFix {
  padding-left: 0.3%;
}
.devicesTextFix {
  padding-left: 1.08%;
  color: #000;
}
.insightsTextFix {
  padding-left: 1.08%;
}
.activityLogTextFix {
  padding-left: 1.08%;
}
.masterConfigurationTextFix {
  padding-left: 1.08%;
}
.alertTextFix {
  padding-left: 1.975%;
  color: #000;
}
.eventLogsTextFix {
  padding-left: 2%;
  color: #000;
}
.accountSettingsProfileIconFix {
  width: 10.26px;
  height: 13px;
}
.accountSettingsGeneralIconFix {
  width: 11.55px;
  height: 15px;
}
.accountSettingsNotificationsIconFix {
  width: 11.56px;
  height: 14px;
}
.accountSettingsProfileTextFix {
  padding-left: 1.08%;
  color: #000 !important;
}
.accountSettingsGeneralTextFix {
  padding-left: 1.08%;
  color: #000;
}
.accountSettingsNotificationsTextFix {
  padding-left: 1.08%;
  color: #000 !important;
}
.juntoBlack .bib-menu-item-row:hover {
  background-color: #3E3E3E !important;
  color:#ffffff;
}
.juntoWhite .bib-menu-item-row:hover {
  background-color: #f0f0f0 !important;
  color:#1c1c1c;
}
.juntoBlack .bib-menu-item-row .sideNavSlice {
  color: #cbcbcb;;
}
.juntoBlack .bib-menu-item-row:hover .sideNavSlice {
  color:#ffffff;
}
.juntoAccount .bib-menu-item-row:hover {
  background-color: #f0f0f0 !important;
  color:#1c1c1c !important;
}
.biblioAccount .bib-menu-item-row:hover {
  background-color: #f0f0f0 !important;
  color:#1c1c1c !important;
}
.device-menu-img{
  margin-right: 8px;
}
.device-menu-content{
  display: flex;
  align-items: center;
  div{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  div+div{
    margin-left: 15px;
  }
}
.sidenav-back-button{
  display: flex;
  align-items: center;
  width: fit-content !important;
  margin-bottom: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  height: 20px !important;
  cursor: pointer;
  color: #000 !important;
}
.device-sidenav-header{
  padding-left: 11px;
}

.img-column {
  padding-left: 11.18%;
  // margin-top: 7px;
  top: 3px;
}

.dashboardUpper {
  margin-bottom: 55px;
}

.dashboardSideNavBack {
  margin-right: 6px;
}

.settingsSideNav {
  font-size: 1.25rem;
  font-weight:300; 
  font-family: IBM Plex Sans;
  line-height:20px;  
  color:rgb(28, 28, 28);
  overflow: hidden;
  text-overflow: ellipsis;
}

.sideBarDashboardItems{
  height: 20px;
  align-items: center;
}
.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sideNavEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noChange {
  background-color: transparent !important;
}

.sibarComponentHeader {
  padding-top: 38px;
  padding-bottom: 39px;
  & .sidenav-back-button {
    color: #fff !important;
  }
}

.juntoWhite .selectedSidebarWhite {
  background: #f0f0f0 !important;
}

.juntoAccount .selectedSidebarWhite {
  background: #f0f0f0 !important;
  color: #000;
}

.compIcon {
position: relative;
top: -4px;
}

.biblioRed {
  &:focus-visible {
    border: 3px solid black !important;
  }
}

.device-info-icon{
  display: inline-block;
  font-size: 14px;
  float: right;
  margin-top: -2em;
  position: relative;
}
.deviceNameSideBar{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:  85%;
}
.deviceInfo{
  width: 130px;
  word-wrap: break-word
}
.deviceInfo span{
  display: block;
  margin-bottom: 5px; 
  font-size: 12px;
  word-wrap: break-word
}
// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background-color: rgba(255, 255, 255, 0.7) !important;
// }