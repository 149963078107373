@Disabled: url(../../../../../assets/images/disabled.svg),auto;

.antdTableClass {
    table {
        table-layout: auto !important;

        .ant-table-placeholder {
            .ant-table-cell {
                font-size: 1.25rem;
                padding-top: 5px !important;
                padding-bottom: 5px !important;
                color: #737373;
                border-radius: 0px 0px 10px 10px;
                text-align: center;
            }
        }

        thead tr {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: 500;
            font-size: 0.75rem;

            .ant-table-row-expand-icon-cell {
                padding: 0;
            }

            th {
                border: none;
                border-right: none !important;

                &::before {
                    border-right: 1px solid #EDEDED;
                }

                &:nth-last-child(2) {
                    &::before {
                        border-right: 1px solid #FAFAFA
                    }
                }

                &:last-child {
                    padding: 0;
                }

                .ant-table-filter-column .ant-table-filter-trigger.active {
                    display: block !important;
                    margin: -0px -8px -4px 4px;
                }
            }
        }

        tbody {
            tr {
                position: relative;
                background-color: #e5e5e5;
                box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02),
                    0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067),
                    0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318),
                    0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179),
                    0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01),
                    0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821),
                    0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822),
                    0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
                border-radius: 10px;

                td {
                    border-bottom: 3px solid #f0f0f0;
                    margin-bottom: 5px;
                    background-color: white;
                    font-family: IBM Plex Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.75rem;
                    border-right: none !important;
                    line-height: 20px;
                    color: #4d4d4d;

                    padding-top: 12.5px;
                    padding-bottom: 12.5px;
                }

                td:hover {
                    background: white !important;
                }

                td:first-child {
                    border-radius: 10px 0 0px 10px;
                }
            }

            // .ant-table-expanded-row {
                // td {
                //     border-radius: 0 !important;
                //     padding-right: 20px !important;
                //     padding-left: 20px !important;

                //     // p {
                //     //     border-top: 1px solid #8080802e !important;
                //     // }
                //     &:hover {
                //         background-color: transparent !important;
                //     }
                // }

                // .ant-table-thead th.ant-table-column-sort {
                //     background: #fafafa;
                // }

                // .ant-input-search {
                //     width: 100%;
                //     height: 44px;
                //     border: 1px solid #0000002e;
                //     border-radius: 10px;
                //     background: white;
                // }
            // }
        }

        .ant-table-thead {
            th.ant-table-column-sort {
                font-weight: bold !important;
                background: #fafafa !important;

                .ant-table-column-sorter-full {
                    display: block !important;
                }

                .ant-table-filter-column {
                    .ant-table-column-title {
                        font-weight: bold !important;
                        font-family: IBM Plex Sans;
                        font-style: normal;
                        font-size: 0.75rem;
                        line-height: 20px;
                        color: #4D4D4D;
                        overflow: hidden;
                       text-overflow: ellipsis;
                       margin-bottom: 0px;
                    }

                    .ant-table-column-sorter-full {
                        display: block !important;
                    }
                }

                .ant-dropdown-trigger {
                    margin: 0px -8px -4px 4px;
                    display: block !important;
                }
            }

            th.ant-table-column-has-sorters {
                .ant-table-column-sorter-full {
                    display: none;
                }

                // .ant-table-filter-column {
                //     .ant-table-column-sorter-full {
                //         display: none;
                //     }
                // }

                // .ant-dropdown-trigger {
                //     display: none;
                // }

                &:hover {
                    background: #fafafa !important;

                    // .ant-table-column-sorter-full {
                    //     display: block !important;
                    // }

                    // .ant-table-filter-column {
                    //     .ant-table-column-sorter-full {
                    //         display: block !important;
                    //         height: 19px;
                    //     }
                    // }

                    // .ant-dropdown-trigger {
                    //     display: block !important;
                    //     margin: -0px -8px -4px 4px;
                    // }
                }
            }
        }

        //css for other than tr,td
        .ant-table-column-sorter-up.active,
        .ant-table-column-sorter-down.active {
            color: red;
        }

        .active {
            border: none;
        }
        
        // .ant-table-expanded-row-level-1 td {
            // padding: 0px !important;
            // background: transparent;

            // p {
            //     margin: 0px !important;
            // }
        // }
    }

    .ant-table-footer {
        padding-top: 10px !important;
        padding-bottom: 16px !important;

        // .more-prev-btn-div {
        //     padding: 0px !important;
        // }
    }

    .ant-table-body {
        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 10px;
            height: 6px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: #f0f0f0;
        }

        &::-webkit-scrollbar-thumb {
            background: #ccc;
            border-radius: 4px;

            &:hover {
                background: #999;
            }
        }
    }

    .ant-table-header {
        table {
            border-top: none !important;
            table-layout: fixed !important;
        }
    }

    .ant-table-expanded {
        position: relative;
    }

    .ant-table-row-expand-icon-cell {
        button {
            display: none;
        }
    }

    li:hover {
        border-left: none !important;
        color: black !important;
    }

    .loaderBody {
        margin-top: 8%;
    }
}


// .ant-btn-sm {
//     box-shadow: rgb(0 0 0 / 2%) 0px 54px 29px,
//         rgb(0 0 0 / 2%) 0px 27.0264px 14.5142px,
//         rgb(0 0 0 / 1%) 0px 16.2794px 8.74265px,
//         rgb(0 0 0 / 1%) 0px 10.4327px 5.60275px,
//         rgb(0 0 0 / 1%) 0px 6.76164px 3.63125px,
//         rgb(0 0 0 / 1%) 0px 4.25637px 2.28583px,
//         rgb(0 0 0 / 1%) 0px 2.44555px 1.31335px,
//         rgb(0 0 0 / 0%) 0px 1.07635px 0.57804px;
// }

.ant-table-filter-dropdown {
    border-radius: 10px;

    li {
        border-left: 2px solid transparent;

        &:hover {
            border-left: 2px solid red;
        }
    }

    .ant-dropdown-menu::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
    }

    .ant-dropdown-menu::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    .ant-dropdown-menu::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 4px;
    }

    .ant-table-filter-dropdown .ant-dropdown-menu {
        border-radius: 10px;
    }
}

.ant-table-column-title {
    font-weight: 500;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-size: 0.75rem;
    line-height: 20px;
    color: #4D4D4D;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
}
.headText{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
 }

.ant-tooltip-placement-top {
    display: none !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    display: none !important;
}

.ant-collapse-header {
    border: none !important;
    width: 50%;
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.688rem;
    line-height: 13px;
    color: #4D4D4D;

    .ant-collapse-arrow {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 0.688rem;
        line-height: 13px;
        color: #4D4D4D;
    }

    .ant-collapse-content {
        border: none !important;
        background-color: transparent !important;
    }
}

.ant-collapse>.ant-collapse-item {
    border-bottom: none !important;
    background: white;
}

.ant-collapse-content>.ant-collapse-content-box {
    padding: 5px !important;
    border: none;
}

.ant-collapse-item:last-child>.ant-collapse-content {
    border: none;

    ul {
        li {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 0.688rem;
            line-height: 13px;
            color: #4D4D4D !important;
        }

        li:hover {
            background: transparent;
        }
    }
}

.ant-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.688rem;
    line-height: 13px;
    color: #4D4D4D;
    font-feature-settings: 'tnum', "tnum";
    background-color: transparent;
    border: none !important;
    border-bottom: 0;
    border-radius: 10px;
}

.ant-table-filter-dropdown-btns {
    border-radius: 10px;
    border: none;

    .ant-btn {
        border: 0px black;
        box-shadow: none;
        border-color: black;
        background-color: black;
        width: auto;
        height: auto;
    }

    .ant-btn-link {
        color: white;
        background: black;
        border-color: black;
        border-radius: 10px;
        border-color: black;
        box-shadow: none;
    }

    .ant-btn-primary {
        border-radius: 10px;
    }

    .ant-btn-lin {
        border-radius: 10px;
    }

    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
        cursor: @Disabled;
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: transparent !important;
        background: transparent !important;
        text-shadow: none !important;
        box-shadow: none !important;
    }

    .ant-btn-link:hover,
    .ant-btn-link:focus {
        color: #fff !important;
        border-color: #4D4D4D !important;
        background: #4D4D4D !important;
    }
}

////
.ant-table-expanded-row-level-1:hover {
    background-color: #E5E5E5 !important;
}

.ant-table-expanded-row-level-1 td p:hover {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}


// antd tables
.ant-dropdown-menu-title-content>span {
    max-width: 180px;
    white-space: break-spaces;
    display: inline-flex;
    word-break: break-word;
}  

.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item 
    .ant-dropdown-menu-title-content .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-input{
        left: auto !important;
}