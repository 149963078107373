.device-config-header {
  display: flex;
  align-items: center;
  padding-top: 1.1vh;
  margin-bottom: 30px;
  justify-content: space-between;
  padding-left: 2.75vw;
  padding-right: 6px;
}

.DeviceConfigTitle {
  display: flex !important;
  align-items: center;
  font-size: 1.4375rem;
  font-weight: 500;
  line-height: 30px;
  padding-bottom: 5px;
  flex-wrap: wrap;
  grid-row-gap: 0.5em !important;
  .DeviceConfigTitle-heading {
    white-space: nowrap;
    margin-right: 20px;
  }
}

.get-configuration-button {
  padding-right: 10px;
}

.discard-all-button {
  padding-left: 10px;
}

.configuration-subHeader {
  justify-content: space-between;
  align-items: center;
}

.configSearchIcon:focus {
  border: 2px solid black !important;
  border-radius: 5px !important;
}

.config-filter {
  margin-left: auto;
  .ant-row {
    justify-content: end;
    .ant-col {
      display: flex;
      align-items: center;
    }
  }
}

.config-saveBtn {
  min-width: 11em;
}

.config-apply-changes {
  margin-left: auto;

  .ant-row.ant-form-item {
    margin-bottom: 0px !important;
  }
}

.configSearchBtn {
  margin-right: 10px !important;
}
.configSearchBar{
  border: 1px solid #55C1F6 !important;
}