[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label
{
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    width: 14px;
    height: 14px;
    border: 1px solid rgba(203, 203, 203, 1);
    border-radius: 3px;
    background: #fff;
}
[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    top: 4px;
    left:4px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 1px;
	background-color: #000;
}
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}